import { useEffect } from 'react'
import './stripePaymentSuccess.css'
import redirectJson from './redirectLottie.json'
import { Player } from '@lottiefiles/react-lottie-player';
import { useParams } from 'react-router-dom';

function StripeBookSuccessPage() {
    const { bookName } = useParams()
    useEffect(() => {
        const timer = setTimeout(() => {
            window.open(`chrome-extension://jkkfeklkdokfnlfodkdafododleokdoa/Dashboard/dashboard.html?purchaseBook=${bookName}`, '_self')
        }, 1500);
        return () => clearTimeout(timer);
    }, []);
    return (
        <section id='stripe_payment_success_container'>
            <h1>Redirecting you back to the app....</h1>
            <Player
                src={redirectJson}
                className="player"
                loop
                autoplay
            />
        </section>
    )
}

export default StripeBookSuccessPage;