import './landingPage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faStar, faShieldHalved, faXmark, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import load from './loader.gif'
import codi from '../SupportChat/codi.png'
import quizIcon from './quiz_main_icon.png'
import introImg from './quiz_intro.png'
import quizImage2 from './quiz_page_2.png'
import quizImage3 from './quiz_page_3.png'
import quizImage4 from './quiz_page_4.png'
import quizImage5 from './quiz_page_5.png'
import quizImage6 from './quiz_page_6.png'
import quizImage7 from './quiz_page_7.png'
import quizEndStar from './quiz_premium_review.png'
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import '../SaleHeader/saleHeader.css'
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useRef } from 'react';
import Footer from '../Footer/footer'

import useMediaQuery from '@mui/material/useMediaQuery';

// import playStoreIcon from './playStoreIcon.png'
// import appleStoreIcon from './appleStoreIcon.png'
// import chromeStoreIcon from './chromeStoreIcon.png'

mixpanel.init('d051baf39ffda3bf75a7b21ddff1eef4', { debug: false });

function LandingPage() {
    const navigate = useNavigate();

    const Ref = useRef(null);
    const [timeReady, setTimeReady] = useState(false)
    const [remainingHours, setRemainingHours] = useState('00')
    const [remainingMinutes, setRemainingMinutes] = useState('00')
    const [remainingSeconds, setRemainingSeconds] = useState('00')
    const [currentQuestion, setCurrentQuestion] = useState(null)
    const [activeAnswer, setActiveAnswer] = useState(null)
    const [quizResult, setQuizResult] = useState(20)
    const [quizAlert, setQuizAlert] = useState(false)
    const [loading, setLoading] = useState(true)

    const getTimeRemaining = (time) => {
        const total = Date.parse(time) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }

    const updateTimer = (time) => {
        let { total, hours, minutes, seconds } = getTimeRemaining(time);
        if (total >= 0) {
            setRemainingHours(hours > 9 ? hours : '0' + hours)
            setRemainingMinutes(minutes > 9 ? minutes : '0' + minutes)
            setRemainingSeconds(seconds > 9 ? seconds : '0' + seconds)
        }
    }

    const startTimer = (time) => {
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            updateTimer(time);
        }, 1000)
        Ref.current = id;
    }

    const openQuiz = () => {
        setCurrentQuestion(1)
        mixpanel.track('QuizPage', {
            'click': 'open_quiz'
        })
    }

    const closeQuiz = () => {
        setCurrentQuestion(null)
        setQuizAlert(false)
        setQuizResult(20)
        setActiveAnswer(null)
        mixpanel.track('QuizPage', {
            'click': 'close_quiz'
        })
    }

    const submitAnswer = (questionNum) => {
        mixpanel.track('QuizQuestionInteraction', {
            [questionNum]: activeAnswer
        })
        if (activeAnswer) {
            setQuizAlert(false)
            if (questionNum === 1) {
                if (activeAnswer === 1) {
                    setQuizResult(quizResult + 15)
                } else if (activeAnswer === 2) {
                    setQuizResult(quizResult + 10)
                } else if (activeAnswer === 3) {
                    setQuizResult(quizResult + 5)
                }
            } else if (questionNum === 2) {
                if (activeAnswer === 1) {
                    setQuizResult(quizResult + 10)
                } else if (activeAnswer === 2) {
                    setQuizResult(quizResult + 7)
                } else if (activeAnswer === 3) {
                    setQuizResult(quizResult + 5)
                } else {
                    setQuizResult(quizResult + 3)
                }
            } else if (questionNum === 4) {
                if (activeAnswer === 1) {
                    setQuizResult(quizResult + 3)
                } else if (activeAnswer === 2) {
                    setQuizResult(quizResult + 5)
                } else if (activeAnswer === 3) {
                    setQuizResult(quizResult + 7)
                } else {
                    setQuizResult(quizResult + 15)
                }
            } else if (questionNum === 5) {
                if (activeAnswer === 1) {
                    setQuizResult(quizResult + 10)
                } else if (activeAnswer === 2) {
                    setQuizResult(quizResult + 7)
                } else if (activeAnswer === 3) {
                    setQuizResult(quizResult + 7)
                } else {
                    setQuizResult(quizResult + 5)
                }
            } else if (questionNum === 6) {
                if (activeAnswer === 1) {
                    setQuizResult(quizResult + 10)
                } else if (activeAnswer === 2) {
                    setQuizResult(quizResult + 15)
                } else if (activeAnswer === 3) {
                    setQuizResult(quizResult + 5)
                } else {
                    setQuizResult(quizResult + 5)
                }
            } else if (questionNum === 7) {
                if (activeAnswer === 1) {
                    setQuizResult(quizResult + 14)
                } else {
                    setQuizResult(quizResult + 0)
                }
            }
            setActiveAnswer(null)
            setCurrentQuestion(questionNum + 1)
            if (questionNum === 7) {
                const timer = setTimeout(() => {
                    setCurrentQuestion(9)
                }, 1500);
                return () => clearTimeout(timer);
            }
        } else {
            setQuizAlert(true)
        }
    }

    useEffect(() => {
        const pageOpened = localStorage.getItem('openTime')
        if (pageOpened) {
            const pageOpenedDate = new Date(pageOpened)
            const day = pageOpenedDate.getDate()
            const currentDay = new Date().getDate()
            if (day !== currentDay) {
                let deadline = new Date()
                localStorage.setItem('openTime', deadline)
                deadline.setSeconds(deadline.getSeconds() + 5400)
                startTimer(deadline)
                setTimeReady(true)
            } else {
                pageOpenedDate.setSeconds(pageOpenedDate.getSeconds() + 5400)
                startTimer(pageOpenedDate)
                setTimeReady(true)
            }
        } else {
            let deadline = new Date()
            localStorage.setItem('openTime', deadline)
            deadline.setSeconds(deadline.getSeconds() + 5400)
            startTimer(deadline)
            setTimeReady(true)
        }
        const timer = setTimeout(() => {
            setLoading(false)
        }, 500);
        return () => clearTimeout(timer);
    }, [])

    const isMobile = useMediaQuery('(max-width: 600px)');
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const openLifetimePurchaseForApp = () => {
        window.open('https://buy.stripe.com/6oEeY4eDD9Hn8ogfZo', '_blank');
    }
    const openAnnualPurchaseForApp = () => {
        window.open('https://buy.stripe.com/dR6eY4brr6vbgUM9AY', '_blank');
    }
    const openAnnualFreeTrailForApp = () => {
        window.open('https://buy.stripe.com/dR603a3YZ5r7cEw7tA', '_blank');
    }

    if (loading) {
        return (
            <section id='full_page_loader'>
                <img src={load} alt='Loading..' />
            </section>
        )
    } else {
        return (
            <>
                <section id='nav_bar_blockerx'>
                    <div className='home_brand_area'>
                        <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png' alt='brand-logo'></img>
                    </div>
                    <div className='home_butn_area'>
                        <button id='signupForNewUser' onClick={() => {
                            mixpanel.track('LandingPage', {
                                'click': 'signup_button'
                            })
                            navigate("/signup")
                        }}>Signup</button>
                        <button onClick={() => {
                            mixpanel.track('LandingPage', {
                                'click': 'reset_password_button'
                            })
                            navigate("/forgotPassword")
                        }}>Forgot Password</button>
                    </div>
                </section>
                <section id='nav_bar_blockerx_mobile'>
                    <div className='home_brand_area'>
                        <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png' alt='brand-logo'></img>
                    </div>
                </section>
                {(() => {
                    if (timeReady) {
                        return (
                            <section id='sale_header'>
                                {/* <div className='details_area'>
                                    <div className='details_tagline'>
                                        <h2>Quitting Porn Has Never Been Easier!</h2>
                                    </div>
                                    <div className='details_countdown'>
                                        <h3>Offer ends in <strong>{remainingHours}</strong> hours <strong>{remainingMinutes}</strong> minutes <strong>{remainingSeconds}</strong> seconds</h3>
                                    </div>
                                </div>
                                <div className='deal_area'>
                                    <h1 className='supersale'>SAVE 73% NOW!</h1>
                                </div> */}
                                {isMobile ? (
                                    <div className={`mobile_details_area`}>
                                        <div className='deal_area'>
                                            <h1 className='supersale'>SAVE 73% NOW!</h1>
                                        </div>
                                        <div className='details_countdown'>
                                            <h3>OFFER ENDS IN <strong>{remainingHours}h : {remainingMinutes}m : {remainingSeconds}s</strong></h3>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className='details_area'>
                                            <div className='details_tagline'>
                                                <h2>Quitting Porn Has Never Been Easier!</h2>
                                            </div>
                                            <div className='details_countdown'>
                                                <h3>Offer ends in <strong>{remainingHours}</strong> hours <strong>{remainingMinutes}</strong> minutes <strong>{remainingSeconds}</strong> seconds</h3>
                                            </div>
                                        </div>
                                        <div className='deal_area'>
                                            <h1 className='supersale'>SAVE 73% NOW!</h1>
                                        </div>
                                    </>
                                )}
                            </section>
                        )
                    }
                })()}
                <section id='hero_area'>
                    <div className='left_containers'>
                        <h2>Quit Porn & Reclaim Your Life</h2>
                        <div className='hero_list_item'>
                            <span>✓</span> Block all porn websites in one click
                        </div>
                        <div className='hero_list_item'>
                            <span>✓</span> Accountability Partner
                        </div>
                        <div className='hero_list_item'>
                            <span>✓</span> Prevent Uninstall
                        </div>
                        <div className='hero_list_item'>
                            <span>✓</span> <a href='https://community.blockerx.net/' target='_blank' rel="noreferrer"> Access to Strong De-addiction Community</a>
                        </div>
                        <div className='hero_list_item'>
                            <span>✓</span> Use across all devices
                        </div>
                        <div className='hero_list_item'>
                            <span>✓</span> No Advertisements
                        </div>
                    </div>
                    <div className='pricing_containers'>
                        <div className='pricing_card lifetime'>
                            <div className='pricing_card_tagline'>
                                <div className='pricing_card_tag'>
                                    Best value
                                </div>
                            </div>
                            <div className='pricing_card_main'>
                                <div className='pricing_card_main_head'>
                                    <h2>Lifetime Plan</h2>
                                    <span>73% off</span>
                                </div>
                                <div onClick={() => {
                                    mixpanel.track('LandingPage', {
                                        'click': 'lifetime_card'
                                    })
                                    openLifetimePurchaseForApp()
                                }} className='pricing_card_main_body'>
                                    <h1><strong>$129.97</strong></h1>
                                    <span>One time payment of <strike>$479.28</strike> $129.97</span>
                                    <button onClick={() => {
                                        mixpanel.track('LandingPage', {
                                            'click': 'lifetime_card_btn'
                                        })
                                    }}>Get plan</button>
                                    <p>3 day money-back guarantee</p>
                                </div>
                            </div>
                        </div>
                        <div className='pricing_card'>
                            <div className='pricing_card_main'>
                                <div className='pricing_card_main_head'>
                                    <h2>12 month plan</h2>
                                    <span>54% off</span>
                                </div>
                                <div onClick={() => {
                                    mixpanel.track('LandingPage', {
                                        'click': 'annual_card'
                                    })
                                    openAnnualPurchaseForApp()
                                }} className='pricing_card_main_body'>
                                    <h1><strong>$109.97 </strong>/year</h1>
                                    <span>Billed <strike>$239.64</strike> $109.97 every year</span>
                                    <button onClick={() => {
                                        mixpanel.track('LandingPage', {
                                            'click': 'annual_card_btn'
                                        })
                                    }}>Get plan</button>
                                    <p>Renews automatically, unless cancelled</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section id='heroArea'>
                    <div className='hero_main_area'>
                        <div className='hero_text_area'>
                            <div className='hero_heading'><h1>Get a Plan for BlockerX App</h1></div>
                            <div className='hero_paragraph'><p>Embark on the journey with us to quit porn & distraction and become the <span style={{ color: '#E64A19' }}>best version of yourself.</span>  Get the BlockerX plan and access all features in the app on any OS.</p></div>
                        </div>
                        <div className='pricing_cards'>
                            <div onClick={() => {
                                mixpanel.track('LandingPage', {
                                    'click': 'annual_card'
                                })
                            }} className='pricing_plan'>
                                <div className="heading">
                                    <h3>Yearly Plan</h3>
                                    <h2>$109.97<span id="per12Month">/12 month</span></h2>
                                    <p>Billed <strike>$239.64</strike> $109.97 every year</p>
                                </div>
                                <hr />
                                <div className='features_list'>
                                    <div><span>Block all porn website in one click</span></div>
                                    <div><span>Accountability Partner</span></div>
                                    <div><span>Prevent Uninstall</span></div>
                                    <div><span>Access to community</span></div>
                                    <div><span>Use across all devices & OS</span></div>
                                    <div><span>No Ads</span></div>
                                </div>
                                <hr />
                                <div className='card_end'>
                                    <p>supported on</p>
                                    <div className='icon_img'>
                                        <div><img src={playStoreIcon} alt="playStore" /></div>
                                        <div><img src={appleStoreIcon} alt="appleStore" /></div>
                                        <div><img src={chromeStoreIcon} alt="chromeStore" /></div>
                                    </div>
                                    <div className='plan_button'>
                                        <button onClick={() => {
                                            mixpanel.track('LandingPage', {
                                                'click': 'annual_card_btn'
                                            })
                                            openAnnualPurchaseForApp()
                                        }}>Get Plan</button>
                                    </div>
                                    <p>Renews automatically, unless cancelled</p>
                                </div>
                            </div>
                            <div onClick={() => {
                                mixpanel.track('LandingPage', {
                                    'click': 'lifetime_card'
                                })
                            }} className='pricing_plan'>
                                <div className='best_value_tag'>
                                    <span>Best Value</span>
                                </div>
                                <div className="heading">
                                    <h3>Lifetime Plan</h3>
                                    <h2>$129.97</h2>
                                    <p>One time payment of <strike>$479.28</strike> $129.97</p>
                                </div>
                                <hr />
                                <div className='features_list'>
                                    <div><span>Block all porn website in one click</span></div>
                                    <div><span>Accountability Partner</span></div>
                                    <div><span>Prevent Uninstall</span></div>
                                    <div><span>Access to community</span></div>
                                    <div><span>Use across all devices & OS</span></div>
                                    <div><span>No Ads</span></div>
                                </div>
                                <hr />
                                <div className='card_end'>
                                    <p>supported on</p>
                                    <div className='icon_img'>
                                        <div><img src={playStoreIcon} alt="playStore" /></div>
                                        <div><img src={appleStoreIcon} alt="appleStore" /></div>
                                        <div><img src={chromeStoreIcon} alt="chromeStore" /></div>
                                    </div>
                                    <div className='plan_button'>
                                        <button onClick={() => {
                                            mixpanel.track('LandingPage', {
                                                'click': 'lifetime_card_btn'
                                            })
                                            openLifetimePurchaseForApp()
                                        }}>Get Plan</button>
                                    </div>
                                    <p className='moneyBack'>3 days Money-back Guarantee</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section id='home_app_stats_area'>
                    <h1>Ready to Meet the Best version of Yourself ?</h1>
                    <div className='home_app_stats_boxes'>
                        <div className='home_app_stats_container'>
                            <div className='home_stats_icon_area'>
                                <FontAwesomeIcon icon={faDownload} className='home_stats_icon_img' />
                            </div>
                            <div className='home_stats_content_area'>
                                <h2>2,670,876</h2>
                                <span>Downloads</span>
                            </div>
                        </div>
                        <div className='home_app_stats_container'>
                            <div className='home_stats_icon_area'>
                                <FontAwesomeIcon icon={faStar} className='home_stats_icon_img' />
                            </div>
                            <div className='home_stats_content_area'>
                                <h2>79102</h2>
                                <span>5 star Reviews</span>
                            </div>
                        </div>
                        <div className='home_app_stats_container'>
                            <div className='home_stats_icon_area'>
                                <FontAwesomeIcon icon={faShieldHalved} className='home_stats_icon_img' />
                            </div>
                            <div className='home_stats_content_area'>
                                <h2>18,847,757</h2>
                                <span>Porn sessions eliminated/ year</span>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='home_banner_area'>
                    <div className='home_banner_container'>
                        <div className='home_banner_content_area'>
                            <h1><strong>Still Confused ? </strong></h1>
                            <h1 style={{ marginBottom: '15px' }}>Try BlockerX for Free</h1>
                            <span><strong>Free</strong> for 1 day, then $109.95/year</span>
                            <span>Only $9.16/month, billed annually</span>
                            <button onClick={() => {
                                mixpanel.track('LandingPage', {
                                    'click': 'try_for_free'
                                })
                                openAnnualFreeTrailForApp()
                            }} >Try for free</button>
                        </div>
                        <div className='home_banner_img_area'>
                            <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/mobile_app.png' alt='brand_app' />
                        </div>
                    </div>
                </section>
                <section id='home_testimonial_area'>
                    <h1>Users love BlockerX!</h1>
                    <div className='home_testimonial_boxes'>
                        <div className='testimonial_single_container'>
                            <div className='testimonial_text'>
                                <p>"Absolutely phenomenal app. I bought premium, and have been taking a tour of all the options i have. I tried unblocking unrestricted browsers and i LOVE how much feedback that is given back."</p>
                            </div>
                            <div className='testimonial_author'>
                                <div className='author_name'>
                                    Juan El Bean
                                </div>
                                <div className='author_rating'>
                                    <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/5-star.png' alt='rating 5' />
                                </div>
                            </div>
                        </div>
                        <div className='testimonial_single_container'>
                            <div className='testimonial_text'>
                                <p>"A fantastic app. Like most good habit changing apps, it takes away the need for willpower to achieve the goal. Highly recommended."</p>
                            </div>
                            <div className='testimonial_author'>
                                <div className='author_name'>
                                    Ian Tighe
                                </div>
                                <div className='author_rating'>
                                    <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/5-star.png' alt='rating 5' />
                                </div>
                            </div>
                        </div>
                        <div className='testimonial_single_container'>
                            <div className='testimonial_text'>
                                <p>"This app is a godsend. I have been struggling with porn addiction for years and this app helped a lot. Any time I get an urge and open up a website, it immediately blocks it. It’s just perfect."</p>
                            </div>
                            <div className='testimonial_author'>
                                <div className='author_name'>
                                    Hellrider 386
                                </div>
                                <div className='author_rating'>
                                    <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/5-star.png' alt='rating 5' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </section>
                <section id='home_benifits_area'>
                    <h1>BlockerX users saw a significant improvement in their life</h1>
                    <div className='home_page_benifts'>
                        <div className="benifit_row">
                            <div className="benifit_card">
                                <div className="card_head" style={{ background: "#ECE3FF" }}>
                                    <img src="https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/benifits/ben1.png" alt="ben1" />
                                    <h3>Improved self-confidence</h3>
                                </div>
                                <div className="card_body">
                                    <span className="percent" style={{ color: "#8866D9" }}>73%</span>
                                    <span className="sentence">of BlockerX users report feeling more confident.</span>
                                </div>
                            </div>
                            <div className="benifit_card">
                                <div className="card_head" style={{ background: "#D4F3D4" }}>
                                    <img src="https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/benifits/ben2.png"
                                        alt="ben1" />
                                    <h3>Better sleep</h3>
                                </div>
                                <div className="card_body">
                                    <span className="percent" style={{ color: "#0DBB9D" }}>68%</span>
                                    <span className="sentence">of BlockerX users reported an improvement in their sleep
                                        cycle.</span>
                                </div>
                            </div>
                        </div>
                        <div className="benifit_row">
                            <div className="benifit_card">
                                <div className="card_head" style={{ background: "#FFF0C6" }}>
                                    <img src="https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/benifits/ben3.png"
                                        alt="ben1" />
                                    <h3>More energy</h3>
                                </div>
                                <div className="card_body">
                                    <span className="percent" style={{ color: "#F6AB15" }}>78%</span>
                                    <span className="sentence">of BlockerX users reported feeling more energized.</span>
                                </div>
                            </div>
                            <div className="benifit_card">
                                <div className="card_head" style={{ background: "#ffdae0" }}>
                                    <img src="https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/benifits/ben4.png"
                                        alt="ben1" />
                                    <h3>Stronger relationship</h3>
                                </div>
                                <div className="card_body">
                                    <span className="percent" style={{ color: "#FD4985" }}>65%</span>
                                    <span className="sentence">of BlockerX users reported a stronger relationship with their
                                        partner.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <hr /> */}
                </section>
                {/* <section id='home_download_area'>
                    <h1>Download BlockerX</h1> */}
                    {/* <div className='download_button_container'>
                        <a onClick={() => {
                            mixpanel.track('LandingPage', {
                                'click': 'download_blockerx_android'
                            })
                        }} href='https://blockerx.onelink.me/oeB0/blockerxlite' target='ash'><img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/download/dl1.png' alt='' /></a>
                        <a onClick={() => {
                            mixpanel.track('LandingPage', {
                                'click': 'download_blockerx_ios'
                            })
                        }} href='https://apps.apple.com/in/app/blockerx-block-porn-website/id1522854086' target='ash'><img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/download/dl2.png' alt='' /></a>
                        <a onClick={() => {
                            mixpanel.track('LandingPage', {
                                'click': 'download_blockerx_extension'
                            })
                        }} href='https://chrome.google.com/webstore/detail/blockerx-porn-blocker-for/jkkfeklkdokfnlfodkdafododleokdoa' target='ash'><img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/download/dl3.png' alt='' /></a> */}
                        {/* <a onClick={() => {
                            mixpanel.track('LandingPage', {
                                'click': 'download_blockerx_windows'
                            })
                        }} href='https://blockerx-windows-files.s3.us-west-1.amazonaws.com/BlockerXSetup.exe' target='ash'><img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/download/dl4.png' alt='' />
                        </a> */}
                        {/* <a onClick={() => {
                            mixpanel.track('LandingPage', {
                                'click': 'download_blockerx_macOs'
                            })
                        }} href='https://blockerx-windows-files.s3.us-west-1.amazonaws.com/BlockerX.pkg' target='ash'><img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/download/dl5.png' alt='' />
                        </a> */}
                    {/* </div>
                </section> */}
                <section id="offer_ending_banner">
                    <div className='offer_ending' onClick={scrollToTop}>
                        <h1>⌛ Offer Ending Soon, Get your Subscription Now!!</h1>
                    </div>
                </section>
                <Footer/>
                {currentQuestion ? (
                    <section id='home_quiz_area'>
                        {currentQuestion === 1 ? (
                            <div className='quiz_container' id='quiz_question_1'>
                                <div className='quiz_sidebar'>
                                    <div className='quiz_sidebar_heading'>
                                        <img src={codi} alt='codi' />
                                        <h1>Hi! I am Codi!</h1>
                                    </div>
                                    <p>
                                        I am your personal AI Friend, and I will help you embark on the journey
                                        <strong> quit porn & distraction and become the best version of yourself.</strong>
                                    </p>
                                    <img className='codi_says' src={introImg} alt='codi_says' />
                                </div>
                                <div className='quiz_questionare'>
                                    <div className='quiz_question_box'>
                                        <h2>Do you fantasize about porn during sex?</h2>
                                    </div>
                                    <div className='quiz_option_box'>
                                        <div className={activeAnswer === 1 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(1) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>1</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Very Frequently</span>
                                        </div>
                                        <div className={activeAnswer === 2 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(2) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>2</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Frequently</span>
                                        </div>
                                        <div className={activeAnswer === 3 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(3) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>3</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Occasionally</span>
                                        </div>
                                        <div className={activeAnswer === 4 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(4) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>4</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Rarely or Never</span>
                                        </div>
                                    </div>
                                    {quizAlert ? (
                                        <span className='red_alert'>
                                            *Please select one option to Continue
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    <div className='quiz_cta_btn'>
                                        <button onClick={() => { submitAnswer(1) }}>Next</button>
                                    </div>
                                </div>
                            </div>
                        ) : currentQuestion === 2 ? (
                            <div className='quiz_container' id='quiz_question_2'>
                                <div className='quiz_sidebar'>
                                    <p>
                                        Blocker X user noted a significant <strong>reduction of the need for porn</strong> within 3 month of using this app
                                    </p>
                                    <img style={{ width: '70%' }} className='codi_says' src={quizImage2} alt='codi_says' />
                                </div>
                                <div className='quiz_questionare'>
                                    <div className='quiz_question_box'>
                                        <h2>What age did you start viewing Porn?</h2>
                                    </div>
                                    <div className='quiz_option_box'>
                                        <div className={activeAnswer === 1 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(1) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>1</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>12 or younger</span>
                                        </div>
                                        <div className={activeAnswer === 2 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(2) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>2</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>13 to 16</span>
                                        </div>
                                        <div className={activeAnswer === 3 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(3) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>3</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>17 to 24</span>
                                        </div>
                                        <div className={activeAnswer === 4 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(4) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>4</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>25 and older</span>
                                        </div>
                                    </div>
                                    {quizAlert ? (
                                        <span className='red_alert'>
                                            *Please select one option to Continue
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    <div className='quiz_cta_btn'>
                                        <button onClick={() => { submitAnswer(2) }}>Next</button>
                                    </div>
                                </div>
                            </div>
                        ) : currentQuestion === 3 ? (
                            <div className='quiz_container' id='quiz_question_3'>
                                <div className='quiz_sidebar'>
                                    <p>
                                        <strong>More than 50% of porn addicts</strong> have reported loss of interest in real sex and a decrease on libido
                                    </p>
                                    <img style={{ width: '70%' }} className='codi_says' src={quizImage3} alt='codi_says' />
                                </div>
                                <div className='quiz_questionare'>
                                    <div className='quiz_question_box'>
                                        <h2>What is your Gender?</h2>
                                    </div>
                                    <div className='quiz_option_box'>
                                        <div className={activeAnswer === 1 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(1) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>1</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Male</span>
                                        </div>
                                        <div className={activeAnswer === 2 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(2) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>2</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Female</span>
                                        </div>
                                        <div className={activeAnswer === 3 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(3) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>3</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Others</span>
                                        </div>
                                        <div className={activeAnswer === 4 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(4) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>4</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Rather not say</span>
                                        </div>
                                    </div>
                                    {quizAlert ? (
                                        <span className='red_alert'>
                                            *Please select one option to Continue
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    <div className='quiz_cta_btn'>
                                        <button onClick={() => { submitAnswer(3) }}>Next</button>
                                    </div>
                                </div>
                            </div>
                        ) : currentQuestion === 4 ? (
                            <div className='quiz_container' id='quiz_question_4'>
                                <div className='quiz_sidebar'>
                                    <p>
                                        Watching porn releases dopamine in the brain. This chemical makes you feel good, <strong>it’s why you feel pleasure when viewing porn </strong>.
                                    </p>
                                    <img style={{ width: '70%' }} className='codi_says' src={quizImage4} alt='codi_says' />
                                </div>
                                <div className='quiz_questionare'>
                                    <div className='quiz_question_box'>
                                        <h2>On a weekly basis, how many hours do you spend on consuming Porn?</h2>
                                    </div>
                                    <div className='quiz_option_box'>
                                        <div className={activeAnswer === 1 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(1) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>1</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Less than 1 hour</span>
                                        </div>
                                        <div className={activeAnswer === 2 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(2) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>2</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>1 - 3 hours</span>
                                        </div>
                                        <div className={activeAnswer === 3 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(3) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>3</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>4 - 6 hours</span>
                                        </div>
                                        <div className={activeAnswer === 4 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(4) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>4</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>More than 7 hours</span>
                                        </div>
                                    </div>
                                    {quizAlert ? (
                                        <span className='red_alert'>
                                            *Please select one option to Continue
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    <div className='quiz_cta_btn'>
                                        <button onClick={() => { submitAnswer(4) }}>Next</button>
                                    </div>
                                </div>
                            </div>
                        ) : currentQuestion === 5 ? (
                            <div className='quiz_container' id='quiz_question_5'>
                                <div className='quiz_sidebar'>
                                    <img className='codi_say5' src={quizImage5} alt='codi_says' />
                                </div>
                                <div className='quiz_questionare'>
                                    <div className='quiz_question_box'>
                                        <h2>What trigger usually leads you to consume Porn?</h2>
                                    </div>
                                    <div className='quiz_option_box'>
                                        <div className={activeAnswer === 1 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(1) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>1</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Stress</span>
                                        </div>
                                        <div className={activeAnswer === 2 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(2) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>2</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Loneliness</span>
                                        </div>
                                        <div className={activeAnswer === 3 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(3) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>3</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Peer Influence</span>
                                        </div>
                                        <div className={activeAnswer === 4 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(4) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>4</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Others</span>
                                        </div>
                                    </div>
                                    {quizAlert ? (
                                        <span className='red_alert'>
                                            *Please select one option to Continue
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    <div className='quiz_cta_btn'>
                                        <button onClick={() => { submitAnswer(5) }}>Next</button>
                                    </div>
                                </div>
                            </div>
                        ) : currentQuestion === 6 ? (
                            <div className='quiz_container' id='quiz_question_6'>
                                <div className='quiz_sidebar'>
                                    <p>
                                        <strong>Get tons of resources to know more about your problem.</strong> Complete video courses, read e-books and more.
                                    </p>
                                    <img style={{ width: '70%' }} className='codi_says' src={quizImage6} alt='codi_says' />
                                </div>
                                <div className='quiz_questionare'>
                                    <div className='quiz_question_box'>
                                        <h2>Which of these majorly affects due to consuming Porn?</h2>
                                    </div>
                                    <div className='quiz_option_box'>
                                        <div className={activeAnswer === 1 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(1) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>1</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Emotional /Mental Health</span>
                                        </div>
                                        <div className={activeAnswer === 2 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(2) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>2</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Sexual Health</span>
                                        </div>
                                        <div className={activeAnswer === 3 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(3) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>3</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Work and Social Life</span>
                                        </div>
                                        <div className={activeAnswer === 4 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(4) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>4</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Financial Health</span>
                                        </div>
                                    </div>
                                    {quizAlert ? (
                                        <span className='red_alert'>
                                            *Please select one option to Continue
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    <div className='quiz_cta_btn'>
                                        <button onClick={() => { submitAnswer(6) }}>Next</button>
                                    </div>
                                </div>
                            </div>
                        ) : currentQuestion === 7 ? (
                            <div className='quiz_container' id='quiz_question_7'>
                                <div className='quiz_sidebar'>
                                    <p>
                                        Join forces with a trusted partner and embark on a journey toward a <strong>safer and empowering online experience</strong> with BlockerX
                                    </p>
                                    <img style={{ width: '70%' }} className='codi_says' src={quizImage7} alt='codi_says' />
                                </div>
                                <div className='quiz_questionare'>
                                    <div className='quiz_question_box'>
                                        <h2>Have you ever Spent money on Purchasing Porn Website Subscription</h2>
                                    </div>
                                    <div className='quiz_option_box'>
                                        <div className={activeAnswer === 1 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(1) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>1</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>Yes</span>
                                        </div>
                                        <div className={activeAnswer === 2 ? 'quiz_option_single active_option' : 'quiz_option_single'} onClick={() => { setActiveAnswer(2) }}>
                                            <span className='quiz_option_single_num'>
                                                <p>2</p>
                                            </span>
                                            <span className='quiz_option_single_tag'>No</span>
                                        </div>
                                    </div>
                                    {quizAlert ? (
                                        <span className='red_alert'>
                                            *Please select one option to Continue
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    {quizAlert ? (
                                        <span className='red_alert'>
                                            *Please select one option to Continue
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    <div className='quiz_cta_btn'>
                                        <button onClick={() => { submitAnswer(7) }}>Next</button>
                                    </div>
                                </div>
                            </div>
                        ) : currentQuestion === 8 ? (
                            <div className='quiz_container' id='quiz_question_8'>
                                <div className='quiz_calculating'>
                                    <h2>Quiz Complete</h2>
                                    <p>Calculating Results...</p>
                                    <img src={load} alt='loading' />
                                </div>
                            </div>
                        ) : currentQuestion === 9 ? (
                            <div className='quiz_container' id='quiz_question_9'>
                                <div className='quiz_sidebar' id='sidebar_result'>
                                    <div className='quiz_sidebar_heading' id='sidebar_head'>
                                        <img src={codi} alt='codi' />
                                    </div>
                                    <p>
                                        <strong>
                                            Some not so good news :
                                        </strong>
                                    </p>
                                    <p className='result_heading'>Your are Severely addicted to internet porn*</p>
                                    <p id='sidebar_message'>
                                        But the good news is, <strong>you are now in the right place</strong>
                                    </p>
                                    <button id='result_btn_large' onClick={() => {
                                        mixpanel.track('QuizPage', {
                                            'click': 'continue_on_result'
                                        })
                                        setCurrentQuestion(10)
                                    }} className='result_continue'>Continue</button>
                                    <span className='dopamine_info'>Dopamine baseline 34% above estimated average*</span>
                                    <span className='estimate_info'>*Estimate only</span>
                                </div>
                                <div className='quiz_result'>
                                    <div className='quiz_result_inner'>
                                        <div className='quiz_result_avg' style={{ height: '27%' }}>
                                            <h1>27%</h1>
                                            <span>Average</span>
                                        </div>
                                        <div className='quiz_result_user' style={{ height: `${quizResult}%` }}>
                                            <h1>{quizResult}%</h1>
                                            <span>You</span>
                                        </div>
                                    </div>
                                    <button id='result_btn_small' onClick={() => {
                                        mixpanel.track('QuizPage', {
                                            'click': 'continue_on_result'
                                        })
                                        setCurrentQuestion(10)
                                    }} className='result_continue'>Continue</button>
                                </div>
                            </div>
                        ) : (
                            <div className='quiz_container' id='quiz_question_10'>
                                <div className='quiz_sidebar'>
                                    <h1 className='blockerx_perks_heading'>Get Blockerx Premium now!</h1>
                                    <div className='blockerx_single_benifit'>
                                        <span>1</span>
                                        <p>We will help you maximise your productivity by <strong>blocking all porn and distracting sites.</strong></p>
                                    </div>
                                    <div className='blockerx_single_benifit'>
                                        <span>2</span>
                                        <p>An <strong>Accountability Partner</strong> to make sure you don’t relapse.</p>
                                    </div>
                                    <div className='blockerx_single_benifit'>
                                        <span>3</span>
                                        <p>A <strong>Community</strong> of like minded people to share your journey and stay motivated.</p>
                                    </div>
                                    <div className='blockerx_single_benifit'>
                                        <span>4</span>
                                        <p>An investment in yourself to become the <strong>Best Version of You.</strong></p>
                                    </div>
                                </div>
                                <div className='quiz_premium_end'>
                                    <div className='codi_area'>
                                        <img src={codi} alt='codi' />
                                    </div>
                                    <h1>Start your Journey Today</h1>
                                    <div className='user_review'>
                                        <img src={quizEndStar} alt='5 stars' />
                                        <p>“A fantastic app. Helped me overcome my addiction in 2 months! I’ve never been happier.”</p>
                                        <span>-Ian Thige</span>
                                    </div>
                                    <div className='premium_card'>
                                        <div className='premium_offer_area'>
                                            <span>54% Discount</span>
                                        </div>
                                        <div className='card_content'>
                                            <div className='card_plan_area'>
                                                <h3>12 Months Plan</h3>
                                                <p>Renews automatically, unless cancelled</p>
                                                <p>Yearly payment of <strike>$239.64</strike><strong> $109.95</strong></p>
                                                <span>Charged only after trial ends</span>
                                            </div>
                                            <div className='card_price_area'>
                                                <h2>$109.95</h2>
                                                <h4><strike>$239.64</strike></h4>
                                                <p>*Cancel Anytime</p>
                                            </div>
                                        </div>
                                    </div>
                                    <button onClick={() => {
                                        mixpanel.track('QuizPage', {
                                            'click': 'get_free_trail'
                                        })
                                        openAnnualFreeTrailForApp()
                                    }}>Start Today - with 1 day free trial <FontAwesomeIcon icon={faArrowRightLong} /></button>
                                </div>
                            </div>
                        )}
                    </section>
                ) : (
                    <></>
                )}
                {currentQuestion ? (
                    <button onClick={closeQuiz} id='main_quiz_close'>
                        <FontAwesomeIcon icon={faXmark} className='home_stats_icon_img' />
                    </button>
                ) : (
                    <button onClick={openQuiz} id='main_quiz_icon'>
                        <img src={quizIcon} alt='codi' />
                    </button>
                )}
            </>
        )
    }
}

export default LandingPage