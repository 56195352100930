import React, {useState} from "react";
import { AppBar, Box, CssBaseline, IconButton, Toolbar, Button, Divider, Drawer } from '@mui/material'
import './Article.css';
import { NavLink } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from "@mui/icons-material/Menu";
import logoImage from './logo.png'

function HeaderNav() {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} fontFamily={'Poppins'} sx={{ textAlign: 'center' }}>
            <Box sx={{ margin: "20px" }}>
                <img src={logoImage} alt="BlockerX" />
            </Box>

            <Divider />
            <ul className='navigation-mobile'>
                <li className='products-nav'><NavLink to='#'><span>Products</span><KeyboardArrowDownIcon /></NavLink></li>
                <li><NavLink to='#'>Blogs</NavLink></li>
                <li className='active'><NavLink to='#'><Button sx={{ bgcolor: '#E24B1C', color: 'white', padding: '10px 20px', ':hover': { bgcolor: '#E24B1C', color: 'white' }, fontWeight: '700' }}>Get it now</Button></NavLink></li>
            </ul>
        </Box>
    );

    return (
        <Box fontFamily={'Poppins'} sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component='nav' sx={{ bgcolor: "white" }} className="navbar">
                <Toolbar sx={{ justifyContent: 'space-between', margin: '0 10%' }}>
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        sx={{
                            mr: 2,
                            display: { md: "none" },
                        }}
                        onClick={handleDrawerToggle}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img src={logoImage} alt="BlockerX" />
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <ul className='navigation-menu'>
                            <li className='products-nav'>
                                <NavLink to='#'>
                                    <span>Products</span>
                                    <KeyboardArrowDownIcon />
                                </NavLink>
                            </li>
                            <li><NavLink to='#'>Blogs</NavLink></li>
                            <li className='active'>
                                <NavLink to='#'>
                                    <Button sx={{ bgcolor: '#E24B1C', color: 'white', padding: '10px 20px', ':hover': { bgcolor: '#E24B1C', color: 'white' }, fontWeight: '700' }}>
                                        Get it now
                                    </Button>
                                </NavLink>
                            </li>
                        </ul>
                    </Box>

                </Toolbar>
            </AppBar>
            <Box component='nav'>
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '240px' },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
            </Box>
    )
}

export default HeaderNav