import { useState } from 'react';
import './saleHeader.css'
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import { useRef } from 'react';
import masterImg from './master.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import mixpanel from 'mixpanel-browser';

mixpanel.init('d051baf39ffda3bf75a7b21ddff1eef4', { debug: false });

function SaleHeader() {
    const navigate = useNavigate();

    const openCohortStripePurchasePage = () => {
        mixpanel.track('LandingPage', {
            'click': 'register_cohort_button'
        })
        window.open('https://buy.stripe.com/5kAaHO5335r7cEwfZS', '_blank')
    }

    const Ref = useRef(null);
    const [timeReady, setTimeReady] = useState(false)
    const [remainingHours, setRemainingHours] = useState('00')
    const [remainingMinutes, setRemainingMinutes] = useState('00')
    const [remainingSeconds, setRemainingSeconds] = useState('00')

    const getTimeRemaining = (time) => {
        const total = Date.parse(time) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }

    const updateTimer = (time) => {
        let { total, hours, minutes, seconds } = getTimeRemaining(time);
        if (total >= 0) {
            setRemainingHours(hours > 9 ? hours : '0' + hours)
            setRemainingMinutes(minutes > 9 ? minutes : '0' + minutes)
            setRemainingSeconds(seconds > 9 ? seconds : '0' + seconds)
        }
    }

    const startTimer = (time) => {
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            updateTimer(time);
        }, 1000)
        Ref.current = id;
    }

    useEffect(() => {
        const pageOpened = localStorage.getItem('openTime')
        if (pageOpened) {
            const pageOpenedDate = new Date(pageOpened)
            const day = pageOpenedDate.getDate()
            const currentDay = new Date().getDate()
            if (day !== currentDay) {
                let deadline = new Date()
                localStorage.setItem('openTime', deadline)
                deadline.setSeconds(deadline.getSeconds() + 5400)
                startTimer(deadline)
                setTimeReady(true)
            } else {
                pageOpenedDate.setSeconds(pageOpenedDate.getSeconds() + 5400)
                startTimer(pageOpenedDate)
                setTimeReady(true)
            }
        } else {
            let deadline = new Date()
            localStorage.setItem('openTime', deadline)
            deadline.setSeconds(deadline.getSeconds() + 5400)
            startTimer(deadline)
            setTimeReady(true)
        }
    }, [])


    return (
        <>
            <section id='nav_bar_blockerx'>
                <div className='home_brand_area'>
                    <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png' alt='brand-logo'></img>
                </div>
                <div className='home_butn_area'>
                    <button id='signupForNewUser' onClick={() => {
                        mixpanel.track('LandingPage', {
                            'click': 'signup_button'
                        })
                        navigate("/signup")
                    }}>Signup</button>
                    <button onClick={() => {
                        mixpanel.track('LandingPage', {
                            'click': 'reset_password_button'
                        })
                        navigate("/forgotPassword")
                    }}>Forgot Password</button>
                </div>
            </section>
            {/* <section id='cohort_program_banner'>
                <div className='cohort_content_area'>
                    <div className='cohort_content_heading'>
                        <h1>PORN ADDICTION RECOVERY PROGRAM</h1>
                    </div>
                    <div className='cohort_content_subheading'>
                        <p>Overcome your addiction by participating in specialized live sessions hosted by our expert.</p>
                    </div>
                    <div className='cohort_content_keys'>
                        <div className='keys_point add_border'>
                            <h3>Live Sessions</h3>
                            <p>On Google Meet</p>
                        </div>
                        <div className='keys_point add_border'>
                            <h3>3 Weeks</h3>
                            <p>Cohort Program</p>
                        </div>
                        <div className='keys_point add_border'>
                            <h3>Practical Advice</h3>
                            <p>From our experts</p>
                        </div>
                        <div className='keys_point'>
                            <h3>Complete</h3>
                            <p>Recovery plan</p>
                        </div>
                    </div>
                    <div className='cohort_master_info'>
                        <div className='cohort_master_img_area'>
                            <img src={masterImg} alt='master' />
                        </div>
                        <div className='cohort_master_extras'>
                            <h2>Our Expert: <strong>Nicholas Nissen, M.D.</strong></h2>
                            <p>Dr. Nissen is an American physician, author, and influencer. He is a Clinical Fellow in the Department of Psychiatry at Harvard Medical School and a correspondent for ABC News with frequent media appearances. He is the host of the Brain Health with Dr. Nissen Podcast, where he discusses the latest evidence-based approaches to enhancing mental health, sharpening cognition, and optimizing performance.</p>
                        </div>
                    </div>
                </div>
                <div className='cohort_pricing_card'>
                    <div className='cohort_session_info'>
                        <span>Sessions start on</span>
                        <h2>August 6</h2>
                    </div>
                    <div className='cohort_session_info'>
                        <span>Time commitment</span>
                        <h2>2-3 hours / week</h2>
                    </div>
                    <div className='cohort_session_info'>
                        <span>Seats left</span>
                        <h2>21</h2>
                    </div>
                    <div className='cohort_session_pricings'>
                        <div className='cohort_pricing_area'>
                            <div className='cohort_pricing'>
                                <span>Get this program for</span>
                                <div className='cohort_actual_price'>
                                    <h3>$199.99</h3>
                                    <h4><strike>$399.99</strike></h4>
                                </div>
                            </div>
                            <div className='cohort_pricing_offer'>
                                <strong>50%</strong>
                                <span>Off</span>
                                <div className='cohort_ribbon_cut'></div>
                            </div>
                        </div>
                        <div className='cohort_register_button'>
                            <button onClick={openCohortStripePurchasePage}>
                                <h3>Register Now</h3>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                        </div>
                    </div>
                </div>
            </section> */}
            {(() => {
                if (timeReady) {
                    return (
                        <section id='sale_header'>
                            <div className='details_area'>
                                <div className='details_tagline'>
                                    <h2>Quitting Porn Has Never Been Easier!</h2>
                                </div>
                                <div className='details_countdown'>
                                    <h3>Offer ends in <strong>{remainingHours}</strong> hours <strong>{remainingMinutes}</strong> minutes <strong>{remainingSeconds}</strong> seconds</h3>
                                </div>
                            </div>
                            <div className='deal_area'>
                                <h1 className='supersale'>SAVE 73% NOW!</h1>
                            </div>
                        </section>
                    )
                }
            })()}
        </>
    )
}

export default SaleHeader