import './signupPage.css'
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import axios from 'axios';
import auth, { provider } from '../../Connections/firebase';
import { signInWithPopup } from 'firebase/auth';

function SignupPage() {
    const navigate = useNavigate();

    function signupUser(email, password, newsletter) {
        return new Promise((resolve, reject) => {
            axios.post('https://accounts.blockerx.net/api/v2/signUp', {
                provider: false,
                email,
                newsletter,
                password
            })
                .then((response) => {
                    if (response.data.status === "success") {
                        resolve({
                            status: 200,
                            message: response.data.message
                        })
                    } else if (response.data.status === 'error' && response.data.message !== null && response.data.message !== undefined) {
                        resolve({
                            status: 200,
                            message: response.data.message
                        })

                    } else {
                        resolve({
                            status: 404
                        })
                    }
                })
                .catch((error2) => {
                    resolve({
                        status: 404
                    })
                });
        })
    }

    async function createAccountusingCredentials(email, password, newsletter) {
        const USERINFO = await signupUser(email, password, newsletter)
        if (USERINFO && USERINFO.status === 200) {
            alert(USERINFO.message)
            navigate("/")
        } else {
            alert('Something went wrong, Please try again')
        }
    }

    function signInWithGoogle() {
        return new Promise((resolve, reject) => {
            signInWithPopup(auth, provider)
                .then((result) => {
                    axios.post('https://accounts.blockerx.net/api/v2/signUp', {
                        provider: true,
                        email: result.user.email,
                        newsletter: false,
                        uid: result.user.uid
                    })
                        .then((response2) => {
                            if (response2.data.status === "success") {
                                resolve({
                                    status: 200
                                })
                            } else {
                                resolve({
                                    status: 404
                                })
                            }
                        })
                        .catch((error2) => {
                            resolve({
                                status: 404
                            })
                        });
                })
                .catch((error) => {
                    resolve({
                        status: 404
                    })
                })
        })
    }

    async function createAccountusingGoogle() {
        const GOOGLE_RES = await signInWithGoogle()
        if (GOOGLE_RES && GOOGLE_RES.status === 200) {
            alert('Congratulation! Your account has been successfully created')
            navigate("/")
        }
    }

    const [new_user_email, set_new_user_email] = useState("")
    const [new_user_password1, set_new_user_password1] = useState("")
    const [new_user_password2, set_new_user_password2] = useState("")
    const [newsletterStatus, setNewsletterStatus] = useState(false)

    const googleSignIn = () => {
        createAccountusingGoogle()
    }

    const signUpNow = () => {
        if (new_user_email !== null && new_user_email !== undefined && typeof new_user_email === 'string' && new_user_email.trim().length > 0) {
            set_new_user_email(new_user_email.trim())
            if (new_user_password1 !== null && new_user_password1 !== undefined && typeof new_user_password1 === 'string' && new_user_password1.trim().length > 0) {
                set_new_user_password1(new_user_password1.trim())
                if (new_user_password2 !== null && new_user_password2 !== undefined && typeof new_user_password2 === 'string' && new_user_password2.trim().length > 0) {
                    set_new_user_password2(new_user_password2.trim())
                    if (new_user_password1.length > 4 && new_user_password2.length > 4) {
                        if (new_user_password1 === new_user_password2) {
                            createAccountusingCredentials(new_user_email, new_user_password1, newsletterStatus)
                            set_new_user_email("")
                            set_new_user_password1("")
                            set_new_user_password2("")
                            setNewsletterStatus(false)
                        } else {
                            alert("Passwords should be same")
                        }
                    } else {
                        alert("Password should be atleast 5 character long")
                    }
                } else {
                    alert("Confirm Password is required")
                }
            } else {
                alert("Password is required")
            }
        } else {
            alert("Email is required")
        }
    }

    return (
        <section className='signup_page_container'>
            <div className='signup_head'>
                <div className='signup_head_brand'>
                    <img onClick={() => navigate("/")} src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png' alt='brand-logo' />
                </div>
                <div className='signup_head_btns'>
                    <button onClick={() => navigate("/forgotPassword")}>Forgot Password</button>
                </div>
            </div>
            <div className='signup_body'>
                <div className='signup_body_area'>
                    <div className='signup_body_text'>
                        <h2>Register Now</h2>
                        <span>Freedom from porn is just a click away. Get started on your journey of transformation</span>
                    </div>
                    <div className='signup_body_form'>
                        <input type='text' value={new_user_email} onChange={(e) => { set_new_user_email(e.target.value) }} placeholder='Enter your Email' />
                        <input type="password" value={new_user_password1} onChange={(e) => { set_new_user_password1(e.target.value) }} placeholder="Enter Password (At least 5 character long)" />
                        <input type="password" value={new_user_password2} onChange={(e) => { set_new_user_password2(e.target.value) }} placeholder="Confirm Password (At least 5 character long)" />
                        <div className="newsletter_area">
                            <label>
                                <input type="checkbox" name="newsletterStatus" checked={newsletterStatus} onChange={(e) => { setNewsletterStatus(e.target.checked) }} />Subscribe to our Newsletter
                            </label>
                        </div>
                        <button onClick={signUpNow} id="signupNow">Signup</button>
                    </div>
                    <div className="partition">
                        <span>or</span>
                    </div>
                    <div className="google_area">
                        <button onClick={googleSignIn} id="google">
                            Signup with  <img width="25px" src="https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/googleIcon.png" alt="google_icon" />
                        </button>
                    </div>
                    {/* <div className='already_account_login'>
                        Already have an account <button onClick={() => navigate("/signin")}>Login</button>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default SignupPage;