import * as React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './premiumPage.css'
import masterImg from './group.png'
import crown from './Isolation_Mode.png'
import freedom from './freedom.png'
import ben1 from './benifits/ben-1.png'
import ben2 from './benifits/ben-2.png'
import ben3 from './benifits/ben-3.png'
import ben4 from './benifits/ben-4.png'
import ben5 from './benifits/ben-5.png'
import ben6 from './benifits/ben-6.png'
import ben7 from './benifits/ben-7.png'
import controlImg from './control.png'
import BenifitCard from './premiumBenifitCard';
import mixpanel from 'mixpanel-browser';

mixpanel.init('d051baf39ffda3bf75a7b21ddff1eef4', { debug: false });

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 8
    }, 
    desktop: {
        breakpoint: { max: 3000, min: 1700 },
        items: 5
    },
    smallDesktop: {
        breakpoint: { max: 1700, min: 1500 },
        items: 4
    },
    microDesktop: {
        breakpoint: { max: 1500, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1100, min: 700 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 700, min: 0 },
        items: 1
    }
}

const benifitsArr = [
    {
        image: ben1,
        title: 'Wrong ideas about sex and relationships'
    }, {
        image: ben2,
        title: 'Time and productivity loss'
    }, {
        image: ben3,
        title: 'Sexual problems and dissatisfaction'
    }, {
        image: ben4,
        title: 'Financial consequences'
    }, {
        image: ben5,
        title: 'Relationship breakdown'
    }, {
        image: ben6,
        title: 'Escalation and desensitization'
    }, {
        image: ben7,
        title: 'Emotional and mental health problems'
    },
]

function PremiumPage() {
    const openLifetimePurchaseForApp = () => {
        window.open('https://buy.stripe.com/6oEeY4eDD9Hn8ogfZo', '_blank');
    }
    const openAnnualPurchaseForApp = () => {
        window.open('https://buy.stripe.com/dR6eY4brr6vbgUM9AY', '_blank');
    }
    return (
        <>
            <div className="premiumPage">
                <section id='premiumPageHeader'>
                    <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png' alt='Brand_Logo' />
                </section>
                <section id='premiumPageHeroArea'>
                    <div className='premium_hero_data'>
                        <div className='hero_data_headings'>
                            <h1><strong>Transform</strong> your journey,</h1>
                            <h1>Conquer your <strong>urges</strong></h1>
                        </div>
                        <div className='hero_data_subheading'>
                            <p>Let BlockerX porn control be your guiding light on the path to recovery and a brighter, healthier future</p>
                        </div>
                        <div className='hero_data_cta_button'>
                            <button onClick={() => {
                                mixpanel.track('PremiumPage', {
                                    'click': 'get_premium'
                                })
                                openAnnualPurchaseForApp()
                            }}>Get Premium</button>
                        </div>
                    </div>
                    <div className='premium_hero_bg_img'>
                        <img src={masterImg} alt='bg_Img' />
                    </div>
                </section>
                <section id='premiumPagePricingArea'>
                    <div className='premium_pricing_inner'>
                        <div className='premium_pricing_head'>
                            <img src={crown} alt='crown' />
                            <h2>BlockerX Premium</h2>
                        </div>
                        <div className='premium_pricing_container'>
                            <div id='annual_plan_card_simple' className='pricing_card_simple'>
                                <div className='simple_card_tag'>
                                    {/* <span>Most Popular</span> */}
                                </div>
                                <div className='simple_card_plan'>
                                    <span>12 month plan</span>
                                </div>
                                <div className='simple_card_pricing'>
                                    <h2>$109.97 </h2>
                                    <span>/year</span>
                                </div>
                                <div className='simple_card_pricing_info'>
                                    <p>Billed <strike>$239.64</strike> $109.97 every year</p>
                                </div>
                                <div className='simple_card_pointers'>
                                    <span className='single_pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <g clip-path="url(#clip0_16_7988)">
                                                <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.78125 7.60766C0.78125 5.84541 1.4813 4.15533 2.7274 2.90923C3.97351 1.66313 5.66358 0.963074 7.42584 0.963074C9.18809 0.963074 10.8782 1.66313 12.1243 2.90923C13.3704 4.15533 14.0704 5.84541 14.0704 7.60766C14.0704 9.36992 13.3704 11.06 12.1243 12.3061C10.8782 13.5522 9.18809 14.2522 7.42584 14.2522C5.66358 14.2522 3.97351 13.5522 2.7274 12.3061C1.4813 11.06 0.78125 9.36992 0.78125 7.60766ZM7.04665 10.4515L10.8722 5.66921L10.1811 5.11638L6.91908 9.19262L4.60853 7.26746L4.04153 7.94786L7.04665 10.4524V10.4515Z" fill="#00B894" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_16_7988">
                                                    <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        3 day money-back guarantee
                                    </span>
                                    <span className='single_pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <g clip-path="url(#clip0_16_7988)">
                                                <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.78125 7.60766C0.78125 5.84541 1.4813 4.15533 2.7274 2.90923C3.97351 1.66313 5.66358 0.963074 7.42584 0.963074C9.18809 0.963074 10.8782 1.66313 12.1243 2.90923C13.3704 4.15533 14.0704 5.84541 14.0704 7.60766C14.0704 9.36992 13.3704 11.06 12.1243 12.3061C10.8782 13.5522 9.18809 14.2522 7.42584 14.2522C5.66358 14.2522 3.97351 13.5522 2.7274 12.3061C1.4813 11.06 0.78125 9.36992 0.78125 7.60766ZM7.04665 10.4515L10.8722 5.66921L10.1811 5.11638L6.91908 9.19262L4.60853 7.26746L4.04153 7.94786L7.04665 10.4524V10.4515Z" fill="#00B894" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_16_7988">
                                                    <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Accountability Partner
                                    </span>
                                    <span className='single_pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <g clip-path="url(#clip0_16_7988)">
                                                <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.78125 7.60766C0.78125 5.84541 1.4813 4.15533 2.7274 2.90923C3.97351 1.66313 5.66358 0.963074 7.42584 0.963074C9.18809 0.963074 10.8782 1.66313 12.1243 2.90923C13.3704 4.15533 14.0704 5.84541 14.0704 7.60766C14.0704 9.36992 13.3704 11.06 12.1243 12.3061C10.8782 13.5522 9.18809 14.2522 7.42584 14.2522C5.66358 14.2522 3.97351 13.5522 2.7274 12.3061C1.4813 11.06 0.78125 9.36992 0.78125 7.60766ZM7.04665 10.4515L10.8722 5.66921L10.1811 5.11638L6.91908 9.19262L4.60853 7.26746L4.04153 7.94786L7.04665 10.4524V10.4515Z" fill="#00B894" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_16_7988">
                                                    <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Uninstall Protection on Android
                                    </span>
                                    <span className='single_pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <g clip-path="url(#clip0_16_7988)">
                                                <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.78125 7.60766C0.78125 5.84541 1.4813 4.15533 2.7274 2.90923C3.97351 1.66313 5.66358 0.963074 7.42584 0.963074C9.18809 0.963074 10.8782 1.66313 12.1243 2.90923C13.3704 4.15533 14.0704 5.84541 14.0704 7.60766C14.0704 9.36992 13.3704 11.06 12.1243 12.3061C10.8782 13.5522 9.18809 14.2522 7.42584 14.2522C5.66358 14.2522 3.97351 13.5522 2.7274 12.3061C1.4813 11.06 0.78125 9.36992 0.78125 7.60766ZM7.04665 10.4515L10.8722 5.66921L10.1811 5.11638L6.91908 9.19262L4.60853 7.26746L4.04153 7.94786L7.04665 10.4524V10.4515Z" fill="#00B894" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_16_7988">
                                                    <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Uninstall notification on iOS
                                    </span>
                                    <span className='single_pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <g clip-path="url(#clip0_16_7988)">
                                                <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.78125 7.60766C0.78125 5.84541 1.4813 4.15533 2.7274 2.90923C3.97351 1.66313 5.66358 0.963074 7.42584 0.963074C9.18809 0.963074 10.8782 1.66313 12.1243 2.90923C13.3704 4.15533 14.0704 5.84541 14.0704 7.60766C14.0704 9.36992 13.3704 11.06 12.1243 12.3061C10.8782 13.5522 9.18809 14.2522 7.42584 14.2522C5.66358 14.2522 3.97351 13.5522 2.7274 12.3061C1.4813 11.06 0.78125 9.36992 0.78125 7.60766ZM7.04665 10.4515L10.8722 5.66921L10.1811 5.11638L6.91908 9.19262L4.60853 7.26746L4.04153 7.94786L7.04665 10.4524V10.4515Z" fill="#00B894" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_16_7988">
                                                    <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Access to Community
                                    </span>
                                    <span className='single_pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <g clip-path="url(#clip0_16_7988)">
                                                <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.78125 7.60766C0.78125 5.84541 1.4813 4.15533 2.7274 2.90923C3.97351 1.66313 5.66358 0.963074 7.42584 0.963074C9.18809 0.963074 10.8782 1.66313 12.1243 2.90923C13.3704 4.15533 14.0704 5.84541 14.0704 7.60766C14.0704 9.36992 13.3704 11.06 12.1243 12.3061C10.8782 13.5522 9.18809 14.2522 7.42584 14.2522C5.66358 14.2522 3.97351 13.5522 2.7274 12.3061C1.4813 11.06 0.78125 9.36992 0.78125 7.60766ZM7.04665 10.4515L10.8722 5.66921L10.1811 5.11638L6.91908 9.19262L4.60853 7.26746L4.04153 7.94786L7.04665 10.4524V10.4515Z" fill="#00B894" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_16_7988">
                                                    <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Auto-Syncing
                                    </span>
                                    <span className='single_pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <g clip-path="url(#clip0_16_7988)">
                                                <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.78125 7.60766C0.78125 5.84541 1.4813 4.15533 2.7274 2.90923C3.97351 1.66313 5.66358 0.963074 7.42584 0.963074C9.18809 0.963074 10.8782 1.66313 12.1243 2.90923C13.3704 4.15533 14.0704 5.84541 14.0704 7.60766C14.0704 9.36992 13.3704 11.06 12.1243 12.3061C10.8782 13.5522 9.18809 14.2522 7.42584 14.2522C5.66358 14.2522 3.97351 13.5522 2.7274 12.3061C1.4813 11.06 0.78125 9.36992 0.78125 7.60766ZM7.04665 10.4515L10.8722 5.66921L10.1811 5.11638L6.91908 9.19262L4.60853 7.26746L4.04153 7.94786L7.04665 10.4524V10.4515Z" fill="#00B894" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_16_7988">
                                                    <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        No Ads
                                    </span>
                                </div>
                                <div className='simple_card_button_area'>
                                    <button onClick={() => {
                                        mixpanel.track('PremiumPage', {
                                            'click': 'annual_get_plan'
                                        })
                                        openAnnualPurchaseForApp()
                                    }}>Get Plan</button>
                                </div>
                            </div>
                            <div id='lifetime_plan_card_simple' className='pricing_card_simple'>
                                <div className='simple_card_tag'>
                                    <span>Best Value</span>
                                </div>
                                <div className='simple_card_plan'>
                                    <span>Lifetime Plan</span>
                                </div>
                                <div className='simple_card_pricing'>
                                    <h2>$129.97 </h2>
                                    <span></span>
                                </div>
                                <div className='simple_card_pricing_info'>
                                    <p>One time payment of <strike>$479.28</strike> $129.97</p>
                                </div>
                                <div className='simple_card_pointers'>
                                    <span className='single_pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <g clip-path="url(#clip0_16_7988)">
                                                <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.78125 7.60766C0.78125 5.84541 1.4813 4.15533 2.7274 2.90923C3.97351 1.66313 5.66358 0.963074 7.42584 0.963074C9.18809 0.963074 10.8782 1.66313 12.1243 2.90923C13.3704 4.15533 14.0704 5.84541 14.0704 7.60766C14.0704 9.36992 13.3704 11.06 12.1243 12.3061C10.8782 13.5522 9.18809 14.2522 7.42584 14.2522C5.66358 14.2522 3.97351 13.5522 2.7274 12.3061C1.4813 11.06 0.78125 9.36992 0.78125 7.60766ZM7.04665 10.4515L10.8722 5.66921L10.1811 5.11638L6.91908 9.19262L4.60853 7.26746L4.04153 7.94786L7.04665 10.4524V10.4515Z" fill="#00B894" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_16_7988">
                                                    <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        3 day money-back guarantee
                                    </span>
                                    <span className='single_pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <g clip-path="url(#clip0_16_7988)">
                                                <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.78125 7.60766C0.78125 5.84541 1.4813 4.15533 2.7274 2.90923C3.97351 1.66313 5.66358 0.963074 7.42584 0.963074C9.18809 0.963074 10.8782 1.66313 12.1243 2.90923C13.3704 4.15533 14.0704 5.84541 14.0704 7.60766C14.0704 9.36992 13.3704 11.06 12.1243 12.3061C10.8782 13.5522 9.18809 14.2522 7.42584 14.2522C5.66358 14.2522 3.97351 13.5522 2.7274 12.3061C1.4813 11.06 0.78125 9.36992 0.78125 7.60766ZM7.04665 10.4515L10.8722 5.66921L10.1811 5.11638L6.91908 9.19262L4.60853 7.26746L4.04153 7.94786L7.04665 10.4524V10.4515Z" fill="#00B894" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_16_7988">
                                                    <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Accountability Partner
                                    </span>
                                    <span className='single_pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <g clip-path="url(#clip0_16_7988)">
                                                <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.78125 7.60766C0.78125 5.84541 1.4813 4.15533 2.7274 2.90923C3.97351 1.66313 5.66358 0.963074 7.42584 0.963074C9.18809 0.963074 10.8782 1.66313 12.1243 2.90923C13.3704 4.15533 14.0704 5.84541 14.0704 7.60766C14.0704 9.36992 13.3704 11.06 12.1243 12.3061C10.8782 13.5522 9.18809 14.2522 7.42584 14.2522C5.66358 14.2522 3.97351 13.5522 2.7274 12.3061C1.4813 11.06 0.78125 9.36992 0.78125 7.60766ZM7.04665 10.4515L10.8722 5.66921L10.1811 5.11638L6.91908 9.19262L4.60853 7.26746L4.04153 7.94786L7.04665 10.4524V10.4515Z" fill="#00B894" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_16_7988">
                                                    <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Uninstall Protection on Android
                                    </span>
                                    <span className='single_pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <g clip-path="url(#clip0_16_7988)">
                                                <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.78125 7.60766C0.78125 5.84541 1.4813 4.15533 2.7274 2.90923C3.97351 1.66313 5.66358 0.963074 7.42584 0.963074C9.18809 0.963074 10.8782 1.66313 12.1243 2.90923C13.3704 4.15533 14.0704 5.84541 14.0704 7.60766C14.0704 9.36992 13.3704 11.06 12.1243 12.3061C10.8782 13.5522 9.18809 14.2522 7.42584 14.2522C5.66358 14.2522 3.97351 13.5522 2.7274 12.3061C1.4813 11.06 0.78125 9.36992 0.78125 7.60766ZM7.04665 10.4515L10.8722 5.66921L10.1811 5.11638L6.91908 9.19262L4.60853 7.26746L4.04153 7.94786L7.04665 10.4524V10.4515Z" fill="#00B894" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_16_7988">
                                                    <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Uninstall notification on iOS
                                    </span>
                                    <span className='single_pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <g clip-path="url(#clip0_16_7988)">
                                                <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.78125 7.60766C0.78125 5.84541 1.4813 4.15533 2.7274 2.90923C3.97351 1.66313 5.66358 0.963074 7.42584 0.963074C9.18809 0.963074 10.8782 1.66313 12.1243 2.90923C13.3704 4.15533 14.0704 5.84541 14.0704 7.60766C14.0704 9.36992 13.3704 11.06 12.1243 12.3061C10.8782 13.5522 9.18809 14.2522 7.42584 14.2522C5.66358 14.2522 3.97351 13.5522 2.7274 12.3061C1.4813 11.06 0.78125 9.36992 0.78125 7.60766ZM7.04665 10.4515L10.8722 5.66921L10.1811 5.11638L6.91908 9.19262L4.60853 7.26746L4.04153 7.94786L7.04665 10.4524V10.4515Z" fill="#00B894" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_16_7988">
                                                    <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Access to Community
                                    </span>
                                    <span className='single_pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <g clip-path="url(#clip0_16_7988)">
                                                <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.78125 7.60766C0.78125 5.84541 1.4813 4.15533 2.7274 2.90923C3.97351 1.66313 5.66358 0.963074 7.42584 0.963074C9.18809 0.963074 10.8782 1.66313 12.1243 2.90923C13.3704 4.15533 14.0704 5.84541 14.0704 7.60766C14.0704 9.36992 13.3704 11.06 12.1243 12.3061C10.8782 13.5522 9.18809 14.2522 7.42584 14.2522C5.66358 14.2522 3.97351 13.5522 2.7274 12.3061C1.4813 11.06 0.78125 9.36992 0.78125 7.60766ZM7.04665 10.4515L10.8722 5.66921L10.1811 5.11638L6.91908 9.19262L4.60853 7.26746L4.04153 7.94786L7.04665 10.4524V10.4515Z" fill="#00B894" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_16_7988">
                                                    <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Auto-Syncing
                                    </span>
                                    <span className='single_pointer'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <g clip-path="url(#clip0_16_7988)">
                                                <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.78125 7.60766C0.78125 5.84541 1.4813 4.15533 2.7274 2.90923C3.97351 1.66313 5.66358 0.963074 7.42584 0.963074C9.18809 0.963074 10.8782 1.66313 12.1243 2.90923C13.3704 4.15533 14.0704 5.84541 14.0704 7.60766C14.0704 9.36992 13.3704 11.06 12.1243 12.3061C10.8782 13.5522 9.18809 14.2522 7.42584 14.2522C5.66358 14.2522 3.97351 13.5522 2.7274 12.3061C1.4813 11.06 0.78125 9.36992 0.78125 7.60766ZM7.04665 10.4515L10.8722 5.66921L10.1811 5.11638L6.91908 9.19262L4.60853 7.26746L4.04153 7.94786L7.04665 10.4524V10.4515Z" fill="#00B894" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_16_7988">
                                                    <rect x="0.78125" y="0.963013" width="13.2892" height="13.2892" rx="6.64459" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        No Ads
                                    </span>
                                </div>
                                <div className='simple_card_button_area'>
                                    <button onClick={() => {
                                        mixpanel.track('PremiumPage', {
                                            'click': 'lifetime_get_plan'
                                        })
                                        openLifetimePurchaseForApp()
                                    }}>Get Plan</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='premiumPageFreedomArea'>
                    <div className='freedom_container_head'>
                        <h2>Unlock a Life of <strong>Freedom</strong></h2>
                        <p>Experience the Empowering Benefits of Quitting Porn and Rediscover True Joy, Fulfillment, and Authentic Connections</p>
                    </div>
                    <div className='freedom_container_pointers'>
                        <img src={freedom} alt='freedom' />
                    </div>
                </section>
                <section id='premiumPageChainArea'>
                    <h2>Breaking the Chains</h2>
                    <Carousel
                        responsive={responsive}
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        keyBoardControl={false}
                        removeArrowOnDeviceType={['superLargeDesktop', 'desktop', 'smallDesktop', 'microDesktop']}
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={2000}
                        customTransition='transform 2000ms linear'
                        className='benifits_container'>
                        {benifitsArr.map((singleCard) => {
                            return (
                                <BenifitCard    
                                    image={singleCard.image}
                                    title={singleCard.title}
                                />
                            )
                        })} 
                    </Carousel>
                </section>
                <section id='premiumPageFirstStepArea'>
                    <div className='first_step_container'>
                        <h2>Take the First Step</h2>
                        <p>Your journey towards freedom begins now. Join the countless individuals who have reclaimed their lives with our Porn Control Tool. Sign up today and take the first step towards a future filled with choice, empowerment, and fulfillment.</p>
                    </div>
                </section>
                <section id='premiumPageControlArea'>
                    <div className='control_content_area'>
                        <div className='control_description'>
                            <p><strong>Ready to take control</strong> and break free from porn addiction? Sign up now and start your journey towards a healthier, more fulfilling life.</p>
                        </div>
                        <div className='control_pointers'>
                            <span>✓ Block all porn websites in one click</span>
                            <span>✓ Accountability Partner</span>
                            <span>✓ Prevent Uninstall</span>
                            <span>✓ Access to Community</span>
                            <span>✓ Use across all devices</span>
                            <span>✓ No ads</span>
                        </div>
                        <div className='control_cta_container'>
                            <button onClick={() => {
                                mixpanel.track('PremiumPage', {
                                    'click': 'get_blockerX'
                                })
                                window.open('https://blockerx.onelink.me/oeB0/0joi3d8i', '_blank');
                            }}>Get BlockerX</button>
                        </div>
                    </div>
                    <div className='control_img_area'>
                        <img src={controlImg} alt='control' />
                    </div>
                </section>
                <section id='premiumPageDownloadsArea'>
                    <div className='downloads_head_container'>
                        <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png' alt='BlockerX' />
                    </div>
                </section>
            </div>
        </>
    );
}

export default PremiumPage;