import { useParams, useNavigate } from "react-router-dom";
import "./automation.css";
import { useEffect, useState } from "react";
import load from "../SupportChat/loading-paper.gif";
import orderIdHelp from "./order_id_help.png";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleDown, faCircleXmark, faCircleInfo, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import mixpanel from "mixpanel-browser";
import { CardActionArea, CardActions, Grid } from "@mui/material";

mixpanel.init("d051baf39ffda3bf75a7b21ddff1eef4", { debug: false });

function AutomationPage() {
    const { category, subcategory, uid, email } = useParams();
    console.log({ category, subcategory, uid, email });

    const paramsAppName = new URLSearchParams(window.location.search).get(
        "appName"
    );
    const paramsPlatform = new URLSearchParams(window.location.search).get(
        "platform"
    );
    const paramsToken = new URLSearchParams(window.location.search).get(
        "token"
    );

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [resultingStatement, setResultingStatement] = useState(
        "Your Issue has been Fixed"
    );
    const [refundPage, setRefundPage] = useState(false);
    const [googleActivationPage, setGoogleActivationPage] = useState(false)
    const [stripeActivationPage, setStripeActivationPage] = useState(false)
    const [orderIdInfoPopup, setOrderIdInfoPopup] = useState(false)
    const [removePartnerPage, setRemovePartnerPage] = useState(false)
    const [restoreStreakPage, setRestoreStreakPage] = useState(false)
    const [directAutomation, setDirectAutomation] = useState(false)
    const [showPartnerRemovalPopup, setShowPartnerRemovalPopup] = useState(false)
    const [showAPKUpdatePopup, setShowAPKUpdatePopup] = useState(false)
    const [showForceUninstallPopup, setShowForceUninstallPopup] = useState(false)
    const [premiumList, setPremiumList] = useState([]);
    const [partner, setPartner] = useState('Not Present')
    const [forceBuddyRemoveStatus, setForceBuddyRemoveStatus] = useState(true)
    const [streak, setStreak] = useState(null)
    const [lastStreak, setLastSteak] = useState(null)
    const [newSelectedFile, setNewSelectedFile] = useState(null)
    const [successStatusAfterAutomation, setSuccessStatusAfterAutomation] = useState(true)
    const [googleActivationEmail, setGoogleActivationEmail] = useState('')
    const [stripeActivationEmail, setStripeActivationEmail] = useState('')
    const [googleActivationOrderId, setGoogleActivationOrderId] = useState('')

    function streakIssueAutomation(type, uid, email) {
        return new Promise((resolve, reject) => {
            axios
                .post("https://accounts.blockerx.net/api/user/dashboard/streak", {
                    uid,
                    type,
                })
                .then((response) => {
                    if (type === "reset") {
                        resolve({
                            status: true,
                            message: "Your Streak has been Reset.",
                        });
                    } else {
                        resolve({
                            status: true,
                            message: "Your Streak has been Fixed",
                        });
                    }
                })
                .catch((error2) => {
                    resolve({
                        status: false,
                    });
                });
        });
    }

    function addUserToNoUpdatesList(uid) {
        return new Promise((resolve, reject) => {
            axios
                .post("https://events.blockerx.net/api/apk/users/update", {
                    uid
                })
                .then((response) => {
                    resolve({
                        status: true,
                        message: "You will not receive App updates anymore",
                    })
                })
                .catch((error2) => {
                    resolve({
                        status: false,
                    });
                });
        });
    }

    function turnOffForceUninstall(uid) {
        return new Promise((resolve, reject) => {
            axios
                .post("https://accounts.blockerx.net/api/v1/forceUninstall", {
                    uid,
                    dashboardUid: "65fbd1b92763334654a9b5b4",
                    status: false
                })
                .then((response) => {
                    resolve({
                        status: true,
                        message: "You can now uninstall the app",
                    })
                })
                .catch((error2) => {
                    resolve({
                        status: false,
                    });
                });
        });
    }

    function fetchUsersActivePremium(uid) {
        return new Promise((resolve, reject) => {
            axios
                .post("https://dev.blockerx.net/api/user/dashboard/premium/list", {
                    uid,
                })
                .then((response) => {
                    console.log('Premium Data', response.data);
                    if (response.data.status === 200) {
                        resolve({
                            status: true,
                            message:
                                "You have " +
                                response.data.data.premiumData.length +
                                " active purchases, associated with this account",
                            list: response.data.data.premiumData,
                        });
                    } else {
                        resolve({
                            status: false,
                            message: "There are no purchase associated with this account",
                            list: [],
                        });
                    }
                })
                .catch((error2) => {
                    resolve({
                        status: false,
                        message: "There are no purchase associated with this account",
                        list: [],
                    });
                });
        });
    }

    async function performAutomation(category, subcategory, uid, email) {
        switch (category) {
            case "streak": {
                const RESULT = await streakIssueAutomation(subcategory, uid, email);
                if (RESULT && RESULT.status && RESULT.message) {
                    setResultingStatement(RESULT.message);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
                break;
            }
            case "premium": {
                if (subcategory === "refund") {
                    const RESULT = await fetchUsersActivePremium(uid);
                    if (RESULT && RESULT.status && RESULT.list && RESULT.list.length) {
                        console.log(RESULT.list);
                        for (let i = 0; i < RESULT.list.length; i++) {
                            if (
                                RESULT.list[i].commonDisplayPlan === "lifetime" &&
                                new Date().getTime() - RESULT.list[i].purchasedAt < 3 * 86400000
                            ) {
                                RESULT.list[i].refundEligible = true;
                                RESULT.list[i].refundBtnId = 'refund_btn' + i
                            } else {
                                RESULT.list[i].refundEligible = false;
                            }
                            if (RESULT.list[i].paymentMethod === 'google' || RESULT.list[i].paymentMethod === 'ios' || RESULT.list[i].paymentMethod === 'stripe' || RESULT.list[i].paymentMethod === 'stripeUAE' || RESULT.list[i].paymentMethod === 'stripeUSA') {
                                RESULT.list[i].cancelEligible = true;
                                RESULT.list[i].cancelBtnId = 'cancel_btn' + i
                            }
                        }
                        setPremiumList(RESULT.list);
                        setRefundPage(true);
                        setResultingStatement(RESULT.message);
                        setLoading(false);
                    } else {
                        setRefundPage(true);
                        setResultingStatement(RESULT.message);
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
                break;
            }
            case "apk": {
                if (subcategory === "updates") {
                    setDirectAutomation(true)
                    setShowAPKUpdatePopup(true)
                    setLoading(false)
                    break;
                } else {
                    setLoading(false)
                }
                break;
            }
            case "switch": {
                if (subcategory === "forceUninstall") {
                    setDirectAutomation(true)
                    setShowForceUninstallPopup(true)
                    setLoading(false)
                } else {
                    setLoading(false)
                }
                break;
            }
            default: {
                setLoading(false);
                break;
            }
        }
    }

    const apkUpdatesFinal = async () => {
        setLoading(true)
        const RESULT = await addUserToNoUpdatesList(uid)
        if (RESULT && RESULT.status && RESULT.message) {
            setResultingStatement(RESULT.message);
            setDirectAutomation(false)
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const forceUninstallFinal = async () => {
        setLoading(true)
        const RESULT = await turnOffForceUninstall(uid);
        if (RESULT && RESULT.status && RESULT.message) {
            setResultingStatement(RESULT.message)
            setDirectAutomation(false)
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    async function authorizeAutomation(category, subcategory) {
        navigate(`/chatbot/automation/auth/${category}/${subcategory}`);
    }

    const processCancelationandRefund = (
        request,
        paymentMethod,
        planName,
        purchaseToken,
        btnId
    ) => {
        if (
            window.confirm(
                "This action cannot be reversed. Make sure you really want to do this"
            )
        ) {
            axios
                .post("https://accounts.blockerx.net/api/v2/cancellationAndRefund", {
                    uid,
                    request,
                    paymentMethod,
                    planName,
                    purchaseToken,
                    premiumStatus: "active",
                })
                .then((response) => {
                    if (request === "refund") {
                        mixpanel.track("AutomationPage", {
                            activity: "process_refund",
                            uid: uid,
                        });
                        alert("Successfully Initiated the Refund");
                        document.getElementById(btnId).disabled = true
                    } else {
                        mixpanel.track("AutomationPage", {
                            activity: "process_cancelation",
                            uid: uid,
                        });
                        alert("Successfully Initiated the Cancellation");
                        document.getElementById(btnId).disabled = true
                    }
                })
                .catch((error2) => {
                    alert("Your Request cannot be initiated. Try again later");
                });
        } else {
        }
    }

    const openAppleDocs = (type) => {
        if (type === "subscription") {
            window.open("https://support.apple.com/en-ae/HT202039", "_self");
        } else {
            window.open("https://support.apple.com/en-ae/HT204084", "_self");
        }
    };

    function getPortalforStripePurchase(uid, paymentMethod, purchaseToken) {
        return new Promise((resolve, reject) => {
            axios
                .post("https://dev.blockerx.net/api/v2/cancellationAndRefund", {
                    uid,
                    paymentMethod,
                    purchaseToken
                })
                .then((response) => {
                    console.log('Portal Res', response.data);
                    resolve({
                        status: true,
                        link: response.data.data
                    })
                })
                .catch((error2) => {
                    resolve({
                        status: false
                    })
                });
        })
    }

    async function openStripePortalForToken(uid, paymentMethod, purchaseToken) {
        const URL = await getPortalforStripePurchase(uid, paymentMethod, purchaseToken)
        if (URL.status && URL.link) {
            window.open(URL.link, '_self')
        } else {
            alert('Something went wrong')
        }
    }

    function loginUserUsingGoogle(email, uid) {
        return new Promise((resolve, reject) => {
            axios.post('https://accounts.blockerx.net/api/login/refund', {
                provider: true,
                email,
                uid
            })
                .then((response) => {
                    try {
                        if (response.data.status === 200) {
                            resolve(response.data.data.user)
                        } else {
                            resolve(null)
                        }
                    } catch {
                        resolve(null)
                    }
                })
                .catch((error2) => {
                    resolve(null)
                });
        })
    }

    async function getUsersPartnerInfo(email, uid) {
        const RESULT = await loginUserUsingGoogle(email, uid)
        console.log({ RESULT })
        setForceBuddyRemoveStatus(RESULT.forceBuddyRemoveStatus)
        setPartner(RESULT.partner)
        setRemovePartnerPage(true)
        setLoading(false)
    }

    async function getUsersStreakInfo(email, uid) {
        const RESULT = await loginUserUsingGoogle(email, uid)
        console.log('in11', RESULT)
        if (RESULT.streaks !== null && RESULT.streaks !== undefined && RESULT.streaks.length > 0) {
            setStreak(RESULT.streaks[0])
        }
        if (RESULT.streaks !== null && RESULT.streaks !== undefined && RESULT.streaks.length > 1) {
            setLastSteak(RESULT.streaks[1])
        }
        setRestoreStreakPage(true)
        setLoading(false)
    }

    const removeThisPartner = () => {
        setShowPartnerRemovalPopup(true)
    }

    const removePartnerFinal = () => {
        console.log('')
        if (partner === 'Not present') {
            console.log('in1')
            axios.post('https://accounts.blockerx.net/api/updateForceSwitches', {
                uid,
                switchName: "forceBuddyRemove",
                flag: true,
                secretKey: "friendly-bohr"
            })
                .then((response) => {
                    console.log({ response })
                    mixpanel.track('CustomizePage', {
                        'activity': 'remove_partner_any',
                        'uid': uid
                    })
                    alert('Successfully Removed your Current Partner. Open BlockerX App to view the Change')
                    setShowPartnerRemovalPopup(false)
                    document.getElementById('remove_partner_submit').disabled = true
                })
                .catch((error2) => {
                    console.log('in2', error2)
                    alert('Your Request cannot be initiated. Try again later')
                    setShowPartnerRemovalPopup(false)
                    console.log(error2)
                })
        } else {
            axios.post('https://accounts.blockerx.net/api/v1/partner', {
                uid,
                type: 'remove'
            })
                .then((response) => {
                    mixpanel.track('CustomizePage', {
                        'activity': 'remove_partner',
                        'uid': uid
                    })
                    alert('Successfully Removed your Current Partner. Open BlockerX App to view the Change')
                    setShowPartnerRemovalPopup(false)
                    document.getElementById('remove_partner_submit').disabled = true
                })
                .catch((error2) => {
                    alert('Your Request cannot be initiated. Try again later')
                    setShowPartnerRemovalPopup(false)
                    console.log(error2)
                })
        }
    }

    const restoreStreak = () => {
        if (window.confirm('This action cannot be reversed. Make sure you really want to do this')) {
            axios.post('https://accounts.blockerx.net/api/dashboard/user/streakreset', {
                activeId: streak._id,
                completeId: lastStreak._id
            })
                .then((response) => {
                    mixpanel.track('CustomizePage', {
                        'activity': 'restore_streak',
                        'uid': uid
                    })
                    alert('Successfully Restored your previous Streak. Open BlockerX App to view the Streak')
                    document.getElementById('restore_streak_switch_submit').disabled = true
                })
                .catch((error2) => {
                    alert('Your Request cannot be initiated. Try again later')
                    console.log(error2)
                });
        } else {

        }
    }

    function sendPremiumActivationRequest(paymentGateway, addedOrderId, addedEmail, addedFile) {
        return new Promise((resolve, reject) => {
            if (paymentGateway === 'google') {
                const formData = new FormData()
                formData.append('paymentGateway', paymentGateway)
                formData.append('orderId', addedOrderId)
                formData.append('email', addedEmail)
                formData.append('file', addedFile)
                axios.post('https://accounts.blockerx.net/api/user/premium/activation', formData)
                    .then((response) => {
                        resolve({
                            result: true,
                            message: 'You premium activation request will be assessed within 24hrs'
                        })
                    })
                    .catch((error2) => {
                        resolve({
                            result: false,
                            message: 'We are not able to process your request.'
                        })
                    });
            } else if (paymentGateway === 'stripe') {
                axios.post('https://accounts.blockerx.net/api/user/premium/activation', {
                    paymentGateway,
                    email: addedEmail
                })
                    .then((response) => {
                        resolve({
                            result: true,
                            message: response.data.data.info
                        })
                    })
                    .catch((error2) => {
                        resolve({
                            result: false,
                            message: 'We are not able to process your request.'
                        })
                    });
            } else {
                resolve({
                    result: false,
                    message: 'We are not able to process your request.'
                })
            }
        })
    }

    const submitGoogleActivation = async () => {
        setLoading(true)
        if (googleActivationEmail && googleActivationEmail !== undefined && googleActivationEmail.length > 0 && googleActivationEmail.includes('@')) {
            if (googleActivationOrderId && googleActivationOrderId !== undefined && googleActivationOrderId.trim().length > 0 && googleActivationOrderId.includes('GPA')) {
                const RESULT = await sendPremiumActivationRequest('google', googleActivationOrderId.trim(), googleActivationEmail.trim(), newSelectedFile)
                setGoogleActivationEmail('')
                setGoogleActivationOrderId('')
                setNewSelectedFile(null)
                if (RESULT && RESULT.result) {
                    setResultingStatement(RESULT.message)
                    setGoogleActivationPage(false)
                } else {
                    alert('Something went wrong. We are not able to process your request.')
                }
            } else {
                alert('Enter a valid order Number')
            }
        } else {
            alert('Enter a valid email address')
        }
        setLoading(false)
    }

    const submitStripeActivation = async () => {
        setLoading(true)
        console.log('activating...', stripeActivationEmail)
        if (stripeActivationEmail && stripeActivationEmail !== undefined && stripeActivationEmail.length > 0 && stripeActivationEmail.includes('@')) {
            const RESULT = await sendPremiumActivationRequest('stripe', null, stripeActivationEmail.trim(), null)
            console.log('RESULT is here', RESULT)
            setStripeActivationEmail('')
            if (RESULT && RESULT.result) {
                setResultingStatement(RESULT.message)
                setSuccessStatusAfterAutomation(false)
                setStripeActivationPage(false)
            } else {
                alert('Something went wrong. We are not able to process your request.')
            }
        } else {
            alert('Enter a valid email address')
        }
        setLoading(false)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (category !== "partner" && uid && email && uid !== "anonymous" && email !== "anonymous" && subcategory !== 'activate_google' && subcategory !== 'restore') {
                performAutomation(category, subcategory, uid, email)
            } else if (category === "premium" && subcategory === "activate_google") {
                setGoogleActivationPage(true)
                setLoading(false)
            } else if (category === "premium" && subcategory === "activate_stripe") {
                setStripeActivationPage(true)
                setLoading(false)
            } else if (category === "partner" && subcategory === "remove" && uid !== "anonymous" && email !== "anonymous") {
                getUsersPartnerInfo(email, uid)
            } else if (category === "streak" && subcategory === "restore" && uid !== "anonymous" && email !== "anonymous") {
                console.log('in10')
                getUsersStreakInfo(email, uid)
            } else {
                authorizeAutomation(category, subcategory)
            }
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <section id="chatbot_loading">
                <img src={load} alt="loading" />
            </section>
        );
    } else {
        if (refundPage) {
            return (
                <section id="automation_confirmation">
                    <div className="automation_success_area">
                        <h1> Premium Information </h1>
                        <p className="subheading_automation"> {resultingStatement} </p>
                        {premiumList.length ? (
                            <div className="all_premium_list">
                                {premiumList.map((singlePremium) => {
                                    return (
                                        <Card
                                            className="single_premium_info_card"
                                            sx={{ maxWidth: 320 }}
                                        >
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    {singlePremium.commonDisplayPlan[0].toUpperCase() +
                                                        singlePremium.commonDisplayPlan.slice(1)}{" "}
                                                    Plan
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    <strong>Payment Gateway: </strong>
                                                    {singlePremium.paymentMethod === "ios"
                                                        ? "Apple"
                                                        : singlePremium.paymentMethod === "google"
                                                            ? "Google Play"
                                                            : singlePremium.paymentMethod === "stripe" ||
                                                                singlePremium.paymentMethod === "stripeTest" ||
                                                                singlePremium.paymentMethod === "stripeUSA" ||
                                                                singlePremium.paymentMethod === "stripeUAE"
                                                                ? "Stripe"
                                                                : singlePremium.paymentMethod}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    <strong>Purchased At: </strong>
                                                    {new Date(singlePremium.purchasedAt).toUTCString()}
                                                </Typography>
                                            </CardContent>
                                            <div className="refund_btn_area">
                                                {singlePremium.cancelEligible ? (
                                                    <p>*Click below to View/Cancel your payment or subscription</p>
                                                ) : (
                                                    <></>
                                                )}
                                                {/* {singlePremium.paymentMethod === "ios" ? (
                                                    <button
                                                        onClick={() => {
                                                            openAppleDocs("charge");
                                                        }}
                                                        style={{ fontSize: "12px" }}
                                                        className="custom_cta_btn_apple"
                                                    >
                                                        Refund Purchase Docs{" "}
                                                        <FontAwesomeIcon
                                                            color="#474747"
                                                            icon={faArrowUpRightFromSquare}
                                                        />
                                                    </button>
                                                ) : singlePremium.refundEligible ? (
                                                    <button id={singlePremium.refundBtnId} onClick={() => {
                                                        processCancelationandRefund('refund', singlePremium.paymentMethod, singlePremium.planName, singlePremium.purchaseToken, singlePremium.cancelBtnId)
                                                    }}>Get Refund</button>
                                                ) : (
                                                    <button disabled>Get Refund</button>
                                                )} */}
                                                {singlePremium.paymentMethod === "ios" ? (
                                                    <button
                                                        onClick={() => {
                                                            openAppleDocs("subscription");
                                                        }}
                                                        className="custom_cta_btn_apple"
                                                    >
                                                        Manage Billing
                                                    </button>
                                                ) : singlePremium.cancelEligible ? (
                                                    <button id={singlePremium.cancelBtnId} onClick={() => {
                                                        if (singlePremium.paymentMethod === 'google') {
                                                            window.open('https://play.google.com/store/account/orderhistory', '_self')
                                                        } else if (singlePremium.paymentMethod === "stripe" ||
                                                            singlePremium.paymentMethod === "stripeTest" ||
                                                            singlePremium.paymentMethod === "stripeUSA" ||
                                                            singlePremium.paymentMethod === "stripeUAE") {
                                                            openStripePortalForToken(singlePremium.uid, singlePremium.paymentMethod, singlePremium.purchaseToken)
                                                        }
                                                    }}>Manage Billing</button>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            {singlePremium.refundEligible ? (
                                                <div className="refund_elligible_statement">
                                                    <p>Unsattisfied with the purchase?</p>
                                                    <button onClick={() => {
                                                        if (paramsAppName && paramsPlatform && email && uid) {
                                                            navigate(
                                                                "/ticket/create?appName=" +
                                                                paramsAppName +
                                                                "&platform=" +
                                                                paramsPlatform +
                                                                "&email=" +
                                                                email +
                                                                "&uid=" +
                                                                uid
                                                            );
                                                        } else {
                                                            navigate("/ticket/create");
                                                        }
                                                    }}>Claim Refund</button>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </Card>
                                    );
                                })}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    {/* <span
                        onClick={() => {
                            if (paramsAppName && paramsPlatform && email && uid) {
                                navigate(
                                    "/ticket/create?appName=" +
                                    paramsAppName +
                                    "&platform=" +
                                    paramsPlatform +
                                    "&email=" +
                                    email +
                                    "&uid=" +
                                    uid
                                );
                            } else {
                                navigate("/ticket/create");
                            }
                        }}
                    >
                        Still facing an issue? Register here
                    </span> */}
                </section>
            );
        } else if (googleActivationPage) {
            return (
                <section id="automation_google_activation">
                    <div className="automation_google_activation_form_area">
                        <h1> Please provide the below Details for Activating your Premium</h1>
                        <div className="automation_google_activation_inner_form">
                            <div className="automation_form_single_field">
                                <label><strong>Email*</strong> <span>(The email id you used to purchase premium)</span></label>
                                <input type="text" placeholder="Enter your Email" value={googleActivationEmail} onChange={(e) => { setGoogleActivationEmail(e.target.value) }} />
                            </div>
                            <div className="automation_form_single_field">
                                <label><strong>Order Number*</strong><span><FontAwesomeIcon
                                    color="#878787"
                                    icon={faCircleInfo}
                                    style={{ cursor: 'pointer', fontSize: '20px' }}
                                    onClick={() => { setOrderIdInfoPopup(true) }}
                                /></span></label>
                                <input type="text" placeholder="GPA.XXXX-XXXX-XXXX-XXXXX" value={googleActivationOrderId} onChange={(e) => { setGoogleActivationOrderId(e.target.value) }} />
                            </div>
                            <div className="automation_form_single_field">
                                <label><strong>Attach File*</strong> <span>(optional)</span></label>
                                <div className="automation_form_outer_field">
                                    <label htmlFor="newFile" className="sr-only">
                                        Choose File
                                    </label>
                                    <input id="newFile" type="file" accept='image/*,video/*' onChange={(e) => { setNewSelectedFile(e.target.files[0]) }} />
                                </div>
                            </div>
                            <div className="automation_form_single_submit">
                                <button onClick={submitGoogleActivation}>Submit</button>
                            </div>
                        </div>
                    </div>
                    {googleActivationPage && orderIdInfoPopup ? (
                        <div className="orderIdHelpWindow">
                            <div className="orderIdHelpWindowInner">
                                <div className="orderIdHelpClose">
                                    <FontAwesomeIcon
                                        color="#ffffff"
                                        icon={faCircleXmark}
                                        onClick={() => { setOrderIdInfoPopup(false) }}
                                    />
                                </div>
                                <div className="orderIdHelpImage">
                                    <img src={orderIdHelp} alt="orderId" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {/* <span className="automation_footer"
                        onClick={() => {
                            if (paramsAppName && paramsPlatform && email && uid) {
                                navigate(
                                    "/ticket/create?appName=" +
                                    paramsAppName +
                                    "&platform=" +
                                    paramsPlatform +
                                    "&email=" +
                                    email +
                                    "&uid=" +
                                    uid
                                );
                            } else {
                                navigate("/ticket/create");
                            }
                        }}
                    >
                        Not Helping ? Create Ticket
                    </span> */}
                </section>
            )
        } else if (stripeActivationPage) {
            return (
                <section id="automation_stripe_activation">
                    <div className="automation_google_activation_form_area">
                        <h1> Please provide the below Details for Activating your Premium</h1>
                        <div className="automation_google_activation_inner_form">
                            <div className="automation_form_single_field">
                                <label><strong>Email*</strong> <span>(The email id you used to purchase premium)</span></label>
                                <input type="text" placeholder="Enter your Email" value={stripeActivationEmail} onChange={(e) => { setStripeActivationEmail(e.target.value) }} />
                            </div>
                            <div className="automation_form_single_submit">
                                <button onClick={submitStripeActivation}>Submit</button>
                            </div>
                        </div>
                    </div>
                    {/* <span className="automation_footer"
                        onClick={() => {
                            if (paramsAppName && paramsPlatform && email && uid) {
                                navigate(
                                    "/ticket/create?appName=" +
                                    paramsAppName +
                                    "&platform=" +
                                    paramsPlatform +
                                    "&email=" +
                                    email +
                                    "&uid=" +
                                    uid
                                );
                            } else {
                                navigate("/ticket/create");
                            }
                        }}
                    >
                        Not Helping ? Create Ticket
                    </span> */}
                </section>
            )
        } else if (removePartnerPage) {
            return (
                <section id="partner_removal_page">
                    <Grid sx={{ flexGrow: 1 }} container spacing={2} className='custom_grid_item'>
                        <Grid item xs={100}>
                            <Grid container justifyContent="center" spacing={4}>
                                <Grid item>
                                    <Card sx={{ maxWidth: 400 }} className="remove_partner_card">
                                        <CardActionArea>
                                            <CardContent>
                                                <Typography gutterBottom variant="h4" component="div" height={90}>
                                                    Partner Information
                                                </Typography>
                                                {partner !== 'Not present' ? (
                                                    <div className='information_custom_premium_remove'>
                                                        <span className="custom_premium_statement">Are you sure you want to remove your partner?</span>
                                                        <span>Type: Email</span>
                                                        <span>Partner: {partner}</span>
                                                    </div>
                                                ) : forceBuddyRemoveStatus ? (
                                                    <div className='information_custom_premium' >
                                                        <h3>Partner Does Not Exists</h3>
                                                    </div>
                                                ) : (
                                                    <div className='information_custom_premium' >
                                                        <span className="custom_premium_statement">Are you sure you want to remove your partner?</span>
                                                        <h3>Remove Long Sentence/ Time Delay/ Walking Buddy.</h3>
                                                    </div>
                                                )}
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            {partner === 'Not present' && forceBuddyRemoveStatus ? (
                                                <button disabled className='custom_cta_btn'>Remove Partner</button>
                                            ) : (
                                                <button onClick={() => { removeThisPartner() }} id='remove_partner_submit' className='custom_cta_btn'>Remove Partner</button>
                                            )}

                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        showPartnerRemovalPopup ? (
                            <div className="alert_confirmation_popup">
                                <div className="alert_inner_popup">
                                    <h1>Are you sure, you want to remove your partner ?</h1>
                                    <div className="removal_btn_area">
                                        <button className="secondary" onClick={() => {
                                            removePartnerFinal()
                                        }}>
                                            Yes
                                        </button>
                                        <button className="primary" onClick={() => {
                                            setShowPartnerRemovalPopup(false)
                                        }}>
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )
                    }
                </section >
            )
        } else if (restoreStreakPage) {
            return (
                <section id="restore_streak_page">
                    <Grid item>
                        <Card sx={{ maxWidth: 300 }} className="restore_streak_card">
                            <CardActionArea>
                                <CardContent>
                                    <Typography gutterBottom variant="h4" component="div" height={90}>
                                        Streak Information
                                    </Typography>
                                    {lastStreak === null ? (
                                        <div className='information_custom_premium'>
                                            <span>You don't have any Streak for Restoring</span>
                                        </div>
                                    ) : (
                                        <div className='information_custom_premium'>
                                            <span className="restore_clear_instruction">"If you somehow relapsed by mistake. This option will help you to restore you previous Streak without loosing any day."</span>
                                            <h3>Current Streak: </h3>
                                            <span><strong>Start Time: </strong>{new Date(streak.startTime).toUTCString()}</span>
                                            <span><strong>Streak (number of days): </strong>{Math.floor((new Date().getTime() - streak.startTime) / 86400000)}</span>
                                            <div className='streak_icon_in_between'><FontAwesomeIcon icon={faArrowAltCircleDown} /></div>
                                            <h3>Streak After Restore: </h3>
                                            <span><strong>Start Time: </strong>{new Date(lastStreak.startTime).toUTCString()}</span>
                                            <span><strong>Streak (number of days): </strong>{Math.floor((new Date().getTime() - lastStreak.startTime) / 86400000)}</span>
                                        </div>
                                    )}
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <button onClick={() => { restoreStreak() }} id='restore_streak_switch_submit' className='custom_cta_btn'>Restore to Previous Streak</button>
                            </CardActions>
                        </Card>
                    </Grid>
                </section>
            )
        } else if (directAutomation) {
            return (
                <section id="direct_automation_issue">
                    {showAPKUpdatePopup ? (
                        <div className="alert_confirmation_popup">
                            <div className="alert_inner_popup">
                                <p className="inner_popup_text">In some devices, when you update the App, then the operating system disable the accessibility service by itself.</p>
                                <h1>Are you sure, you want to stop receiving App updates ?</h1>
                                <div className="removal_btn_area">
                                    <button className="secondary" onClick={() => {
                                        apkUpdatesFinal()
                                    }}>
                                        Yes
                                    </button>
                                    <button className="primary" onClick={() => {
                                        navigate(`/chatbot?token=${paramsToken}&appName=${paramsAppName}&platform=${paramsPlatform}`)
                                    }}>
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {showForceUninstallPopup ? (
                        <div className="alert_confirmation_popup">
                            <div className="alert_inner_popup">
                                <h1>Are you sure, you want to turn off Prevent Uninstall ?</h1>
                                <div className="removal_btn_area">
                                    <button className="secondary" onClick={() => {
                                        forceUninstallFinal()
                                    }}>
                                        Yes
                                    </button>
                                    <button className="primary" onClick={() => {
                                        navigate(`/chatbot?token=${paramsToken}&appName=${paramsAppName}&platform=${paramsPlatform}`)
                                    }}>
                                        No
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </section>
            )
        } else {
            return (
                <section id="automation_confirmation">
                    <div className="automation_success_area">
                        {successStatusAfterAutomation ? (
                            <h1> Success </h1>
                        ) : (
                            <h1> Check complete <FontAwesomeIcon
                                color="#ffffff"
                                icon={faCircleCheck}
                            /></h1>
                        )}
                        <p> {resultingStatement} </p>
                    </div>
                    {/* <span
                        onClick={() => {
                            if (paramsAppName && paramsPlatform && email && uid) {
                                navigate(
                                    "/ticket/create?appName=" +
                                    paramsAppName +
                                    "&platform=" +
                                    paramsPlatform +
                                    "&email=" +
                                    email +
                                    "&uid=" +
                                    uid
                                );
                            } else {
                                navigate("/ticket/create");
                            }
                        }}
                    >
                        Not Fixed Yet ? Create Ticket
                    </span> */}
                </section>
            );
        }
    }
}

export default AutomationPage;