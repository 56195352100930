import './desktopAuth.css'
import auth, { provider } from '../../Connections/firebase';
import { signInWithPopup } from 'firebase/auth';
import { useParams } from 'react-router-dom';
import axios from 'axios';


function DesktopAuthPage() {
    const { platform } = useParams();
    const signInWithGoogle = () => {
        signInWithPopup(auth, provider)
            .then((response1) => {
                const link = document.createElement('a');
                link.href = `blockerxWindowsApp://uid=${response1.user.uid}`
                document.body.appendChild(link);
                link.click();
            })
            .catch((error1) => {
            });
    };

    const signInWithGoogleForChrome = () => {
        const appId = new URLSearchParams(window.location.search).get('appId')
        signInWithPopup(auth, provider)
            .then((response1) => {
                axios.post('https://accounts.blockerx.net/api/v1/logIn', {
                    provider: true,
                    uid: response1.user.uid,
                    email: response1.user.email
                })
                    .then((response) => {
                        window.open(`chrome-extension://${appId}/Dashboard/dashboard.html?uid=${response1.user.uid}&displayName=${response1.user.displayName}&email=${response1.user.email}&userAuth=${response1.user.accessToken}`, '_self')
                    })
                    .catch((error2) => {
                        alert('Something went wrong')
                    })

                })
            .catch((error1) => {
            });
    }
    if (platform === 'windows') {
        return (
            <div className='main_area'>
                <div className='sub_area'>
                    <div className='img_area'>
                        <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png' alt='logo' />
                    </div>
                    <span>Please Confirm Linking your BlockerX account to a Google Account. You will be redirected to a Google Pop-up to Sign-In</span>
                    <div className='btn_area'>
                        <button onClick={signInWithGoogle}>Confirm</button>
                    </div>
                </div>
            </div>
        );
    } else if (platform === 'macOs') {
        return (
            <div className='main_area'>
                <div className='sub_area'>
                    <div className='img_area'>
                        <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png' alt='logo' />
                    </div>
                    <span>Please Confirm Linking your BlockerX account to a Google Account. You will be redirected to a Google Pop-up to Sign-In</span>
                    <div className='btn_area'>
                        <button onClick={signInWithGoogle}>Confirm</button>
                    </div>
                </div>
            </div>
        );
    } else if (platform === 'chrome') {
        return (
            <div className='main_area'>
                <div className='sub_area'>
                    <div className='img_area'>
                        <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png' alt='logo' />
                    </div>
                    <span>Please Confirm Linking your BlockerX account to a Google Account. You will be redirected to a Google Pop-up to Sign-In</span>
                    <div className='btn_area'>
                        <button onClick={signInWithGoogleForChrome}>Confirm</button>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className='main_area'>
                <div className='sub_area'>
                    <div className='img_area'>
                        <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png' alt='logo' />
                    </div>
                    <span>You seem to be lost</span>
                </div>
            </div>
        );
    }
}

export default DesktopAuthPage;