import './supportChat.css'
import codi from './codi.png'
import load from './loading-paper.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import waiting from './typing_wait.gif'
import axios from 'axios';
import mixpanel from 'mixpanel-browser';

mixpanel.init('d051baf39ffda3bf75a7b21ddff1eef4', { debug: false });

const commonQuestionReply = {
    chat_mode_blocking: {
        question: 'Blocking stops working',
        answer: `It could be because your device's accessibility service got malfunctioned or turned off. If you find that BlockerX stops working due to your phone's operating system killing background processes, follow these steps to optimize its performance and prevent disruptions:
        When your phone's operating system terminates background processes, it can affect the functionality of apps like BlockerX. To reduce the chances of this happening, you can disable battery optimizations specifically for the BlockerX app:
        1. Access Settings: Navigate to your device's settings.
        2. App Settings: Find and select the "Apps" or "Applications" section. Look for "BlockerX" in the list of installed apps.
        3. Battery Optimization: Within the BlockerX app settings, locate the "Battery" or "Battery optimization" section.
        4. Disable Optimization: Toggle off the option for battery optimization specifically for the BlockerX app. This action ensures that the app's background processes are not aggressively managed by the operating system.
        For Oppo/Vivo Users:
        If you're using an Oppo or Vivo device, it's important to note that accessibility settings might reset when you clear recent apps. To avoid this issue, follow the instructions provided in this video guide: Accessibility Fix Guide.
        If issue still persists:
        1. Ensure that Porn Blocker switch is turned on.
        2. Ensure that accessibility service is turned on for BlockerX App from the settings.`
    },
    chat_mode_accessibility: {
        question: 'Accessibility service is turning off after app update',
        answer: `Dear User, Thank you for reaching out to us regarding the APK update issue you've encountered. We apologize for any inconvenience this may have caused.\n\n
        In some devices, when you update the APK, then the operating system disable the accessibility service by itself.\n\n
        To address this matter, we recommend the following steps:\n\n
        1. Open the BlockerX app on your device.
        2. Navigate to the Support section located inside the app's menu, accessible via the hamburger icon.
        3. Within the Support section, you'll find detailed instructions on how to modify the APK update settings to resolve the issue.
        \n\nBy following these steps, you should be able to effectively fix the APK update issue. If you encounter any further difficulties or have additional questions, please don't hesitate to reach out to us. We're here to assist you further.\n\n
        Thank you.`
    },
    chat_mode_apk_link: {
        question: 'Link to download APK is not working',
        answer: `Apologies for the inconvenience with the download link. Please use the updated link to download BlockerX: https://blockerx-android-apk.s3.amazonaws.com/blockerx-android-apk-file.apk.`
    },
    chat_mode_able_to_uninstall: {
        question: 'I am easily able to uninstall the app',
        answer: `Due to Play Store policies, some BlockerX features, including Prevent Uninstall, may be restricted. For full functionality, uninstall the Play Store version and download the APK version from https://blockerx.onelink.me/oeB0/uninstallemail. This will grant access to all features`
    },
    chat_mode_vpn_issue: {
        question: 'VPN is causing issue',
        answer: `To address issues with BlockerX's VPN, please try restarting your device and, if necessary, uninstalling and reinstalling the BlockerX app for a fresh update. If problems continue, contact us for more help.`
    },
    chat_mode_banking_app: {
        question: 'Banking apps not working',
        answer: `Apologies for the inconvenience with banking apps. Due to policy restrictions, some banking apps restrict the use of accessibility services. To use these apps, temporarily turn off the accessibility service and reactivate it afterward.`
    },
    chat_mode_apk_installation: {
        question: 'Unable to install the APK',
        answer: `If you're having trouble installing the APK version of BlockerX, follow these steps:
        1. Uninstall any existing BlockerX versions from your device.
        2. Try downloading the APK version again.`
    },
    chat_mode_blocklist_blocking: {
        question: 'Items in blocklist are not getting blocked',
        answer: `Dear user, Thanks for reaching out to us. We apologize for the inconvenience you've experienced with the listed sites/Keywords/apps not being blocked despite being on your blocked list.\n\n
        To address this issue, we recommend ensuring that the Accessibility feature for BlockerX is turned on in your device settings. Once Accessibility is enabled, BlockerX should be able to block the listed sites and any others on your blocklist effectively.\n\n
        If you encounter any further difficulties or if you have any additional questions, please don't hesitate to reach out to us for further assistance. We're here to help! \n\n
        Thank you.`
    }
}

const questionSet = {
    'FCabekdaW5TGfwHquzMIC': {
        question: 'Which app would you like assistance with?',
        options: [
            {
                value: 'BlockerX',
                redirect: 'kfwvGbSnxIflHegPjbJyJ'
            }, {
                value: 'Zenze',
                redirect: 'create_ticket'
            }, {
                value: 'Healify',
                redirect: 'create_ticket'
            }, {
                value: 'SocialX',
                redirect: 'create_ticket'
            }, {
                value: 'Dtox',
                redirect: 'create_ticket'
            },
        ]
    },
    'kfwvGbSnxIflHegPjbJyJ': {
        question: '',
        options: [
            {
                value: 'Blocking stops working',
                redirect: 'chat_mode_blocking'
            }, {
                value: 'I purchased premium but my premium is not active',
                redirect: 'premium_active_flow'
            }, {
                value: 'View/Cancel payments and subscriptions',
                redirect: 'provide_refund_cancel'
            }, {
                value: 'Accountability partner is not responding',
                redirect: 'create_ticket'
            }, {
                value: 'Updating the app is causing issue. Stop receiving App updates',
                redirect: 'do_not_get_updates'
            }, {
                value: 'Streak issue',
                redirect: '8ejVnAHnAhE8WiYY-ULiV'
            }, {
                value: "Not able to add friend's email due to long sentence",
                redirect: 'create_ticket'
            }, {
                value: 'VPN is causing issue',
                redirect: 'chat_mode_vpn_issue'
            }, {
                value: 'Banking apps not working',
                redirect: 'chat_mode_banking_app'
            }, {
                value: 'Not able to uninstall the App',
                redirect: 'force_uninstall_turn_off'
            }, {
                value: 'I have a suggestion',
                redirect: 'create_ticket'
            }, {
                value: 'I have a query (Ask any question)',
                redirect: 'chat_mode'
            }, {
                value: "My issue is not listed (Create Ticket)",
                redirect: 'create_ticket'
            },
        ]
    },
    'bPXii7mXFwy2dJ8f4psGt': {
        question: 'I have Accountability partner related issue',
        options: [
            {
                value: 'I am not able to add accountability partner',
                redirect: 'add_partner'
            }, {
                value: 'I am not able to remove accountability partner',
                redirect: 'remove_partner'
            }, {
                value: 'Partner is not receiving verification email',
                redirect: 'check_spam'
            }, {
                value: 'Daily report issue',
                redirect: 'create_ticket'
            }, {
                value: 'Partner receiving wrong emails',
                redirect: 'create_ticket'
            }
        ]
    },
    'VURUexQDtiEGKZ58U83v2': {
        question: 'I want changes in my Account',
        options: [
            {
                value: 'Change my email',
                redirect: 'create_ticket'
            }, {
                value: 'Change my username',
                redirect: 'create_ticket'
            }, {
                value: 'Delete my account',
                redirect: 'create_ticket'
            }
        ]
    },
    '9csINV6kGtg6zjYI4WB8i': {
        question: 'In which platform you are facing the Issue',
        options: [
            {
                value: 'Android',
                redirect: 'nhq-T5pvxsbqDmtVATito'
            }, {
                value: 'Ios',
                redirect: 'nhq-T5pvxsbqDmtVATito'
            }, {
                value: 'Chrome extension',
                redirect: 'nhq-T5pvxsbqDmtVATito'
            }, {
                value: 'Windows',
                redirect: 'nhq-T5pvxsbqDmtVATito'
            }, {
                value: 'MacOs',
                redirect: 'nhq-T5pvxsbqDmtVATito'
            }
        ]
    },
    'nhq-T5pvxsbqDmtVATito': {
        question: 'What is the issue you are facing ?',
        options: [
            {
                value: 'Porn blocking not working',
                redirect: 'create_ticket'
            }, {
                value: 'Item added in blocklist, still not blocking',
                redirect: 'create_ticket'
            }, {
                value: 'App is crashing',
                redirect: 'create_ticket'
            }, {
                value: 'App is not opening',
                redirect: 'create_ticket'
            }, {
                value: 'Particular blocking feature not working',
                redirect: 'create_ticket'
            }
        ]
    },
    'YV6iNsRPqieKuV5vKr-gP': {
        question: 'I have a premium issue',
        options: [
            {
                value: 'I have purchased the premium, but it did not activate',
                redirect: 'premium_active_flow'
            }, {
                value: 'I am not able to purchase premium',
                redirect: 'purchase_premium'
            }, {
                value: 'I want to cancel Premium',
                redirect: 'provide_refund_cancel'
            }, {
                value: 'Other issue',
                redirect: 'create_ticket'
            }
        ]
    },
    'premium_active_flow': {
        question: 'From which platform did you purchase Premium ?',
        options: [
            {
                value: 'Google Play',
                redirect: 'premium_active_google'
            }, {
                value: 'Apple/ App Store',
                redirect: 'premium_active_apple'
            }, {
                value: 'Stripe Gateway',
                redirect: 'premium_active_stripe'
            }, {
                value: 'Not sure',
                redirect: 'create_ticket'
            }
        ]
    },
    'eCL-jaiIo8KRe6pe4gemV': {
        question: 'Which platform do you want changes to be in ?',
        options: [
            {
                value: 'Android',
                redirect: '9UAfd5XECgzByBuxFTSNu'
            }, {
                value: 'Ios',
                redirect: '9UAfd5XECgzByBuxFTSNu'
            }, {
                value: 'Chrome extension',
                redirect: '9UAfd5XECgzByBuxFTSNu'
            }, {
                value: 'Windows',
                redirect: '9UAfd5XECgzByBuxFTSNu'
            }, {
                value: 'MacOs',
                redirect: '9UAfd5XECgzByBuxFTSNu'
            }
        ]
    },
    '9UAfd5XECgzByBuxFTSNu': {
        question: 'What changes are you looking for ?',
        options: [
            {
                value: 'Turn off prevent uninstall switch',
                redirect: 'create_ticket'
            }, {
                value: 'Turn off blocking',
                redirect: 'create_ticket'
            }, {
                value: 'Remove items from blockList',
                redirect: 'create_ticket'
            }, {
                value: 'Remove items from whitelist',
                redirect: 'create_ticket'
            }, {
                value: 'Turn off some other switch',
                redirect: 'create_ticket'
            }
        ]
    },
    '8ejVnAHnAhE8WiYY-ULiV': {
        question: 'I have a streak related issue',
        options: [
            {
                value: 'My streak is stuck',
                redirect: 'streak_continue'
            }, {
                value: 'My streak got reset',
                redirect: 'streak_restore'
            }
        ]
    }
}

function SupportChat() {

    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" })
    }

    const navigate = useNavigate();

    const paramsToken = new URLSearchParams(window.location.search).get("token")
    const paramsAppName = new URLSearchParams(window.location.search).get("appName")
    const paramsPlatform = new URLSearchParams(window.location.search).get("platform")

    const [optionMode, setOptionMode] = useState(true)
    const [loadingMode, setLoadingMode] = useState(true)
    const [currentQuestionId, setCurrentQuestionId] = useState('FCabekdaW5TGfwHquzMIC')
    const [userEmail, setUserEmail] = useState(null)
    const [userUid, setUserUid] = useState(null)
    const [chatList, setChatList] = useState([])
    const [userQuery, setUserQuery] = useState('')
    const [waitingForAI, setWaitingForAI] = useState(false)
    const [sendingQuery, setSendingQuery] = useState(false)


    const changeQuestion = async (questionId) => {
        mixpanel.track('Chatbot', {
            'optionClick': questionId
        })
        if (questionId === 'create_ticket') {
            if (paramsAppName && paramsPlatform && userEmail && userUid) {
                navigate("/ticket/create?appName=" + paramsAppName + "&platform=" + paramsPlatform + "&email=" + userEmail + "&uid=" + userUid)
            } else {
                navigate("/ticket/create")
            }
        } else if (questionId === 'get_suggestion') {
            navigate("/suggestion")
        } else if (questionId === 'open_faq') {
            window.open('https://blockerx.net/faqs/')
        } else if (questionId === 'user_dashboard') {
            if (userUid && userUid !== undefined && userEmail && userEmail !== undefined) {
                navigate(`/signin?uid=${userUid}&email=${userEmail}`)
            } else {
                navigate("/signin")
            }
        } else if (questionId === 'streak_continue') {
            if (userUid && userUid !== undefined && userEmail && userEmail !== undefined && paramsAppName && paramsPlatform) {
                navigate(`/chatbot/automation/streak/continue/${userUid}/${userEmail}?appName=${paramsAppName}&platform=${paramsPlatform}`)
            } else {
                navigate("/chatbot/automation/streak/continue/anonymous/anonymous")
            }
        } else if (questionId === 'streak_restore') {
            if (userUid && userUid !== undefined && userEmail && userEmail !== undefined && paramsAppName && paramsPlatform) {
                navigate(`/chatbot/automation/streak/restore/${userUid}/${userEmail}?appName=${paramsAppName}&platform=${paramsPlatform}`)
            } else {
                navigate("/chatbot/automation/streak/restore/anonymous/anonymous")
            }
        } else if (questionId === 'streak_reset') {
            if (userUid && userUid !== undefined && userEmail && userEmail !== undefined && paramsAppName && paramsPlatform) {
                navigate(`/chatbot/automation/streak/reset/${userUid}/${userEmail}?appName=${paramsAppName}&platform=${paramsPlatform}`)
            } else {
                navigate("/chatbot/automation/streak/reset/anonymous/anonymous")
            }
        } else if (questionId === 'chat_mode') {
            setOptionMode(false)
        } else if (questionId === 'chat_mode_blocking' || questionId === 'chat_mode_apk_link' || questionId === 'chat_mode_able_to_uninstall' || questionId === 'chat_mode_accessibility' || questionId === 'chat_mode_banking_app' || questionId === 'chat_mode_apk_installation' || questionId === 'chat_mode_blocklist_blocking' || questionId === 'chat_mode_vpn_issue') {
            setLoadingMode(true)
            setUserQuery('')
            setSendingQuery(true)
            const newChatItem = {
                type: 'user',
                content: commonQuestionReply[questionId].question
            }
            setChatList(previousChat => [
                ...previousChat,
                newChatItem
            ])
            setWaitingForAI(true)
            scrollToBottom()
            setWaitingForAI(false)
            const newChatAnswer = {
                type: 'bot',
                content: parseTextForLink(commonQuestionReply[questionId].answer + `\n\nFeel free to contact us with any further questions or concerns. Thank you.`)
            }
            setChatList(previousChat => [
                ...previousChat,
                newChatAnswer
            ])
            scrollToBottom()
            setSendingQuery(false)
            setOptionMode(false)
            setLoadingMode(false)
        } else if (questionId === 'articles_list') {
            if (paramsAppName && paramsPlatform && userEmail && userUid) {
                navigate("/support/articles?appName=" + paramsAppName + "&platform=" + paramsPlatform + "&email=" + userEmail + "&uid=" + userUid)
            } else {
                navigate("/support/articles")
            }
        } else if (questionId === 'add_partner') {
            if (paramsAppName && paramsPlatform && userEmail && userUid) {
                navigate("/support/articles/add_partner?appName=" + paramsAppName + "&platform=" + paramsPlatform + "&email=" + userEmail + "&uid=" + userUid)
            } else {
                navigate("/support/articles/add_partner")
            }
        } else if (questionId === 'remove_partner') {
            // if (paramsAppName && paramsPlatform && userEmail && userUid) {
            //     navigate("/support/articles/remove_partner?appName=" + paramsAppName + "&platform=" + paramsPlatform + "&email=" + userEmail + "&uid=" + userUid)
            // } else {
            //     navigate("/support/articles/remove_partner")
            // }
            if (userUid && userUid !== undefined && userEmail && userEmail !== undefined && paramsAppName && paramsPlatform) {
                navigate(`/chatbot/automation/partner/remove/${userUid}/${userEmail}?appName=${paramsAppName}&platform=${paramsPlatform}`)
            } else {
                navigate("/chatbot/automation/partner/remove/anonymous/anonymous")
            }
        } else if (questionId === 'check_spam') {
            if (paramsAppName && paramsPlatform && userEmail && userUid) {
                navigate("/support/articles/check_spam?appName=" + paramsAppName + "&platform=" + paramsPlatform + "&email=" + userEmail + "&uid=" + userUid)
            } else {
                navigate("/support/articles/check_spam")
            }
        } else if (questionId === 'provide_refund_cancel') {
            if (userUid && userUid !== undefined && userEmail && userEmail !== undefined && paramsAppName && paramsPlatform) {
                navigate(`/chatbot/automation/premium/refund/${userUid}/${userEmail}?appName=${paramsAppName}&platform=${paramsPlatform}`)
            } else {
                navigate("/chatbot/automation/premium/refund/anonymous/anonymous")
            }
        } else if (questionId === 'purchase_premium') {
            navigate("/")
        } else if (questionId === 'premium_active_google') {
            if (userUid && userUid !== undefined && userEmail && userEmail !== undefined && paramsAppName && paramsPlatform) {
                navigate(`/chatbot/automation/premium/activate_google/${userUid}/${userEmail}?appName=${paramsAppName}&platform=${paramsPlatform}`)
            } else {
                navigate("/chatbot/automation/premium/activate_google/anonymous/anonymous")
            }
        } else if (questionId === 'premium_active_stripe') {
            if (userUid && userUid !== undefined && userEmail && userEmail !== undefined && paramsAppName && paramsPlatform) {
                navigate(`/chatbot/automation/premium/activate_stripe/${userUid}/${userEmail}?appName=${paramsAppName}&platform=${paramsPlatform}`)
            } else {
                navigate("/chatbot/automation/premium/activate_stripe/anonymous/anonymous")
            }
        } else if (questionId === 'premium_active_apple') {
            window.open('https://blockerx.net/blog/how-to-activate-restore-your-blockerx-premium-purchase-on-ios/', '_blank')
        } else if (questionId === 'do_not_get_updates') {
            if (userUid && userUid !== undefined && userEmail && userEmail !== undefined && paramsAppName && paramsPlatform) {
                navigate(`/chatbot/automation/apk/updates/${userUid}/${userEmail}?appName=${paramsAppName}&platform=${paramsPlatform}&token=${paramsToken}`)
            } else {
                navigate("/chatbot/automation/apk/updates/anonymous/anonymous")
            }
        } else if (questionId === 'force_uninstall_turn_off') {
            if (userUid && userUid !== undefined && userEmail && userEmail !== undefined && paramsAppName && paramsPlatform) {
                navigate(`/chatbot/automation/switch/forceUninstall/${userUid}/${userEmail}?appName=${paramsAppName}&platform=${paramsPlatform}&token=${paramsToken}`)
            } else {
                navigate("/chatbot/automation/switch/forceUninstall/anonymous/anonymous")
            }
        } else {
            setCurrentQuestionId(questionId)
        }
    }

    function isValidToken(paramToken) {
        return new Promise((resolve, reject) => {
            axios.post('https://accounts.blockerx.net/api/verify/userToken', {
                token: paramToken
            })
                .then((response) => {
                    if (response.data.status === 'success' && response.data.data.data) {
                        setUserEmail(response.data.data.email)
                        setUserUid(response.data.data.message)
                        resolve({
                            status: true
                        })
                    } else {
                        resolve({
                            status: false
                        })
                    }
                })
                .catch((error2) => {
                    resolve({
                        status: false
                    })
                })
        })
    }

    async function tokenValidation(token) {
        const tokenStatus = await isValidToken(token)
        if (token !== undefined && token !== null && typeof token === 'string' && token.length > 0 && tokenStatus.status) {
            setLoadingMode(false)
        } else {
            setLoadingMode(false)
        }
    }

    function askQueryToAI(query) {
        return new Promise((resolve, reject) => {
            axios.post('https://dev.blockerx.net/api/user/dashboard/openaiquery', {
                query
            })
                .then((response) => {
                    console.log('Response from bot', response.data)
                    if ('data' in response.data && response.data.data.length) {
                        resolve({
                            status: true,
                            reply: parseTextForLink(response.data.data + `\n\nFeel free to contact us with any further questions or concerns. Thank you.`)
                        })
                    } else {
                        resolve({
                            status: false
                        })
                    }
                })
                .catch((error2) => {
                    resolve({
                        status: false
                    })
                })
        })
    }

    const parseTextForLink = (text) => {
        const lines = text.split('\n')
        let parsedText = []
        lines.map((line, num) => {
            const words = line.split(' ')
            words.map((word, index) => {
                if (word.startsWith('http://') || word.startsWith('https://')) {
                    parsedText.push(
                        <a
                            key={index}
                            href={word}
                            style={{ textDecoration: 'underline', fontWeight: "bold", color: "blue" }}
                            target="_blank"
                            rel="noopener noreferrer"
                        > {word} </a>
                    )
                } else {
                    parsedText.push(word + ' ')
                }
            })
            parsedText.push('\n')
        })
        return parsedText
    }

    const createNewQuery = async () => {
        if (userQuery && userQuery !== undefined && userQuery.length) {
            setUserQuery('')
            setSendingQuery(true)
            const newChatItem = {
                type: 'user',
                content: userQuery
            }
            setChatList(previousChat => [
                ...previousChat,
                newChatItem
            ])
            setWaitingForAI(true)
            scrollToBottom()
            const RESULT = await askQueryToAI(userQuery)
            if (RESULT && RESULT.status) {
                setWaitingForAI(false)
                const newChatAnswer = {
                    type: 'bot',
                    content: RESULT.reply
                }
                setChatList(previousChat => [
                    ...previousChat,
                    newChatAnswer
                ])
                scrollToBottom()
                setSendingQuery(false)
            } else {
                setWaitingForAI(false)
                const newChatAnswer = {
                    type: 'bot',
                    content: 'Sorry I could not understand your Question.'
                }
                setChatList(previousChat => [
                    ...previousChat,
                    newChatAnswer
                ])
                scrollToBottom()
                setSendingQuery(false)
            }
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (paramsAppName === 'zenze') {
                navigate('/ticket/create')
            } else if (paramsToken) {
                tokenValidation(paramsToken)
            } else {
                setLoadingMode(false)
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    if (loadingMode) {
        return (
            <section id='chatbot_loading'>
                <img src={load} alt='loading' />
            </section>
        )
    } else {
        return (
            <section id="chatbot_page">
                <div>
                    <div className='chatbot_header_container'>
                        <div onClick={() => { setCurrentQuestionId('FCabekdaW5TGfwHquzMIC') }} className='chatbot_icon_area'>
                            <img src={codi} alt='icon' />
                        </div>
                        <div className='chatbot_content_area'>
                            <h1>BlockerX Support</h1>
                        </div>
                    </div>
                    {/* <img className='chatbot_wave' src={wave} alt='wave' /> */}
                    {optionMode ? (
                        <div id={currentQuestionId} className='chatbot_current_flow_question'>
                            <h4>{questionSet[currentQuestionId].question}</h4>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
                <div className='chatbot_actual_chat_container'>
                    {optionMode ? (
                        <>
                            {questionSet[currentQuestionId].options.map((singleOption) => {
                                return (
                                    <div className='single_chat_item option_chat'>
                                        <p onClick={() => { changeQuestion(singleOption.redirect) }}>
                                            {singleOption.value}
                                        </p>
                                    </div>
                                )
                            })}
                        </>
                    ) : (
                        <>
                            {chatList.map((single_chat) => {
                                return (
                                    <div className={single_chat.type === 'user' ? 'single_chat_item user_chat' : 'single_chat_item bot_chat'}>
                                        <p>
                                            {single_chat.content}
                                        </p>
                                    </div>
                                )
                            })}
                            {waitingForAI ? (
                                <div className='single_chat_item waiting_gif'>
                                    <img src={waiting} alt='waiting' />
                                </div>
                            ) : (
                                <></>
                            )}
                            <div ref={messagesEndRef} className='single_chat_item chat_endpoint' style={{ minHeight: '1vh' }}></div>
                        </>
                    )}
                </div>
                {optionMode && currentQuestionId === 'FCabekdaW5TGfwHquzMIC' ? (
                    <div className='chatbot_footer_container option_container'>
                        {/* <div className='chat_foot_inp_area'>
                            <input type='text' placeholder='Enter your Query' />
                        </div> */}
                        <div className='chat_foot_btn_area'>
                            <div className='footer_ticket_submit'>
                                <button onClick={() => {
                                    if (userEmail && userUid) {
                                        navigate("/ticket/view?email=" + userEmail + "&uid=" + userUid)
                                    } else {
                                        navigate("/ticket/view")
                                    }
                                }}>View Ticket Status</button>
                            </div>
                            {/* <div className='footer_ticket_submit'>
                                    <button onClick={() => { 
                                        if (paramsAppName && paramsPlatform && userEmail && userUid) {
                                            navigate("/ticket/create?appName=" + paramsAppName + "&platform=" + paramsPlatform + "&email=" + userEmail + "&uid=" + userUid)
                                        } else {
                                            navigate("/ticket/create")
                                        }
                                    }}>Create Ticket <FontAwesomeIcon icon={faPlus} /></button>
                                </div> */}
                            <div className='footer_ticket_submit'>
                                <button onClick={() => {
                                    if (paramsAppName && paramsPlatform && userEmail && userUid) {
                                        navigate("/support/articles?appName=" + paramsAppName + "&platform=" + paramsPlatform + "&email=" + userEmail + "&uid=" + userUid)
                                    } else {
                                        navigate("/support/articles")
                                    }
                                }}>Open FAQ</button>
                            </div>
                        </div>

                        {/* <div className='chat_foot_email_redirect'>
                            <a href='mailto:support@blockerx.org'>Contact Support via Email</a>
                        </div> */}
                        {/* <div className='chat_foot_email_redirect'>
                            <a href='https://blockerx.freshdesk.com/support/solutions/folders/81000163140'>Open FAQ</a>
                        </div> */}
                    </div>
                ) : optionMode ? (
                    <div></div>
                ) : (
                    <div className='chatbot_footer_container'>
                        <div className='chat_foot_inp_area'>
                            <input value={userQuery} onChange={(e) => { setUserQuery(e.target.value) }} type='text' placeholder='Enter your Query' />
                            {sendingQuery ? (
                                <button disabled><FontAwesomeIcon icon={faPaperPlane} /></button>
                            ) : (
                                <button onClick={createNewQuery}><FontAwesomeIcon icon={faPaperPlane} /></button>
                            )}
                        </div>
                        <div className='chat_foot_btn_area'>
                            {chatList.length >= 6 ? (
                                <div className='footer_btns_cta'>
                                    <span onClick={() => {
                                        if (paramsAppName && paramsPlatform && userEmail && userUid) {
                                            navigate("/ticket/create?appName=" + paramsAppName + "&platform=" + paramsPlatform + "&email=" + userEmail + "&uid=" + userUid)
                                        } else {
                                            navigate("/ticket/create")
                                        }
                                    }}>Still facing an issue? Create Ticket</span>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                )}
            </section>
        )
    }
}

export default SupportChat;