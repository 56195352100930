// MODULE IMPORTS
import React from 'react';
import ReactDOM from 'react-dom/client';
import mixpanel from 'mixpanel-browser';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'

// LOCAL DEPENDENCIES IMPORT
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// COMPONENTS IMPORT
import PremiumPage from './Components/PremiumPage/premiumPage';
import SignupPage from './Components/SignupPage/signupPage';
import ForgotPassword from './Components/ForgotPassword/forgotPassword';
import SupportPage from './Components/SupportPage/support';
import ChatbotPage from './Components/Chatbot/Chatbot';
import DesktopAuthPage from './Components/DesktopAuth/desktopAuth';
import ChromeUninstall from './Components/ChromeUninstall/chromeUninstall';
import ChromeSubmitted from './Components/ChromeUninstall/chromeUninstallSubmitted';
import ProfilePage from './Components/ProfilePage/profilePage';
import Ratings from './Components/Ratings/Ratings';
import DeleteUserAccount from './Components/DeleteUserAccount/deleteUserAccount';
import DailyTaskPage from './Components/HealifyDailyTask/dailyTask';
import SupportChat from './Components/SupportChat/supportChat';
import CustomizePage from './Components/Customize/customize';
import CreateTicketPage from './Components/Ticket/createTicket';
import GetSuggestionPage from './Components/Suggestion/suggestionPage';
import ViewTicketPage from './Components/Ticket/viewTicket';
import FeedbackTicketPage from './Components/Ticket/feedbackTicket';
import DeleteZenzeAccount from './Components/DeleteZenzeAccount/deleteZenzeAccount';
import AutomationPage from './Components/Automation/automation';
import ArticlesPage from './Components/Articles/articles';
import AutomationAuthPage from './Components/Automation/automationAuth';
import QuizPage from './Components/Quiz/quizPage';
import DeleteDtoXAccount from './Components/DeleteDtoXAccount/deleteDtoXAccount';
import DeleteHealifyAccount from './Components/DeleteHealifyAccount/deleteHealifyAccount';
import DeleteSocialXAccount from './Components/DeleteSocialXAccount/deleteSocialXAccount';
import Articles from './Components/ArticlePage/Article';
import StripeSuccessPage from './Components/StripePaymentSuccess/stripePaymentSuccess';
import StripeSuccessPageWindows from './Components/StripePaymentSuccess/stripePaymentSuccessWindows';
import StripeBookSuccessPage from './Components/StripePaymentSuccess/stripeBookPurchase';
import ZenzeSupportPage from './Components/SupportChat/zenzeSupportChat';
import DownloadAPK from './Components/DownloadAPK/downloadAPK';
import BlockedPage from './Components/BlockWindow/blockWindow';
import CourseFlow from './Components/BootCamp/bootcamp';
import JournalComplete from './Components/BootCamp/completedJournal';
import CommunityGuide from './Components/Community/communityGuide';

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)

mixpanel.init('d051baf39ffda3bf75a7b21ddff1eef4', { debug: false });

let userId = localStorage.getItem('userId')
if (userId && userId !== undefined) {
  console.log('The User Is ', userId)
  mixpanel.identify(userId)
} else {
  userId = uuidv4()
  mixpanel.identify(userId)
  localStorage.setItem('userId', userId)
}

const root = ReactDOM.createRoot(document.getElementById('root'));
mixpanel.track('AppSetup', {
  'open': 'AppOpened'
})
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/home" element={<App />} />
        <Route path="/premium" element={<PremiumPage />} />
        <Route path='/signup' element={<SignupPage />} />
        <Route path='/forgotPassword' element={<ForgotPassword />} />
        <Route path='/signin' element={<App />} />
        <Route path='/blockerx-support' element={<SupportPage />} />
        <Route path='/chatbot' element={<SupportChat />} />
        <Route path='/zenzeSupport' element={<ZenzeSupportPage />} />
        <Route path='/blockerx-support/en' element={<SupportPage />} />
        <Route path='/blockerx-chat' element={<ChatbotPage />} />
        <Route path='/ticket/create' element={<CreateTicketPage />} />
        <Route path='/ticket/view' element={<ViewTicketPage />} />
        <Route path='/ticket/view/:id' element={<ViewTicketPage />} />
        <Route path='/ticket/feedback/:id' element={<FeedbackTicketPage />} />
        <Route path='/suggestion' element={<GetSuggestionPage />} />
        <Route path='/desktop-auth/' element={<DesktopAuthPage />} />
        <Route path='/desktop-auth/:platform' element={<DesktopAuthPage />} />
        <Route path='/chrome-uninstall' element={<ChromeUninstall />} />
        <Route path='/chrome-uninstall/thank-you' element={<ChromeSubmitted />} />
        <Route path='/user/profile/:uid' element={<ProfilePage />} />
        <Route path='/chatbot/automation/:category/:subcategory/:uid/:email' element={<AutomationPage />} />
        <Route path='/chatbot/automation/auth/:category/:subcategory' element={<AutomationAuthPage />} />
        <Route path='/support/articles' element={<ArticlesPage />} />
        <Route path='/support/articles/:criteria' element={<ArticlesPage />} />
        <Route path='/ratings/vpn' element={<Ratings />} />
        <Route path='/quiz' element={<QuizPage />} />
        <Route path='/download-apk' element={<DownloadAPK />} />
        <Route path='/macos/blocked' element={<BlockedPage />} />
        <Route path='/user/deleteAccount' element={<DeleteUserAccount />} />
        <Route path='/zenze/deleteAccount' element={<DeleteZenzeAccount />} />
        <Route path='/dtox/deleteAccount' element={<DeleteDtoXAccount />} />
        <Route path='/healify/deleteAccount' element={<DeleteHealifyAccount />} />
        <Route path='/socialx/deleteAccount' element={<DeleteSocialXAccount />} />
        <Route path='/healify/TaskOfTheDay/:day' element={<DailyTaskPage />} />
        <Route path="/blog-page/:article" element={<Articles />} />
        <Route path="/chrome/paymentSuccess" element={<StripeSuccessPage />} />
        <Route path="/chrome/bookPurchase/:bookName" element={<StripeBookSuccessPage />} />
        <Route path="/windows/paymentSuccess" element={<StripeSuccessPageWindows />} />
        <Route path="/bootcamp/progress" element={<CourseFlow/>} />
        <Route path="/bootcamp/complete" element={<JournalComplete/>} />
        <Route path="/community/guide/:page" element={<CommunityGuide/>} />
        <Route path='*' element={<App />} /> 
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
