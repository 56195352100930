import React, { useEffect, useState } from 'react';
import './bootcamp.css';
import load from '../SupportChat/loading-paper.gif'
import brandImg from './nnn_brand.png'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const JournalComplete = () => {

    const paramsToken = new URLSearchParams(window.location.search).get("userToken")

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true)

    function isValidToken(paramToken) {
        return new Promise((resolve, reject) => {
            axios.post('https://accounts.blockerx.net/api/verify/userToken', {
                token: paramToken
            })
                .then((response) => {
                    console.log(' 2: ', response.data)
                    if (response.data.status === 'success' && response.data.data.data) {
                        resolve({
                            status: true,
                            userId: response.data.data.message
                        })
                    } else {
                        resolve({
                            status: false
                        })
                    }
                })
                .catch((error2) => {
                    console.log(' Error 2: ', error2)
                    resolve({
                        status: false
                    })
                })
        })
    }

    function getCourseProgress(thisUser) {
        return new Promise((resolve, reject) => {
            axios.get(`https://dev.blockerx.net/api/v1/course-ops/user-progress?uid=${thisUser}&course=3_week_bootcamp`)
                .then((response3) => {
                    if (response3.data.status === 200 && 'data' in response3.data && response3.data.data && 'progress' in response3.data.data && response3.data.data.progress && 'completedCount' in response3.data.data.progress && response3.data.data.progress.completedCount) {
                        resolve({
                            status: true,
                            completed: response3.data.data.progress.completedCount
                        })
                    } else {
                        resolve({
                            status: true,
                            completed: 0
                        })
                    }
                })
                .catch((error3) => {
                    console.log(' Error 3: ', error3)
                    resolve({
                        status: false
                    })
                })
        })
    }

    function updateCourseProgress(uid, sequence) {
        return new Promise((resolve, reject) => {
            axios.post('https://dev.blockerx.net/api/v1/course-ops/user-progress', {
                uid,
                course: "3_week_bootcamp",
                completedCount: sequence
            })
                .then((response) => {
                    console.log(' Updated: ', response.data)
                    if (response.data.status === 200) {
                        resolve({
                            status: true
                        })
                    } else {
                        resolve({
                            status: false
                        })
                    }
                })
                .catch((error) => {
                    console.log(' Error 2: ', error)
                    resolve({
                        status: false
                    })
                })
        })
    }

    async function verifyUserToken() {
        if (paramsToken && paramsToken !== undefined && paramsToken.length) {
            if (paramsToken === 'testBootCampCode29') {
                const COURSE_STATUS = await getCourseProgress('htT9yDhI2iNXF1gq7YMSttBkYdJ2')
                if (COURSE_STATUS.status && COURSE_STATUS.completed !== null) {
                    await updateCourseProgress('htT9yDhI2iNXF1gq7YMSttBkYdJ2', COURSE_STATUS.completed + 1)
                    navigate(`/bootcamp/progress?userToken=${paramsToken}`)
                } else {
                    navigate(`/bootcamp/progress?userToken=${paramsToken}`)
                }
            } else {
                const RESULT = await isValidToken(paramsToken)
                if (RESULT && RESULT.status && RESULT.userId) {
                    const COURSE_STATUS = await getCourseProgress(RESULT.userId)
                    if (COURSE_STATUS.status && COURSE_STATUS.completed !== null) {
                        await updateCourseProgress(RESULT.userId, COURSE_STATUS.completed + 1)
                        navigate(`/bootcamp/progress?userToken=${paramsToken}`)
                    } else {
                        navigate(`/bootcamp/progress?userToken=${paramsToken}`)
                    }
                } else {
                    navigate(`/bootcamp/progress?userToken=${paramsToken}`)
                }
            }
        } else {
            navigate('/bootcamp/progress')
        }
    }

    useEffect(() => {
        verifyUserToken()
    }, [])

    if (isLoading) {
        return (
            <section className='course_window'>
                <div className='bootcamp_main_header'>
                    <button className='bootcamp_back'>
                        <img src={brandImg} alt='NNN' />
                    </button>
                    <h2>WIN NO NUT NOVEMBER</h2>
                </div>
                <div className='loading_container'>
                    <img src={load} alt='loading' />
                </div>
            </section>
        )
    } else {
        return (
            <></>
        )
    }
}

export default JournalComplete;