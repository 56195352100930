import { useState } from 'react'
import './Ratings.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Ratings = () => {

    const [rating, setRating] = useState(0)
    const [hover, setHover] = useState(0)

    const handleRating = (index) => {
        setRating(index)
        if (index > 3) {
            window.open('https://apps.apple.com/app/id1522854086?action=write-review', '_blank')
        }
    } 

    return (
        <div className="ratings-page">
            <div className="card-list">
                <article className="card">
                    <div className="card-header">
                        <h1> <span className='org-name'> BlockerX </span> has saved you from porn 13 times.</h1>
                    </div>
                    <div className="card-subheader">
                        <span> Tell us how we are doing </span>
                    </div>
                    <div className="card-body">
                        {[...Array(5)].map((star, index) => {
                            index += 1;
                            return (
                                <button type="button"
                                    key={index}
                                    className={index <= (hover || rating) ? "icon-button on" : "icon-button off"}
                                    onClick={() => handleRating(index)}
                                    onMouseEnter={() => setHover(index)}
                                    onMouseLeave={() => setHover(rating)}
                                >
                                    <FontAwesomeIcon icon={faStar} />

                                </button>
                            );
                        })}
                    </div>
                    <div className="card-footer">
                        3998 users already left their reviews.
                    </div>
                </article>
            </div>
        </div>
    )
}

export default Ratings