import * as React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import './customize.css';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark, faArrowAltCircleDown, faArrowUpRightFromSquare, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import auth, { provider } from '../../Connections/firebase';
import { signInWithPopup } from 'firebase/auth';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from "react-router-dom";
import load from '../SupportChat/loading-paper.gif'

mixpanel.init('d051baf39ffda3bf75a7b21ddff1eef4', { debug: false });

function CustomizePage() {

    const navigate = useNavigate();
    mixpanel.track('CustomizePage', {
        'open': 'customize_page'
    })

    const paramsUid = new URLSearchParams(window.location.search).get("uid")
    const paramsEmail = new URLSearchParams(window.location.search).get("email")

    const [loggedIn, setLoggedIn] = useState(false)
    const [userUid, setUserUid] = useState(null)
    const [userEmail, setUserEmail] = useState(null)
    const [partner, setPartner] = useState('Not Present')
    const [premium, setPremium] = useState(null)
    const [premiumType, setPremiumType] = useState('charge')
    const [refundEligible, setPremiumEligible] = useState(false)
    const [showTerms, setShowTerms] = useState(false)
    const [streak, setStreak] = useState(null)
    const [lastStreak, setLastSteak] = useState(null)
    const [loading, setLoading] = useState(true)

    const [new_user_email, set_new_user_email] = useState("");
    const [new_user_password1, set_new_user_password1] = useState("");

    function loginUserUsingCredentials(email, password) {
        return new Promise((resolve, reject) => {
            axios.post('https://accounts.blockerx.net/api/login/refund', {
                provider: false,
                email,
                password
            })
                .then((response) => {
                    try {
                        if (response.data.status === 200) {
                            resolve(response.data.data.user)
                        } else {
                            resolve(null)
                        }
                    } catch {
                        resolve(null)
                    }
                })
                .catch((error2) => {
                    resolve(null)
                });
        })
    }

    function loginUserUsingGoogle(email, uid) {
        return new Promise((resolve, reject) => {
            axios.post('https://accounts.blockerx.net/api/login/refund', {
                provider: true,
                email,
                uid
            })
                .then((response) => {
                    try {
                        if (response.data.status === 200) {
                            resolve(response.data.data.user)
                        } else {
                            resolve(null)
                        }
                    } catch {
                        resolve(null)
                    }
                })
                .catch((error2) => {
                    resolve(null)
                });
        })
    }

    const logInNow = async () => {
        const tempEmail = new_user_email.trim()
        const tempPassword = new_user_password1.trim()

        if (tempEmail && tempEmail.length > 0) {
            if (tempPassword && tempPassword.length > 0) {
                const RESULT = await loginUserUsingCredentials(tempEmail, tempPassword)
                set_new_user_email('')
                set_new_user_password1('')
                if (RESULT !== null) {
                    console.log({ RESULT })
                    setUserUid(RESULT.uid)
                    setUserEmail(new_user_email)
                    setPartner(RESULT.partner)
                    mixpanel.track('CustomizePage', {
                        'activity': 'login_with_credentials',
                        'email': tempEmail
                    })
                    if (RESULT.streaks !== null && RESULT.streaks !== undefined && RESULT.streaks.length > 0) {
                        setStreak(RESULT.streaks[0])
                    }
                    if (RESULT.streaks !== null && RESULT.streaks !== undefined && RESULT.streaks.length > 1) {
                        setLastSteak(RESULT.streaks[1])
                    }
                    if (RESULT.premium !== null && RESULT.premium !== undefined && RESULT.premium.length > 0) {
                        if (RESULT.premium[0].paymentMethod === 'stripeUAE' || RESULT.premium[0].paymentMethod === 'stripeUSA' || RESULT.premium[0].paymentMethod === 'stripe' || RESULT.premium[0].paymentMethod === 'stripeTest') {
                            RESULT.premium[0].paymentMethod = 'Stripe'
                        }
                        if (RESULT.premium[0].paymentMethod === 'ios') {
                            RESULT.premium[0].paymentMethod = 'iOS'
                        }
                        setPremium(RESULT.premium[0])
                        try {
                            if (RESULT.premium[0].commonDisplayPlan === 'annual' || RESULT.premium[0].commonDisplayPlan === 'monthly') {
                                setPremiumType('subscription')
                                if (RESULT.premium[0].paymentMethod === 'Stripe') {
                                    const tempTime = new Date().getTime()
                                    if (tempTime - RESULT.premium[0].purchasedAt < (3 * 86400000)) {
                                        setPremiumEligible(false)
                                    } else {
                                        setPremiumEligible(false)
                                    }
                                } else {
                                    setPremiumEligible(false)
                                }
                            } else if (RESULT.premium[0].commonDisplayPlan === 'lifetime') {
                                setPremiumType('charge')
                                if (RESULT.premium[0].paymentMethod === 'Stripe') {
                                    setPremiumEligible(false)
                                } else {
                                    const tempTime = new Date().getTime()
                                    if (tempTime - RESULT.premium[0].purchasedAt < (3 * 86400000)) {
                                        setPremiumEligible(true)
                                    } else {
                                        setPremiumEligible(false)
                                    }
                                }
                            } else {
                                setPremiumType('bi-annual')
                            }
                        } catch (e) {
                            console.log('Somehow here', e)
                        }
                    }
                    setLoggedIn(true)
                } else {
                    alert('Credentials added are invalid')
                }
            } else {
                alert('Please enter a valid Password')
            }
        } else {
            alert('Please enter a valid Email')
        }
    }

    const loginWithGoogle = () => {
        signInWithPopup(auth, provider)
            .then(async (result) => {
                console.log({ result })
                const RESULT = await loginUserUsingGoogle(result.user.email, result.user.uid)
                if (RESULT !== null) {
                    console.log({ RESULT })
                    setUserUid(RESULT.uid)
                    setUserEmail(result.user.email)
                    setPartner(RESULT.partner)
                    mixpanel.track('CustomizePage', {
                        'activity': 'login_with_google',
                        'email': result.user.email
                    })
                    if (RESULT.streaks !== null && RESULT.streaks !== undefined && RESULT.streaks.length > 0) {
                        setStreak(RESULT.streaks[0])
                    }
                    if (RESULT.streaks !== null && RESULT.streaks !== undefined && RESULT.streaks.length > 1) {
                        setLastSteak(RESULT.streaks[1])
                    }
                    if (RESULT.premium !== null && RESULT.premium !== undefined && RESULT.premium.length > 0) {
                        if (RESULT.premium[0].paymentMethod === 'stripeUAE' || RESULT.premium[0].paymentMethod === 'stripeUSA' || RESULT.premium[0].paymentMethod === 'stripe' || RESULT.premium[0].paymentMethod === 'stripeTest') {
                            RESULT.premium[0].paymentMethod = 'Stripe'
                        }
                        setPremium(RESULT.premium[0])
                        try {
                            if (RESULT.premium[0].commonDisplayPlan === 'annual' || RESULT.premium[0].commonDisplayPlan === 'monthly') {
                                setPremiumType('subscription')
                                if (RESULT.premium[0].paymentMethod === 'Stripe') {
                                    const tempTime = new Date().getTime()
                                    if (tempTime - RESULT.premium[0].purchasedAt < (3 * 86400000)) {
                                        setPremiumEligible(true)
                                    } else {
                                        setPremiumEligible(false)
                                    }
                                } else {
                                    setPremiumEligible(false)
                                }
                            } else if (RESULT.premium[0].commonDisplayPlan === 'lifetime') {
                                setPremiumType('charge')
                                const tempTime = new Date().getTime()
                                if (tempTime - RESULT.premium[0].purchasedAt < (3 * 86400000)) {
                                    setPremiumEligible(true)
                                } else {
                                    setPremiumEligible(false)
                                }
                            } else {
                                setPremiumType('bi-annual')
                            }
                        } catch (e) {
                            console.log('Somehow here', e)
                        }
                    }
                    setLoggedIn(true)
                } else {
                    alert('Credentials added are invalid')
                }
            })
            .catch((err) => {
                alert('Something went wrong')
            })
    }

    const processCancelation = (request) => {
        if (window.confirm('This action cannot be reversed. Make sure you really want to do this')) {
            axios.post('https://accounts.blockerx.net/api/cancellationAndRefund', {
                uid: userUid,
                request
            })
                .then((response) => {
                    if (request === 'refund') {
                        mixpanel.track('CustomizePage', {
                            'activity': 'process_refund',
                            'uid': userUid
                        })
                        alert('Successfully Initiated the Refund')
                        document.getElementById('premium_refund_submit').disabled = true
                    } else {
                        mixpanel.track('CustomizePage', {
                            'activity': 'process_cancelation',
                            'uid': userUid
                        })
                        alert('Successfully Initiated the Cancellation')
                        document.getElementById('premium_cancel_submit').disabled = true
                    }
                })
                .catch((error2) => {
                    alert('Your Request cannot be initiated. Try again later')
                });
        } else {
        }
    }

    const removeThisPartner = () => {
        if (window.confirm('This action cannot be reversed. Make sure you really want to do this')) {
            axios.post('https://accounts.blockerx.net/api/v1/partner', {
                uid: userUid,
                type: 'remove'
            })
                .then((response) => {
                    mixpanel.track('CustomizePage', {
                        'activity': 'remove_partner',
                        'uid': userUid
                    })
                    alert('Successfully Removed your Current Partner. Open BlockerX App to view the Change')
                    document.getElementById('remove_partner_submit').disabled = true
                })
                .catch((error2) => {
                    alert('Your Request cannot be initiated. Try again later')
                    console.log(error2)
                });
        } else {
        }
    }

    const processPreventUninstall = () => {
        if (window.confirm('This action cannot be reversed. Make sure you really want to do this')) {
            axios.post('https://accounts.blockerx.net/api/v1/forceUninstall', {
                uid: userUid,
                status: true
            })
                .then((response) => {
                    alert('Successfully Turned Off the Prevent Uninstall Switch. Reopen your app the see results')
                    document.getElementById('prevent_uninstall_switch_off').disabled = true
                })
                .catch((error2) => {
                    console.log(error2)
                });
        } else {
        }
    }

    const restoreStreak = () => {
        if (window.confirm('This action cannot be reversed. Make sure you really want to do this')) {
            axios.post('https://accounts.blockerx.net/api/dashboard/user/streakreset', {
                activeId: streak._id,
                completeId: lastStreak._id
            })
                .then((response) => {
                    mixpanel.track('CustomizePage', {
                        'activity': 'restore_streak',
                        'uid': userUid
                    })
                    alert('Successfully Restored your previous Streak. Open BlockerX App to view the Streak')
                    document.getElementById('restore_streak_switch_submit').disabled = true
                })
                .catch((error2) => {
                    alert('Your Request cannot be initiated. Try again later')
                    console.log(error2)
                });
        } else {
        }
    }

    const openAppleDocs = (type) => {
        if (type === 'subscription') {
            window.open('https://support.apple.com/en-ae/HT202039', '_self')
        } else {
            window.open('https://support.apple.com/en-ae/HT204084', '_self')
        }
    }

    const fetchAutorizedUserData = async (email, uid) => {
        const RESULT = await loginUserUsingGoogle(email, uid)
        if (RESULT !== null) {
            console.log({ RESULT })
            setUserUid(RESULT.uid)
            setUserEmail(email)
            setPartner(RESULT.partner)
            mixpanel.track('CustomizePage', {
                'activity': 'login_with_google',
                'email': email
            })
            if (RESULT.streaks !== null && RESULT.streaks !== undefined && RESULT.streaks.length > 0) {
                setStreak(RESULT.streaks[0])
            }
            if (RESULT.streaks !== null && RESULT.streaks !== undefined && RESULT.streaks.length > 1) {
                setLastSteak(RESULT.streaks[1])
            }
            if (RESULT.premium !== null && RESULT.premium !== undefined && RESULT.premium.length > 0) {
                if (RESULT.premium[0].paymentMethod === 'stripeUAE' || RESULT.premium[0].paymentMethod === 'stripeUSA' || RESULT.premium[0].paymentMethod === 'stripe' || RESULT.premium[0].paymentMethod === 'stripeTest') {
                    RESULT.premium[0].paymentMethod = 'Stripe'
                }
                setPremium(RESULT.premium[0])
                try {
                    if (RESULT.premium[0].commonDisplayPlan === 'annual' || RESULT.premium[0].commonDisplayPlan === 'monthly') {
                        setPremiumType('subscription')
                        if (RESULT.premium[0].paymentMethod === 'Stripe') {
                            const tempTime = new Date().getTime()
                            if (tempTime - RESULT.premium[0].purchasedAt < (3 * 86400000)) {
                                setPremiumEligible(true)
                            } else {
                                setPremiumEligible(false)
                            }
                        } else {
                            setPremiumEligible(false)
                        }
                    } else if (RESULT.premium[0].commonDisplayPlan === 'lifetime') {
                        setPremiumType('charge')
                        const tempTime = new Date().getTime()
                        if (tempTime - RESULT.premium[0].purchasedAt < (3 * 86400000)) {
                            setPremiumEligible(true)
                        } else {
                            setPremiumEligible(false)
                        }
                    } else {
                        setPremiumType('bi-annual')
                    }
                } catch (e) {
                    console.log('Somehow here', e)
                }
            }
            setLoggedIn(true)
        } else {
            alert('Credentials added are invalid')
        }
    }

    useEffect(() => {
        console.log('in0')
        if (paramsUid && paramsEmail) {
            console.log('in1')
            fetchAutorizedUserData(paramsEmail, paramsUid)
            setLoading(false)
        } else {
            console.log('in8')
            setLoading(false)
        }
    }, []);
    if (loading) {
        console.log('in6')
        return (
            <section id='chatbot_loading'>
                <img src={load} alt='loading' />
            </section>
        )
    } else {
        console.log('in7')
        if (loggedIn) {
            console.log('in2')
            return (
                <section id='customize_container'>
                    <h1>Customize your Account</h1>
                    <h2>Logged In as <strong>{userEmail}</strong></h2>
                    <Grid sx={{ flexGrow: 1 }} container spacing={2} className='custom_grid_item'>
                        <Grid item xs={100}>
                            <Grid container justifyContent="center" spacing={4}>
                                <Grid item>
                                    <Card sx={{ maxWidth: 300 }}>
                                        <CardActionArea>
                                            <CardContent>
                                                <Typography gutterBottom variant="h4" component="div" height={90}>
                                                    Partner Information
                                                </Typography>
                                                {partner === 'Not present' ? (
                                                    <div className='information_custom_premium'>
                                                        <h3>Partner Does Not Exists</h3>
                                                    </div>
                                                ) : (
                                                    <div className='information_custom_premium'>
                                                        <span>Type: Email</span>
                                                        <span>Partner: {partner}</span>
                                                    </div>
                                                )}
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            {partner === 'Not present' ? (
                                                <button disabled className='custom_cta_btn'>Remove Partner</button>
                                            ) : (
                                                <button onClick={() => { removeThisPartner() }} id='remove_partner_submit' className='custom_cta_btn'>Remove Partner</button>
                                            )}

                                        </CardActions>
                                    </Card>
                                </Grid>
                                {/* <Grid item>
                                    <Card sx={{ maxWidth: 300 }}>
                                        <CardActionArea>
                                            <CardContent>
                                                <Typography gutterBottom variant="h4" component="div" height={90}>
                                                    Prevent Uninstall Switch
                                                </Typography>
                                                <div className='information_custom_premium'>
                                                    <span>*By Turning Switch from here, User will be able to Uninstall the BlockerX app in 'Android', Even if the Prevent Uninstall is turned on*</span>
                                                    <span>*If the Switch is already Off, this won't have any affect*</span>
                                                </div>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <button onClick={() => { processPreventUninstall() }} id='prevent_uninstall_switch_off' className='custom_cta_btn'>Turn Off Switch</button>
                                        </CardActions>
                                    </Card>
                                </Grid> */}
                                <Grid item>
                                    <Card sx={{ maxWidth: 300 }}>
                                        <CardActionArea>
                                            <CardContent>
                                                <Typography gutterBottom variant="h4" component="div" height={90}>
                                                    Streak Information
                                                </Typography>
                                                {lastStreak === null ? (
                                                    <div className='information_custom_premium'>
                                                        <span>You don't have any Streak for Restoring</span>
                                                    </div>
                                                ) : (
                                                    <div className='information_custom_premium'>
                                                        <span>"If you somehow relapsed by mistake. This option will help you to restore your previous Streak without loosing any day."</span>
                                                        <h3>Current Streak: </h3>
                                                        <span><strong>Start Time: </strong>{new Date(streak.startTime).toUTCString()}</span>
                                                        <span><strong>Streak (number of days): </strong>{Math.floor((new Date().getTime() - streak.startTime) / 86400000)}</span>
                                                        <div className='streak_icon_in_between'><FontAwesomeIcon icon={faArrowAltCircleDown} /></div>
                                                        <h3>Streak After Restore: </h3>
                                                        <span><strong>Start Time: </strong>{new Date(lastStreak.startTime).toUTCString()}</span>
                                                        <span><strong>Streak (number of days): </strong>{Math.floor((new Date().getTime() - lastStreak.startTime) / 86400000)}</span>
                                                    </div>
                                                )}
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <button onClick={() => { restoreStreak() }} id='restore_streak_switch_submit' className='custom_cta_btn'>Restore to Previous Streak</button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {showTerms && premiumType === 'subscription' ? (
                        <div className='terms_container'>
                            <div className='terms_close_area'>
                                <FontAwesomeIcon onClick={() => { setShowTerms(false) }} color='#474747' icon={faCircleXmark} />
                            </div>
                            <div className='terms_info_area'>
                                <span>For Annual and Monthly Subscription: Purchases are final and are not eligible for a refund.</span>
                                <span>Read our terms here: <a href='https://blockerx.net/terms-of-usage/' rel='noreferrer' target='_blank'>Terms Of Usage (blockerx.net)</a></span>
                            </div>
                        </div>
                    ) : showTerms ? (
                        <div className='terms_container'>
                            <div className='terms_close_area'>
                                <FontAwesomeIcon onClick={() => { setShowTerms(false) }} color='#474747' icon={faCircleXmark} />
                            </div>
                            <div className='terms_info_area'>
                                <span>For Lifetime: Purchases are only eligible for a refund within 3 days of purchase (3 day moneyback guarantee).</span>
                                <span>Read our terms here: <a href='https://blockerx.net/terms-of-usage/' rel='noreferrer' target='_blank'>Terms Of Usage (blockerx.net)</a></span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </section>
            )
        } else if (paramsUid && paramsEmail) {
            console.log('in3')
            return (
                <section id='customize_container'>
                    <h1>Customize your Account</h1>
                    <h2>Logged In as <strong>{userEmail}</strong></h2>
                    <Grid sx={{ flexGrow: 1 }} container spacing={2} className='custom_grid_item'>
                        <Grid item xs={100}>
                            <Grid container justifyContent="center" spacing={4}>
                                <Grid item>
                                    <Card sx={{ maxWidth: 300 }}>
                                        <CardActionArea>
                                            <CardContent>
                                                <Typography gutterBottom variant="h4" component="div" height={90}>
                                                    Partner Information
                                                </Typography>
                                                {partner === 'Not present' ? (
                                                    <div className='information_custom_premium'>
                                                        <h3>Partner Does Not Exists</h3>
                                                    </div>
                                                ) : (
                                                    <div className='information_custom_premium'>
                                                        <span>Type: Email</span>
                                                        <span>Partner: {partner}</span>
                                                    </div>
                                                )}
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            {partner === 'Not present' ? (
                                                <button disabled className='custom_cta_btn'>Remove Partner</button>
                                            ) : (
                                                <button onClick={() => { removeThisPartner() }} id='remove_partner_submit' className='custom_cta_btn'>Remove Partner</button>
                                            )}

                                        </CardActions>
                                    </Card>
                                </Grid>
                                {/* <Grid item>
                                    <Card sx={{ maxWidth: 300 }}>
                                        <CardActionArea>
                                            <CardContent>
                                                <Typography gutterBottom variant="h4" component="div" height={90}>
                                                    Prevent Uninstall Switch
                                                </Typography>
                                                <div className='information_custom_premium'>
                                                    <span>*By Turning Switch from here, User will be able to Uninstall the BlockerX app in 'Android', Even if the Prevent Uninstall is turned on*</span>
                                                    <span>*If the Switch is already Off, this won't have any affect*</span>
                                                </div>
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <button onClick={() => { processPreventUninstall() }} id='prevent_uninstall_switch_off' className='custom_cta_btn'>Turn Off Switch</button>
                                        </CardActions>
                                    </Card>
                                </Grid> */}
                                <Grid item>
                                    <Card sx={{ maxWidth: 300 }}>
                                        <CardActionArea>
                                            <CardContent>
                                                <Typography gutterBottom variant="h4" component="div" height={90}>
                                                    Streak Information
                                                </Typography>
                                                {lastStreak === null ? (
                                                    <div className='information_custom_premium'>
                                                        <span>You don't have any Streak for Restoring</span>
                                                    </div>
                                                ) : (
                                                    <div className='information_custom_premium'>
                                                        <span>"If you somehow relapsed by mistake. This option will help you to restore you previous Streak without loosing any day."</span>
                                                        <h3>Current Streak: </h3>
                                                        <span><strong>Start Time: </strong>{new Date(streak.startTime).toUTCString()}</span>
                                                        <span><strong>Streak (number of days): </strong>{Math.floor((new Date().getTime() - streak.startTime) / 86400000)}</span>
                                                        <div className='streak_icon_in_between'><FontAwesomeIcon icon={faArrowAltCircleDown} /></div>
                                                        <h3>Streak After Restore: </h3>
                                                        <span><strong>Start Time: </strong>{new Date(lastStreak.startTime).toUTCString()}</span>
                                                        <span><strong>Streak (number of days): </strong>{Math.floor((new Date().getTime() - lastStreak.startTime) / 86400000)}</span>
                                                    </div>
                                                )}
                                            </CardContent>
                                        </CardActionArea>
                                        <CardActions>
                                            <button onClick={() => { restoreStreak() }} id='restore_streak_switch_submit' className='custom_cta_btn'>Restore to Previous Streak</button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {showTerms && premiumType === 'subscription' ? (
                        <div className='terms_container'>
                            <div className='terms_close_area'>
                                <FontAwesomeIcon onClick={() => { setShowTerms(false) }} color='#474747' icon={faCircleXmark} />
                            </div>
                            <div className='terms_info_area'>
                                <span>For Annual and Monthly Subscription: Purchases are final and are not eligible for a refund.</span>
                                <span>Read our terms here: <a href='https://blockerx.net/terms-of-usage/' rel='noreferrer' target='_blank'>Terms Of Usage (blockerx.net)</a></span>
                            </div>
                        </div>
                    ) : showTerms ? (
                        <div className='terms_container'>
                            <div className='terms_close_area'>
                                <FontAwesomeIcon onClick={() => { setShowTerms(false) }} color='#474747' icon={faCircleXmark} />
                            </div>
                            <div className='terms_info_area'>
                                <span>For Lifetime: Purchases are only eligible for a refund within 3 days of purchase (3 day moneyback guarantee).</span>
                                <span>Read our terms here: <a href='https://blockerx.net/terms-of-usage/' rel='noreferrer' target='_blank'>Terms Of Usage (blockerx.net)</a></span>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </section>
            )
        } else {
            console.log('in4')
            return (
                <section id='customize_login_area'>
                    <div className='custom_login_head'>
                        <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png' alt='brand-logo' />
                        <button onClick={() => { navigate('/forgotPassword') }} className='customize_head_forgot'>Forgot Password</button>
                    </div>
                    <div className='custom_login_body'>
                        <div className='custom_login_body_area'>
                            <div className='custom_login_body_text'>
                                <h2>Login to Continue</h2>
                            </div>
                            <div className="google_area">
                                <button onClick={loginWithGoogle} id="custom_auth_google">
                                    Signin with  <img width="25px" src="https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/googleIcon.png" alt="google_icon" />
                                </button>
                            </div>
                            <div className="partition">
                                <span>or</span>
                            </div>
                            <div className='signup_body_form'>
                                <input type='text' value={new_user_email} onChange={(e) => { set_new_user_email(e.target.value) }} placeholder='Enter your Email' />
                                <input type="password" value={new_user_password1} onChange={(e) => { set_new_user_password1(e.target.value) }} placeholder="Enter Password" />
                                <button onClick={logInNow} id="custom_login">Login</button>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
    }
}

export default CustomizePage;