import React, { useState, useEffect } from 'react';
import './viewTicket.css'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import codi from '../SupportChat/codi.png';
import userIcon from './user-icons.png'
import load from '../SupportChat/loading-paper.gif';
import auth, { provider } from '../../Connections/firebase';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPaperclip, faFile, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import ReactTimeAgo from 'react-time-ago'

function ViewTicketPage() {

    const { id } = useParams();
    const navigate = useNavigate();
    const paramsEmail = new URLSearchParams(window.location.search).get("email")
    const paramsUid = new URLSearchParams(window.location.search).get("uid")

    const [loggedIn, setLoggedIn] = useState(false)
    const [new_user_email, set_new_user_email] = useState('')
    const [new_user_password, set_new_user_password] = useState('')
    const [listOfTickets, setListOfTickets] = useState([])
    const [userEmail, setUserEmail] = useState(null)
    const [conversation, setConversation] = useState([])
    const [ticketInfo, setTicketInfo] = useState(null)
    const [loading, setLoading] = useState(true)
    const [addingChatLoad, setAddingChatLoad] = useState(false)
    const [inputText, setInputText] = useState('')
    const [fileAttachOpen, setFileAttachOpen] = useState(false)
    const [newSelectedFile, setNewSelectedFile] = useState(null)
    const [openFullImage, setOpenFullImage] = useState(false)

    function loginUserUsingCredentials(email, password) {
        return new Promise((resolve, reject) => {
            axios.post('https://accounts.blockerx.net/api/tickets/login', {
                provider: false,
                email,
                password
            })
                .then((response) => {
                    try {
                        if (response.data.status === 200) {
                            resolve(response.data.data.tickets)
                        } else {
                            resolve(null)
                        }
                    } catch {
                        resolve(null)
                    }
                })
                .catch((error2) => {
                    resolve(null)
                });
        })
    }

    function loginUserUsingGoogle(email, uid) {
        return new Promise((resolve, reject) => {
            axios.post('https://accounts.blockerx.net/api/tickets/login', {
                provider: true,
                email,
                uid
            })
                .then((response) => {
                    try {
                        if (response.data.status === 200) {
                            resolve(response.data.data.tickets)
                        } else {
                            resolve(null)
                        }
                    } catch {
                        resolve(null)
                    }
                })
                .catch((error2) => {
                    resolve(null)
                });
        })
    }

    function fetchTicketInfo(ticketId) {
        return new Promise((resolve, reject) => {
            axios.post('https://accounts.blockerx.net/api/tickets/chat/history', {
                ticketId
            })
                .then((response) => {
                    try {
                        if (response.data.status === 200) {
                            resolve({
                                history: response.data.data.history,
                                ticket: response.data.data.ticket
                            })
                        } else {
                            resolve(null)
                        }
                    } catch {
                        resolve(null)
                    }
                })
                .catch((error2) => {
                    resolve(null)
                })
        })
    }

    function appendFileinDB(newFile) {
        console.log('Starting upload')
        return new Promise((resolve, reject) => {
            const formData = new FormData()
            formData.append('ticketId', id)
            formData.append('sender', 'user')
            formData.append('file', newFile)
            axios.post('https://dev.blockerx.net/api/tickets/v2/attachments', formData)
                .then((response) => {
                    console.log('Uploaded', response.data)
                    resolve(true)
                })
                .catch((error2) => {
                    resolve(false)
                })
        })
    }

    function addConversationToTicket(ticketId, message) {
        return new Promise((resolve, reject) => {
            axios.post('https://accounts.blockerx.net/api/tickets/chat', {
                ticketId,
                sender: 'user',
                message
            })
                .then((response) => {
                    try {
                        if (response.data.status === 200) {
                            resolve(true)
                        } else {
                            resolve(false)
                        }
                    } catch {
                        resolve(false)
                    }
                })
                .catch((error2) => {
                    resolve(false)
                })
        })
    }

    const loginWithGoogle = () => {
        signInWithPopup(auth, provider)
            .then(async (result) => {
                const RESULT = await loginUserUsingGoogle(result.user.email, result.user.uid)
                if (RESULT !== null) {
                    console.log({ RESULT })
                    setListOfTickets(RESULT)
                    setUserEmail(result.user.email)
                    setLoggedIn(true)
                } else {
                    alert('Credentials added are invalid')
                }
            })
            .catch((err1) => {
                alert('Something went wrong')
            })
    }

    const logInNow = async () => {
        const tempEmail = new_user_email.trim()
        const tempPassword = new_user_password.trim()
        if (tempEmail && tempEmail.length > 0) {
            if (tempPassword && tempPassword.length > 0) {
                const RESULT = await loginUserUsingCredentials(tempEmail, tempPassword)
                set_new_user_email('')
                set_new_user_password('')
                if (RESULT !== null) {
                    setListOfTickets(RESULT)
                    setUserEmail(new_user_email)
                    setLoggedIn(true)
                } else {
                    alert('Credentials added are invalid')
                }
            } else {
                alert('Please enter a valid Password')
            }
        } else {
            alert('Please enter a valid Email')
        }
    }

    const directOpenTicketsPage = async () => {
        const RESULT = await loginUserUsingGoogle(paramsEmail, paramsUid)
        if (RESULT !== null) {
            setListOfTickets(RESULT)
            setUserEmail(paramsEmail)
            setLoggedIn(true)
            setLoading(false)
        } else {
            alert('Credentials added are invalid')
        }
    }

    const fetchTicketData = async (ticketId) => {
        console.log('Fetching for', ticketId)
        setConversation([])
        setTicketInfo(null)
        const RESULT = await fetchTicketInfo(ticketId)
        if (RESULT) {
            console.log('Fetched', RESULT)
            setConversation(RESULT.history)
            setTicketInfo(RESULT.ticket)
            setLoading(false)
        } else {
            alert('Something Went Wrong.')
        }
    }

    const addItemToConversation = async () => {
        setAddingChatLoad(true)
        if (inputText && inputText.length) {
            const RESULT = await addConversationToTicket(id, inputText)
            if (RESULT) {
                setConversation(previousChats => [
                    ...previousChats,
                    {
                        sender: "user",
                        message: inputText,
                        time: new Date().getTime()
                    }
                ])
            } else {
                alert('Something Went wrong')
            }
        } else {
            alert('Cannot add empty chat')
        }
        setInputText('')
        setAddingChatLoad(false)
    }

    const parseTextForLink = (text) => {
        const lines = text.split('\n')
        let parsedText = []
        lines.map((line, num) => {
            const words = line.split(' ')
            words.map((word, index) => {
                if (word.startsWith('http://') || word.startsWith('https://')) {
                    parsedText.push(
                        <a
                            key={index}
                            href={word}
                            style={{ textDecoration: 'underline', fontWeight: "bold", color: "blue" }}
                            target="_blank"
                            rel="noopener noreferrer"
                        > {word} </a>
                    )
                } else {
                    parsedText.push(word + ' ')
                }
            })
            parsedText.push('\n')
        })
        return parsedText
    }

    const openFileInNewTab = (fileUrl) => {
        window.open(fileUrl, '_blank')
    }

    const openFileAttachPopup = () => {
        setFileAttachOpen(true)
    }

    const CloseFileAttachPopup = () => {
        setFileAttachOpen(false)
    }

    const handleUpload = async () => {
        if (newSelectedFile.size > 100 * 1000 * 1024) {
            alert('Please upload a file less than 100MB')
            document.getElementById('newFile').value = ''
            setNewSelectedFile(null)
        } else {
            setLoading(true)
            await appendFileinDB(newSelectedFile)
            alert('Successfully Uploaded File')
            window.location.reload(true)
        }
    };

    useEffect(() => {
        setLoading(true)
        if (!id && paramsUid && paramsEmail) {
            directOpenTicketsPage()
        }
        if (paramsEmail) {
            setUserEmail(paramsEmail)
            if (id) {
                fetchTicketData(id)
            }
        } else {
            if (id) {
                fetchTicketData(id)
            } else {
                setLoading(false)
            }
        }
    }, []);

    if (id) {
        if (loading) {
            return (
                <section id='chatbot_loading'>
                    <img src={load} alt='loading' />
                </section>
            )
        } else {
            return (
                <section id='ticket_single_page'>
                    <div className='single_ticket_header'>
                        <div className='single_ticket_brand'>
                            <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png' alt='brand-logo' />
                        </div>
                        <div className='single_ticket_user'>
                            {userEmail ? (
                                <span>{userEmail}</span>
                            ) : (
                                <span onClick={() => { navigate('/ticket/view') }}>Login to Get More Information</span>
                            )}
                        </div>
                    </div>
                    {userEmail ? (
                        <div className='single_ticket_body'>
                            <h1>Ticket ID: #{id}</h1>
                            <div className='single_ticket_info'>
                                <span><strong>App: </strong>{ticketInfo.appName} - {ticketInfo.platform}</span>
                                {ticketInfo.status === 'pending' ? (
                                    <span><strong>Status: </strong>Pending</span>
                                ) : ticketInfo.status === 'tech_review' ? (
                                    <span><strong>Status: </strong> Tech Review</span>
                                ) : ticketInfo.status === 'awaiting_user_reply' ? (
                                    <span><strong>Status: </strong> Awaiting User Reply</span>
                                ) : ticketInfo.status === 'closed' ? (
                                    <span><strong>Status: </strong> Closed</span>
                                ) : (
                                    <span><strong>Status: </strong> Pending</span>
                                )}
                                <span><strong>Created: </strong>{new Date(ticketInfo.time).toUTCString()}</span>
                                <span><strong>Category: </strong>{ticketInfo.tag}</span>
                                <span><strong>Description: </strong>{ticketInfo.description}</span>
                                {ticketInfo.files.length ? (
                                    <span className='ticket_files_list'>
                                        <strong>Files Attached: </strong>
                                        {ticketInfo.files.map((singleFile) => {
                                            return (
                                                <FontAwesomeIcon className='single_file_icon' icon={faFile} onClick={() => {
                                                    openFileInNewTab(singleFile)
                                                }} />
                                            )
                                        })}
                                    </span>
                                ) : (
                                    <></>
                                )}
                            </div>
                            {conversation.length ? (
                                <div className='single_ticket_conversation'>
                                    <h3>Conversation: </h3>
                                    {conversation.map((single_convo) => {
                                        if (single_convo.message.slice(0, 20) === "https://common-icons") {
                                            if (single_convo.message.endsWith('.mp4') || single_convo.message.endsWith('.avi') || single_convo.message.endsWith('.m4a') || single_convo.message.endsWith('.mkv') || single_convo.message.endsWith('.flv')) {
                                                const vidComponent = document.createElement('source')
                                                vidComponent.src = single_convo.message
                                                vidComponent.onloadedmetadata = () => {
                                                    console.log('Test', vidComponent)
                                                }
                                                return (
                                                    <div className={single_convo.sender === 'user' ? 'user_type' : 'dev_type'}>
                                                        <img src={single_convo.sender === 'user' ? userIcon : codi} alt='codi'></img>
                                                        <p>
                                                            <div className='attached_image_container'>
                                                                <video controls>
                                                                    <source src={single_convo.message} />
                                                                </video>
                                                                {/* <video src={single_convo.message} /> */}
                                                            </div>
                                                            <span className='sub_message'>{single_convo.sender === 'user' ? ('You') : ticketInfo.assigned[0].toUpperCase() + ticketInfo.assigned.slice(1)} |&nbsp;<ReactTimeAgo date={single_convo.time} locale="en-US" timeStyle="round" /></span>
                                                        </p>
                                                    </div>
                                                )
                                            } else {
                                                const imgComponent = new Image()
                                                let orientation = 'potrait'
                                                imgComponent.src = single_convo.message
                                                if (imgComponent.width > imgComponent.height) {
                                                    orientation = 'landscape'
                                                }
                                                return (
                                                    <div className={single_convo.sender === 'user' ? 'user_type' : 'dev_type'}>
                                                        <img src={single_convo.sender === 'user' ? userIcon : codi} alt='codi'></img>
                                                        <p>
                                                            <div className='attached_image_container'>
                                                                <img className={orientation === 'potrait' ? 'potrait' : 'landscape'} src={single_convo.message} alt='attached_image' />
                                                            </div>
                                                            <span className='sub_message'>{single_convo.sender === 'user' ? ('You') : ticketInfo.assigned[0].toUpperCase() + ticketInfo.assigned.slice(1)} |&nbsp;<ReactTimeAgo date={single_convo.time} locale="en-US" timeStyle="round" /></span>
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        } else {
                                            return (
                                                <div className={single_convo.sender === 'user' ? 'user_type' : 'dev_type'}>
                                                    <img src={single_convo.sender === 'user' ? userIcon : codi} alt='codi'></img>
                                                    <p><span>{parseTextForLink(single_convo.message)}</span> <span className='sub_message'>{single_convo.sender === 'user' ? ('You') : ticketInfo.assigned[0].toUpperCase() + ticketInfo.assigned.slice(1)} |&nbsp;<ReactTimeAgo date={single_convo.time} locale="en-US" timeStyle="round" /></span></p>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    ) : (
                        <div className='single_ticket_body'>
                            <h1>Ticket ID: #{id}</h1>
                            <h5>Your Ticket has been registered with us. Please Login to know the status of the Ticket</h5>
                        </div>
                    )}
                    {userEmail ? (
                        <div className='single_ticket_footer'>
                            <div className='ticket_reply_attach'>
                                <button onClick={openFileAttachPopup}><FontAwesomeIcon icon={faPaperclip} /></button>
                            </div>
                            <div className='ticket_reply_footer'>
                                <input type='text' placeholder='Add Comment' value={inputText} onChange={(e) => { setInputText(e.target.value) }} />
                            </div>
                            <div className='ticket_reply_submit'>
                                {addingChatLoad ? (
                                    <button><img src={load} alt='loading' /></button>
                                ) : (
                                    <button onClick={addItemToConversation}><FontAwesomeIcon icon={faPaperPlane} /></button>
                                )}
                            </div>
                        </div>
                    ) : (<></>)}
                    {fileAttachOpen ? (
                        <div className='ticket_attach_popup'>
                            <div className='ticket_attach_container'>
                                <div className='file_attach_close_area'>
                                    <FontAwesomeIcon onClick={CloseFileAttachPopup} icon={faXmarkCircle} />
                                </div>
                                <h1>Attach file to this Ticket</h1>
                                <div className='file_attach_container'>
                                    <div>
                                        <label htmlFor="newFile" className="sr-only">
                                            Choose a file
                                        </label>
                                        <input id="newFile" type="file" accept='image/*,video/*' onChange={(e) => { setNewSelectedFile(e.target.files[0]) }} />
                                    </div>
                                    {newSelectedFile && (
                                        <section>
                                            File details:
                                            <ul>
                                                <li>Name: {newSelectedFile.name}</li>
                                                <li>Type: {newSelectedFile.type}</li>
                                                <li>Size: {newSelectedFile.size} bytes</li>
                                            </ul>
                                        </section>
                                    )}

                                    {newSelectedFile && <button className='upload_this_file_btn' onClick={handleUpload}>Upload</button>}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {openFullImage ? (
                        <div className='ticket_full_image_popup'>

                        </div>
                    ) : (
                        <></>
                    )}
                </section>
            )
        }
    } else if (loggedIn) {
        return (
            <section id='ticket_view_page'>
                <div className='ticket_view_head'>
                    <h1>User Tickets</h1>
                    <p>Email: <strong>{userEmail}</strong></p>
                </div>
                {listOfTickets.length ? (
                    <div className='ticket_view_body'>
                        {listOfTickets.map((single_ticket) => {
                            return (
                                <div className='single_ticket_card' onClick={() => {
                                    let url = window.location.origin + '/ticket/view/' + single_ticket._id + "?email=" + userEmail
                                    window.open(url, '_self')
                                }}>
                                    {/* <h2><strong>Ticket ID:</strong> #{single_ticket._id}</h2>
                                    <span><strong>Regarding:</strong> {single_ticket.appName} - {single_ticket.platform}</span>
                                    <span><strong>Description:</strong> {single_ticket.description}</span>
                                    {single_ticket.status === 'pending' ? (
                                        <span><strong>Status: </strong>Pending</span>
                                    ) : single_ticket.status === 'tech_review' ? (
                                        <span><strong>Status: </strong> Tech Review</span>
                                    ) : single_ticket.status === 'awaiting_user_reply' ? (
                                        <span><strong>Status: </strong> Awaiting User Reply</span>
                                    ) : single_ticket.status === 'closed' ? (
                                        <span><strong>Status: </strong> Closed</span>
                                    ) : (
                                        <span><strong>Status: </strong> Pending</span>
                                    )}
                                    <span><strong>Category:</strong> {single_ticket.tag}</span>
                                    <span><strong>Created:</strong> {new Date(single_ticket.time).toUTCString()}</span>
                                    <p>It usually takes upto 48hrs to resolve an issue</p> */}
                                    <div className='single_ticket_card_header'>
                                        <div className='ticket_card_head_id'>
                                            <span>Ticket ID</span>
                                            <h2>#{single_ticket._id}</h2>
                                        </div>
                                        <div className='ticket_card_head_status'>
                                            {single_ticket.status === 'pending' ? (
                                                <span className='pending'>Pending</span>
                                            ) : single_ticket.status === 'tech_review' ? (
                                                <span className='tech_review'> Tech Review</span>
                                            ) : single_ticket.status === 'awaiting_user_reply' ? (
                                                <span className='awaiting_user_reply'> Awaiting User Reply</span>
                                            ) : single_ticket.status === 'closed' ? (
                                                <span className='closed'> Closed</span>
                                            ) : (
                                                <span className='pending'> Pending</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className='single_ticket_time'>
                                        <p>{new Date(single_ticket.time).toUTCString()}</p>
                                    </div>
                                    <div className='single_ticket_regarding'>
                                        <p>Regarding</p>
                                        {single_ticket.appName === 'BlockerX' ? (
                                            <span className='blockerx_app'>{single_ticket.appName}</span>
                                        ) : single_ticket.appName === 'Healify' ? (
                                            <span className='healify_app'>{single_ticket.appName}</span>
                                        ) : single_ticket.appName === 'Zenze' ? (
                                            <span className='zenze_app'>{single_ticket.appName}</span>
                                        ) : single_ticket.appName === 'SocialX' ? (
                                            <span className='socialx_app'>{single_ticket.appName}</span>
                                        ) : single_ticket.appName === 'DTox' ? (
                                            <span className='dtox_app'>{single_ticket.appName}</span>
                                        ) : (
                                            <span className='blockerx_app'>{single_ticket.appName}</span>
                                        )}
                                        {single_ticket.platform === 'android' ? (
                                            <span>Android</span>
                                        ) : single_ticket.platform === 'ios' ? (
                                            <span>Ios</span>
                                        ) : single_ticket.platform === 'windows' ? (
                                            <span>Windows</span>
                                        ) : single_ticket.platform === 'macos' ? (
                                            <span>MacOs</span>
                                        ) : single_ticket.platform === 'chrome' ? (
                                            <span>Chrome</span>
                                        ) : (
                                            <span>Android</span>
                                        )}
                                    </div>
                                    <div className='single_ticket_category'>
                                        <p>Category</p>
                                        <span className='category'>{single_ticket.tag}</span>
                                    </div>
                                    <div className='single_ticket_description'>
                                        <p>Description</p>
                                        <span className='description'>{single_ticket.description}</span>
                                    </div>
                                    <div className='single_ticket_note'>
                                        <p>It usually takes up-to 48hrs to resolve an issue</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                ) : (
                    <div className='ticket_view_body'>
                        <h3>There are no Tickets Registered with this Email</h3>
                    </div>
                )}
            </section>
        )
    } else {
        if (loading) {
            return (
                <section id='chatbot_loading'>
                    <img src={load} alt='loading' />
                </section>
            )
        } else {
            return (
                <section id='ticket_login_area'>
                    <div className='ticket_login_head'>
                        <img src='https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/icon.png' alt='brand-logo' />
                    </div>
                    <div className='ticket_login_body'>
                        <div className='ticket_login_body_area'>
                            <div className='ticket_login_body_text'>
                                <h2>Login</h2>
                                <p>To continue to View your Ticket Status and Replies</p>
                            </div>
                            <div className="google_area">
                                <button onClick={loginWithGoogle} id="ticket_auth_google">
                                    <span>Signin with </span>
                                    <img width="25px" src="https://feed-posts.s3.amazonaws.com/blockerx-landing-page/assets/googleIcon.png" alt="google_icon" />
                                </button>
                            </div>
                            <div className="partition">
                                <span>or</span>
                            </div>
                            <div className='signup_body_form'>
                                <input type='text' value={new_user_email} onChange={(e) => { set_new_user_email(e.target.value) }} placeholder='Enter your Email' />
                                <input type="password" value={new_user_password} onChange={(e) => { set_new_user_password(e.target.value) }} placeholder="Enter Password" />
                                <button onClick={logInNow} id="ticket_login">Login</button>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
    }

}

export default ViewTicketPage