import React from 'react';
import './footer.css'

import BlockerX from './BlockerX.png'
import CommunityLogo from './communityLogo.png'
import YoutubeLogo from './youtube.png'

import iosIcon from './iosLogo.png'
import androidIcon from './playstore.png'
import chromeIcon from './chromestore.png'
import { useNavigate } from "react-router-dom";

import mixpanel from 'mixpanel-browser';
mixpanel.init('d051baf39ffda3bf75a7b21ddff1eef4', { debug: false });


const Footer = () => {
    const navigate = useNavigate();
    const handleAndroidLogoClick = () => {
        window.open('https://play.google.com/store/apps/details?id=io.funswitch.blocker', '_self');
        mixpanel.track('LandingPage Footer', {
            'click': 'open_android_download_link'
        })
    }

    const handleIosLogoClick = () => {
        window.open('https://apps.apple.com/in/app/porn-web-blocker-blockerx/id1522854086', '_self');
        mixpanel.track('LandingPage Footer', {
            'click': 'open_ios_download_link'
        })
    }

    const handleChromeLogoClick = () => {
        window.open('https://chrome.google.com/webstore/detail/blockerx-porn-blocker-for/jkkfeklkdokfnlfodkdafododleokdoa', '_self');
        mixpanel.track('LandingPage Footer', {
            'click': 'open_chrome_download_link'
        })
    }

    const openYoutube = () => {
        window.open('https://www.youtube.com/watch?v=1-AEJvMz8fM', '_self');
        mixpanel.track('LandingPage Footer', {
            'click': 'open_youtube'
        })
    }

    const openCommunityWeb = () => {
        window.open('https://community.blockerx.net/', '_self');
        mixpanel.track('LandingPage Footer', {
            'click': 'open_community_web'
        })
    }

    // const handleBlogClick = (category) => {
    //     mixpanel.track('LandingPage Footer', { click: 'Blog Link Clicked', category: category });
    // };

    return (
        <>
            <div className='footerDiv'>
                <div className='footerMain'>
                    <div className='footerSection logoSection'>
                        <div className='blockerxLogo'>
                            <img src={BlockerX} alt="" />
                        </div>
                        <div className='actionButtons'>
                            <button id='signupForNewUser' onClick={() => {
                                mixpanel.track('LandingPage', {
                                    'click': 'signup_button'
                                })
                                navigate("/signup")
                            }}>Signup</button>
                            <button onClick={() => {
                                mixpanel.track('LandingPage', {
                                    'click': 'reset_password_button'
                                })
                                navigate("/forgotPassword")
                            }}>Forgot Password</button>
                        </div>
                        <div className='promotionLinks'>
                            <span className='promoButton' onClick={openYoutube}><img src={YoutubeLogo} alt="" /></span>
                            <span className='promoButton community' onClick={openCommunityWeb}><img src={CommunityLogo} alt="" /></span>
                        </div>
                    </div>
                    {/* <div className='footerSection blogSection'>
                        <div className='footerHeading'>Blog</div>
                        <div className='blogTitle'>
                            <span><a href="https://blockerx.net/blog/category/all-about-urges/" onClick={() => handleBlogClick('All About Urges')}>All About Urges</a></span>
                            <span><a href="https://blockerx.net/blog/category/erectile-dysfunction/" onClick={() => handleBlogClick('Erectile Dysfunction')}>Erectile Dysfunction</a></span>
                            <span><a href="https://blockerx.net/blog/category/increase-your-productivity/" onClick={() => handleBlogClick('Increase Your Productivity')}>Increase Your Productivity</a></span>
                            <span><a href="https://blockerx.net/blog/category/for-partners-of-porn-addicts/" onClick={() => handleBlogClick('For Partners/Family of Porn Addicts')}>For Partners/Family of Porn Addicts</a></span>
                            <span><a href="https://blockerx.net/blog/category/how-to-deal-with-relapse/" onClick={() => handleBlogClick('How to deal with relapses')}
                            >How to deal with relapses</a></span>
                            <span><a href="https://blockerx.net/blog/death-grip-syndrome/" onClick={() => handleBlogClick('Death Grip Syndrome')}>Death Grip Syndrome</a></span>
                        </div>
                    </div> */}
                    <div className='footerSection productSection'>
                        <div className='footerHeading'>Products</div>
                        <div className='productsList'>
                            <div className='productDiv' onClick={handleAndroidLogoClick}>
                                <div className='productIcon'><img src={androidIcon} alt="icon" /></div>
                                <span>Porn Blocker for Android</span>
                            </div>
                            <div className='productDiv' onClick={handleIosLogoClick}>
                                <div className='productIcon'><img src={iosIcon} alt="icon" /></div>
                                <span>Porn Blocker for iOS</span>
                            </div>
                            <div className='productDiv' onClick={handleChromeLogoClick}>
                                <div className='productIcon'><img src={chromeIcon} alt="icon" /></div>
                                <span>Porn Blocker Chrome Extension</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;