import React, { useEffect, useState } from "react";
import './profilePage.css'
import { useParams } from 'react-router-dom';
import profileImg from './profile.png';
import loadingImg from './loading.gif'
import axios from 'axios';
import AchievementCard from "./achievementCard";

function ProfilePage() {
    const { uid } = useParams()
    const [loadingState, setLoadingState] = useState(true)
    const [profileData, setProfileData] = useState({})
    const [followers, setFollowers] = useState(0)
    const [following, setFollowing] = useState(0)


    const getProfileInfo = (uid) => {
        axios.post('https://accounts.blockerx.net/api/v1/profile', {
            uid
        })
            .then((response) => {
                if (response.data.status === "success") {
                    setProfileData(response.data.data)
                    if ('follow_unfollow' in response.data.data && 'userFollowing' in response.data.data.follow_unfollow && 'totalFollowingCount' in response.data.data.follow_unfollow.userFollowing) {
                        setFollowing(response.data.data.follow_unfollow.userFollowing.totalFollowingCount)
                    }
                    if ('follow_unfollow' in response.data.data && 'userFollowers' in response.data.data.follow_unfollow && 'totalFollowersCount' in response.data.data.follow_unfollow.userFollowers) {
                        setFollowers(response.data.data.follow_unfollow.userFollowers.totalFollowersCount)
                    }
                } else {
                    alert('Something went wrong')
                }
                setLoadingState(false)
            })
            .catch((error) => {
                alert('Something went wrong')
            })
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            getProfileInfo(uid)
        }, 1500);
        return () => clearTimeout(timer);
    }, []);
    if (loadingState) {
        return (
            <div className="loading_container">
                <div className="loading_img">
                    <img src={loadingImg} alt="loading" />
                </div>
            </div>
        )
    } else {
        return (
            <div className="main_container">
                <div className="header_area">
                    <div className="user_area">
                        <div className="profile_img">
                            <img src={profileImg} alt="profileImg" />
                        </div>
                        <div className="username_area">
                            <h2>{profileData.userName}</h2>
                            <h4>{profileData.userEmail}</h4>
                        </div>
                    </div>
                    <div className="stats_area">
                        <div className="follower_box">
                            <h1>{followers}</h1>
                            <h5>Followers</h5>
                        </div>
                        <div className="following_box">
                            <h1>{following}</h1>
                            <h5>Following</h5>
                        </div>
                        <div className="longest_streak_box">
                            <h1>{profileData.achievements[0].currentScore} d</h1>
                            <h5>Longest Streak</h5>
                        </div>
                    </div>
                </div>
                <div className="achievements_area">
                    <h1>Achievements</h1>
                    <div className="cards_container">
                        {
                            profileData.achievements.map((eachAchievement) => {
                                return (
                                    <AchievementCard
                                        key = {eachAchievement.title}
                                        title = {eachAchievement.title}
                                        description = {eachAchievement.description}
                                        iconBg = {eachAchievement.iconBg}
                                        icon = {eachAchievement.icon}
                                        score = {eachAchievement.score}
                                        target = {eachAchievement.target}
                                        currentLevel = {eachAchievement.currentLevel}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ProfilePage