import React, { useState, useEffect } from "react";
import { Box, CssBaseline } from "@mui/material";
import axios from 'axios';
import { useNavigate, useParams, Outlet } from "react-router-dom";
import { Toolbar, Typography, Container } from '@mui/material'
import './Article.css'
import load from '../SupportChat/loading-paper.gif'
import HeaderNav from "./HeaderNav";
// import RelatedPost from "./RelatedPost";
// import cards from './CardsData';

const Articles = () => {

	const { article } = useParams();
	const [loading, setLoading] = useState(true)
	const [pageContent, setPageContent] = useState(null)
	const navigate = useNavigate()

	function getArticleData(article) {
		return new Promise((resolve, reject) => {
			axios.get(`https://dev.blockerx.net/api/web/articles/${article}`)
				.then((response) => {
					resolve({
						content: response.data.data.article,
						status: true
					})
				})
				.catch((error2) => {
					resolve({
						status: false
					})
				})
		})
	}

	async function fetchArticleInfo(article) {
		const RESULT = await getArticleData(article)
		if (RESULT && RESULT.status) {
			console.log("content length: ", Object.keys(RESULT.content).length)
			if (Object.keys(RESULT.content).length) {
				console.log("content: ", RESULT.content)
				setPageContent(RESULT.content)
				setLoading(false)
			}
			else {
				navigate('/')
			}
		} else {
			alert('Something Went wrong. Please try again.')
		}
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			if (article) {
				console.log('Here is the token', article)
				fetchArticleInfo(article)
			} else {
				navigate('/')
			}
		}, 1000);
		return () => clearTimeout(timer);
	}, []);

	// const handleRelatedPostClick = (articleId) => {
	// 	navigate(`/blog-page/${articleId}`);
	// 	window.scrollTo({ top: 0 });
	// };

	const scrollToSection = (id) => {
		const element = document.getElementById(id);
		if (element) {
			const offset = element.offsetTop - 80;
			console.log('offsetTop: ', offset);

			window.scrollTo({
				top: offset,
				behavior: 'smooth',
			});
		}
	};

	const formattedDate = pageContent ? new Date(parseInt(pageContent.creationDate, 10)).toLocaleDateString('en-US', {
		month: 'long',
		day: 'numeric',
		year: 'numeric'
	}) : '';

	if (loading) {
		return (
			<section id='chatbot_loading'>
				<img src={load} alt='loading' />
			</section>
		)
	} else {
		return (
			<Box className='blogsPage'>
				<CssBaseline />
				<HeaderNav />
				<Box className='articleContent' component='main' sx={{ width: '100%' }}>
					<Toolbar />
					<Box className="articleHeading" sx={{ flexDirection: { sm: 'row', xs: 'column' }, bgcolor: pageContent.heroBg }}>
						<Box sx={{ width: { sm: '50%', xs: '100%' }, display: 'flex', justifyContent: { sm: "flex-end", xs: 'center' } }}>
							<img className='frameImage' src={pageContent.heroImage} alt="" />
						</Box>
						<Box p={2} className='articleHeadline' marginLeft={0} sx={{ width: { sm: '50%', xs: '100%' }, display: 'flex', textAlign: { sm: "left", xs: 'center' }, flexDirection: 'column' }}>
							<Typography fontFamily={'poppins'} variant='h4' sx={{ color: 'white', fontWeight: '700', width: { sm: '60%', xs: '100%' } }} >
								{pageContent.title}
							</Typography>
							<Typography fontSize={'.8rem'} className='name' mt={5} fontFamily={'poppins'}>{pageContent.authorName}</Typography>
							<Typography fontSize={'.8rem'} fontFamily={'poppins'}>{formattedDate}</Typography>
						</Box>
					</Box>
					<Toolbar />
					<Container className='contentContainer' maxWidth='sm'>
						<Typography className='heading1' variant="h5" sx={{ fontWeight: '700', color: '#3E3E3E', fontFamily: 'poppins' }}>Table of Content</Typography>
						<Box className='tableContent' marginBottom={'30px'}>
							<Typography variant='p2'>
								<ul>
									{pageContent.articleContent.map((singleElement) => {
										if (singleElement.type === 'heading1') {
											return (
												<li>
													<a href={`#${singleElement.value.replace(/\s+/g, '_')}`} onClick={(e) => { e.preventDefault(); scrollToSection(singleElement.value.replace(/\s+/g, '_')); }}>{singleElement.value}</a>
												</li>
											);
										} else if (singleElement.type === 'heading2') {
											return (
												<ul style={{ listStyle: 'none', marginLeft: '-20px' }}>
													<li>
														<a href={`#${singleElement.value.replace(/\s+/g, '_')}`} onClick={(e) => { e.preventDefault(); scrollToSection(singleElement.value.replace(/\s+/g, '_')); }}>{singleElement.value}</a>
													</li>
												</ul>
											);
										} else if (singleElement.type === 'heading3') {
											return (
												<ul style={{ listStyle: 'none', marginLeft: '-20px' }}>
													<li>
														<a href={`#${singleElement.value.replace(/\s+/g, '_')}`} onClick={(e) => { e.preventDefault(); scrollToSection(singleElement.value.replace(/\s+/g, '_')); }}>{singleElement.value}</a>
													</li>
												</ul>
											);
										}
									})}
								</ul>
							</Typography>
						</Box>
						{pageContent.articleContent.map((singleElement) => {
							if (singleElement.type === 'heading1') {
								return (
									<Typography className='heading' marginTop={'30px'} variant="h5" sx={{ fontWeight: '700', color: '#3E3E3E', marginBottom: '15px', fontFamily: 'poppins' }} id={`${singleElement.value.replace(/\s+/g, '_')}`}>{singleElement.value}</Typography>
								)
							} else if (singleElement.type === 'heading2') {
								return (
									<>
										<Typography color={'#3E3E3E'} marginTop={'20px'} variant='p2' sx={{ fontFamily: 'poppins', fontWeight: '700' }} id={`${singleElement.value.replace(/\s+/g, '_')}`}>{singleElement.value}</Typography>
										<div style={{ marginBottom: '5px' }}></div>
									</>
								)
							} else if (singleElement.type === 'heading3') {
								return (
									<>
										<Typography color={'#3E3E3E'} marginTop={'20px'} variant='h6' sx={{ fontFamily: 'poppins', fontWeight: '700' }} id={`${singleElement.value.replace(/\s+/g, '_')}`}>{singleElement.value}</Typography>
										<div style={{ marginBottom: '10px' }}></div>
									</>
								)
							}
							else if (singleElement.type === 'heading4') {
								return (
									<>
										<Typography color={'#3E3E3E'} marginTop={'20px'} variant='p2' sx={{ fontFamily: 'poppins', fontWeight: '700', display: 'inline' }}>{singleElement.value}</Typography>
									</>
								)
							} else if (singleElement.type === 'heading5') {
								return (
									<>
										<Typography color={'#3E3E3E'} marginTop={'20px'} variant='p2' sx={{ fontFamily: 'poppins', fontWeight: '700', fontSize: '0.9rem' }} id={`${singleElement.value.replace(/\s+/g, '_')}`}>{singleElement.value}</Typography>
										<div style={{ marginBottom: '5px' }}></div>
									</>
								)
							} else if (singleElement.type === 'paragraph1') {
								return (
									<>
										<Typography fontSize={'.9rem'} color={'#3E3E3E'} marginTop={'20px'} variant='p2' sx={{ fontFamily: 'poppins' }}>{singleElement.value}</Typography>
										<div style={{ marginBottom: '20px' }}></div>
									</>
								)
							} else if (singleElement.type === 'paragraph2') {
								return (
									<>
										<Typography fontSize={'.9rem'} color={'#3E3E3E'} marginTop={'20px'} variant='p2' sx={{ fontFamily: 'poppins' }}>{singleElement.value}</Typography>
										<div style={{ marginBottom: '2px' }}></div>
									</>
								)
							} else if (singleElement.type === 'pointers1') {
								return (
									<ul style={{ marginTop: '20px', paddingLeft: '20px' }}>
										{singleElement.value.map((item, index) => (
											<li>
												<Typography color={'#3E3E3E'} variant='p2' sx={{ fontFamily: 'poppins', fontSize: '.9rem' }}>
													{item}
												</Typography>
											</li>
										))}
										<div style={{ marginBottom: '20px' }}></div>
									</ul>
								)
							} else if (singleElement.type === 'pointers2') {
								return (
									<ol style={{ marginTop: '20px', paddingLeft: '20px' }}>
										{singleElement.value.map((item, index) => (
											<li>
												<Typography color={'#3E3E3E'} variant='p2' sx={{ fontFamily: 'poppins', fontSize: '.9rem' }}>
													{item}
												</Typography>
											</li>
										))}
										<div style={{ marginBottom: '20px' }}></div>
									</ol>
								)
							} else if (singleElement.type === 'image') {
								return (
									<Box maxWidth='sm' marginTop={'20px'} marginBottom={'20px'}>
										<img src={singleElement.value} alt="" style={{ maxWidth: '100%', height: 'auto' }} />
									</Box>
								)
							}
						})}
					</Container>
					<Box sx={{ bgcolor: '#F9F9F9', width: '100%', margin: '4% 0 0 0', padding: '4% 15%', display: 'flex', flexDirection: { sm: 'row', xs: 'column' }, textAlign: { sm: 'start', xs: 'center' } }}>
						<Box className='profile'>
							<img src={pageContent.authorProfileImage} alt="profile" />
						</Box>
						<Box marginLeft={3}>
							<Typography mb={'10px'} fontFamily={'poppins'} variant='h6' color={'#54595F'}>{pageContent.authorName}</Typography>
							<Typography fontFamily={'poppins'} variant='body1' color={'#3E3E3E'} fontSize={'.8rem'}>
								{pageContent.authorDetails}</Typography>
						</Box>
					</Box>
					<Outlet />
				</Box>
				{/* <RelatedPost
						activePostId="1"
						cards={cards}
						onRelatedPostClick={handleRelatedPostClick}
					/> */}
				<Outlet />
			</Box>
		);
	}
};

export default Articles;
