import './premiumBenifitCard.css'

function BenifitCard(props) {
    return(
        <div className='benifit_simple_card'>
            <div className='benifit_simple_card_img_area'>
                <img src={props.image} alt='benifit'/>
            </div>
            <div className='benifit_simple_card_text_area'>
                <span>{props.title}</span>
            </div>
        </div>
    )
}

export default BenifitCard;