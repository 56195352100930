import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import load from '../SupportChat/loading-paper.gif'
import feedback from './feedback.gif'
import './feedbackTicket.css'

function FeedbackTicketPage() {
    const { id } = useParams()
    const paramsEmail = new URLSearchParams(window.location.search).get("email")
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (id && paramsEmail) {
            setLoading(false)
        } else {
            setLoading(true)
        }
    }, []);

    if (loading) {
        return (
            <section id='chatbot_loading'>
                <img src={load} alt='loading' />
            </section>
        )
    } else {
        return (
            <section id='ticket_feedback_review_page'>
                <div className='ticket_feedback_container'>
                    <h1>We hope we were Helpful!</h1>
                    <img src={feedback} alt='feedback' />
                    <div className='ticket_feedback_ask'>
                        <h3>How Satisfied are you with our Customer Support? </h3>
                        <div className='ticket_feedback_options'>
                            <span onClick={() => {
                                window.open(`https://dev.blockerx.net/api/dashboard/ticket/feedback?user=${paramsEmail}&rating=5&id=${id}`, '_self')
                            }}>Extremely Satisfied</span>
                            <span onClick={() => {
                                window.open(`https://dev.blockerx.net/api/dashboard/ticket/feedback?user=${paramsEmail}&rating=4&id=${id}`, '_self')
                            }}>Satisfied</span>
                            <span onClick={() => {
                                window.open(`https://dev.blockerx.net/api/dashboard/ticket/feedback?user=${paramsEmail}&rating=3&id=${id}`, '_self')
                            }}>Neutral</span>
                            <span onClick={() => {
                                window.open(`https://dev.blockerx.net/api/dashboard/ticket/feedback?user=${paramsEmail}&rating=2&id=${id}`, '_self')
                            }}>Dissatisfied</span>
                            <span onClick={() => {
                                window.open(`https://dev.blockerx.net/api/dashboard/ticket/feedback?user=${paramsEmail}&rating=1&id=${id}`, '_self')
                            }}>Extremely Dissatisfied</span>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default FeedbackTicketPage;